<div mat-dialog-content>
    <div mat-dialog-title class="d-flex flex-row justify-content-between align-items-center">
        <h2 mat-dialog-title class="mb-0">Search board</h2>
        <button mat-flat-button [mat-dialog-close]="false">
            <img src="assets/icon-close.svg" width="14" height="auto" alt="">
        </button>
    </div>

    <mat-form-field class="mb-2 w-100" [floatLabel]="'always'">
        <mat-label>Start typing here</mat-label>
        <input type="text" placeholder="Board name" matInput [formControl]="searchControl" [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let board of savedBoards" [value]="board"
                        class="text-capitalize">
                {{ board.board_name }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <mat-selection-list
        class="mb-5"
        #boardSelectionList
        multiple="false"
        (selectionChange)="getSelectedBoard()"
    >

            <ng-container *ngIf="($filteredBoards | async); else loading">
                <mat-list-option *ngFor="let board of $filteredBoards | async" [value]="board">
                    <span class="w-100 d-flex flex-row justify-content-between align-items-center">
                        <p>{{ board.board_name }}</p>
                    </span>
                </mat-list-option>

                <ng-container *ngIf="($filteredBoards | async).length === 0">
                    <mat-list-option>
                        No Results
                    </mat-list-option>
                </ng-container>
            </ng-container>
    </mat-selection-list>

</div>

<mat-dialog-actions align="center">
    <button mat-stroked-button color="accent" [mat-dialog-close]="false" class="mr-3">Cancel</button>
    <button
        mat-stroked-button
        color="accent"
        (click)="applySelectedBoard()"
        cdkFocusInitial>Apply</button>
</mat-dialog-actions>

<ng-template #loading>
    <div class="loader-wrapper">
        <mat-spinner strokeWidth="5" diameter="50"></mat-spinner>
    </div>
</ng-template>
