import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFilterData } from '../../../core/models/filter-data.model';
import { IMetricRange } from '../../../core/models/metric-range.model';
import { IProduct } from '../../../core/models/product.model';
import { ListingDisplayMode } from '../../../core/models/display-mode.enum';

@Component({
    selector: 'lynkd-pattern-application-breadcrumbs',
    templateUrl: './application-breadcrumbs.component.html',
    styleUrls: ['./application-breadcrumbs.component.scss']
})
export class ApplicationBreadcrumbsComponent {
    @Input()
    public filterData: IFilterData;
    // eslint-disable-next-line @typescript-eslint/typedef
    public ListingDisplayMode = ListingDisplayMode;
    @Input()
    public gallery: ListingDisplayMode;
    @Input()
    public filtersApplied: boolean = true;

    @Output()
    public readonly mainFilterRemoved: EventEmitter<{ filter: 'location' | 'time' | keyof IFilterData; filterItem?: string }> =
        new EventEmitter<{ filter: 'location' | 'time' | keyof IFilterData; filterItem?: string }>();
    @Output()
    public readonly filterRemoved: EventEmitter<{
        filter: keyof IFilterData;
        filterItem: string | IMetricRange;
        subFilter?: string;
    }> = new EventEmitter<{
        filter: keyof IFilterData;
        filterItem: string | IMetricRange;
        subFilter?: string;
    }>();
    @Output()
    public readonly mainExclusionRemoved: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public readonly exclusionRemoved: EventEmitter<string | IProduct> = new EventEmitter<string | IProduct>();
    @Input()
    public checkActiveMetricRanges: boolean;

    public removeMainFilter(filter: 'location' | 'time' | keyof IFilterData, filterItem?: string): void {
        this.mainFilterRemoved.emit({ filter, filterItem });
    }

    public removeFilter(
        filter: keyof IFilterData,
        filterItem: string | IMetricRange,
        subFilter?: string
    ): void {
        this.filterRemoved.emit({ filter, filterItem, subFilter });
    }

    public removeMainExclusion(): void {
        this.mainExclusionRemoved.emit();
    }

    public removeExclusion(exclusion: string | IProduct): void {
        this.exclusionRemoved.emit(exclusion);
    }
}
