import { AfterContentChecked, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import * as _ from 'lodash';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { selectAnalyticsActiveView, selectApplicationActiveRoute } from '../../../core/core.state';
import { DataService } from '../../../core/data/data.service';
import { IBoard } from '../../../core/models/board.model';
import { ICurrentBoard } from '../../../core/models/current-board.model';
import { IDashboardFilters } from '../../../core/models/dashboard-filters.model';
import { IDashboardInputs } from '../../../core/models/dashboard-inputs.model';
import { IDisplayMetric } from '../../../core/models/display-metric.model';
import { ListingDisplayMode } from '../../../core/models/display-mode.enum';
import { IFilterData } from '../../../core/models/filter-data.model';
import { ILastUpdate } from '../../../core/models/last-update.model';
import { IMetricRange } from '../../../core/models/metric-range.model';
import { IProductMetaItem } from '../../../core/models/product-meta-item.model';
import { IProductMetas } from '../../../core/models/product-metas.model';
import { ISavedBoard } from '../../../core/models/saved-board.model';
import { ITimePeriod } from '../../../core/models/time-period.model';

import {
    actionStoreApplyFilters,
    actionStoreSetFilterSaved,
    actionStoreSetSavedBoards,
    actionStoreUserDetails,
    actionStoreWorkbenchFilterData
} from '../../../core/store/store.actions';
import { initialState } from '../../../core/store/store.reducer';

import {
    selectBoardData,
    selectFilterData,
    selectFiltersSaved,
    selectPatternBoards,
    selectSavedBoards,
    selectUserDetails,
    selectWorkbenchFilterData
} from '../../../core/store/store.selectors';
import { IApplicationMenuItemModel } from '../../../shared/models/application-menu-item.model';
import { ISideMenuItemModel } from '../../../shared/models/side-menu-item.model';
import { IUserMetaData } from '../../../shared/models/user-meta-data.model';
import { BoardService } from '../../../shared/services/board.service';
import { DefaultFiltersService } from '../../../shared/services/default-filters.service';
import { DefaultMetricService } from '../../../shared/services/default-metric.service';
import { ProductHierarchyService } from '../../../shared/services/product-hierarchy.service';
import { SideMenuService } from '../../../shared/services/side-menu.service';
import { UserMetaDataService } from '../../../shared/services/user-meta-data.service';

import { DialogBoardDeleteComponent } from '../../dialogs/dialog-board-delete.component';
import { DialogBoardEditComponent } from '../../dialogs/dialog-board-edit.component';
import { DialogBoardSaveComponent } from '../../dialogs/dialog-board-save.component';
import { DialogMetaDataAddComponent } from '../../dialogs/dialog-metadata-add.component';

import { IAppState, selectIsAuthenticated } from './../../../core/core.module';

import { ItemsPerPageService } from './dashboard.itemsperpage.service';
import { IUserRole, IUserRoleObj } from '../../../core/models/user-roles.model';

@Component({
    selector: 'lynkd-pattern-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterContentChecked, OnDestroy {
    public year: number = new Date().getFullYear();
    public sideNavOpenState: boolean = false;
    // filterOpen = false;
    public pageSize: number = 15000;

    public lastUpdated: string = '';
    public version: string = environment.version;
    public metaDataLoading: boolean = false;

    // clicked = false;

    public itemsPerPage: number;
    public itemWidth: number;
    public itemHeight: number;

    public isAuthenticated$: Observable<boolean>;
    public activeApplication$: Observable<IApplicationMenuItemModel>;

    public subscriptions: Subscription = new Subscription();
    public patternBoards: Array<ICurrentBoard>;
    public patternBoards$: Observable<Array<ICurrentBoard>>;
    public savedBoards: Array<ICurrentBoard>;
    public savedBoards$: Observable<Array<ICurrentBoard>>;
    public filtersSaved: boolean;
    public gallery: ListingDisplayMode = ListingDisplayMode.Gallery;
    // eslint-disable-next-line @typescript-eslint/typedef
    public ListingDisplayMode = ListingDisplayMode;
    public sideMenuConfig$: Observable<Array<ISideMenuItemModel>>;

    public currentBoard: ICurrentBoard = {
        board_id: 0,
        board_name: '',
        user_id: '',
        time_ranges: [],
        time_periods: [],
        time_years: [],
        time_quarters: [],
        time_months: [],
        location_companies: [],
        location_divisions: [],
        location_areas: [],
        location_regions: [],
        location_stores: [],
        departments: [],
        sub_departments: [],
        categories: [],
        product_types: [],
        meta_datas: {},
        metric_ranges: [],
        type: '',
        exclusions: []
    };

    public metaData: Array<IProductMetaItem> = [];
    public metaData$: Observable<Array<IProductMetaItem>> = of([]);

    public filterData: IFilterData = {
        time_period: '',
        time_range: [],
        time_year: [],
        time_quarter: [],
        time_month: [],
        sort_order: [],
        display_metrics: [],
        department: [],
        sub_department: [],
        category: [],
        product_type: [],
        meta_data: {},
        location_company: [],
        location_division: [],
        location_area: [],
        location_region: [],
        location_store: [],
        metric_range: [],
        exclusions: [],
        display_attributes: []
    };

    public filters: IDashboardFilters = {
        time_ranges: [],
        time_periods: [],
        time_years: [],
        time_quarters: [],
        time_months: [],
        location_companies: [],
        location_divisions: [],
        location_areas: [],
        location_regions: [],
        location_stores: [],
        departments: [],
        sub_departments: [],
        categories: [],
        product_types: [],
        styles: [],
        meta_datas: [],
        metric_ranges: [],
        exclusions: []
    };

    public inputs: IDashboardInputs = {
        time_period: '',
        time_range: [],
        time_year: [],
        time_quarter: [],
        time_month: [],
        location_company: [],
        location_division: [],
        location_area: [],
        location_region: [],
        location_store: [],
        department: [],
        sub_department: [],
        category: [],
        product_type: [],
        style: [],
        meta_data: {},
        metric_range: [],
        exclusions: [],
        board_type: ''
    };

    @ViewChildren(MatExpansionPanel)
    public viewPanels: QueryList<MatExpansionPanel>;

    public constructor(
        private readonly _store: Store<IAppState>,
        public dialog: MatDialog,
        private readonly _router: Router,
        private readonly _dataService: DataService,
        private readonly _boardService: BoardService,
        private readonly _itemsPerPageService: ItemsPerPageService,
        private readonly _sideMenuService: SideMenuService,
        private readonly _defaultMetricService: DefaultMetricService,
        private readonly _userMetaDataService: UserMetaDataService,
        private readonly _defaultFilters: DefaultFiltersService,
        private readonly _productHierarchyService: ProductHierarchyService
    ) {
    }

    public async ngOnInit(): Promise<void | boolean> {
        this.isAuthenticated$ = this._store.select(selectIsAuthenticated);
        this.activeApplication$ = this._store.select(selectApplicationActiveRoute);

        this.subscriptions.add(
            this._store.select(selectApplicationActiveRoute).subscribe(async (t: IApplicationMenuItemModel) => {
                if (!t){
                    return;
                }
                if (t.name === 'allocations') {
                    await this._defaultMetricService.hydrateWorkbenchDefaults();
                    await this._defaultMetricService.hydrateStylingDefaults();
                    await this._defaultFilters.hydrateAllocationsDefaults();
                } else if (t.name === 'analytics') {

                    await this._defaultFilters.hydrateAnalyticsDefaults();
                }
                this.sideMenuConfig$ = this._sideMenuService.getAll(t.id);
            })
        );
        if (this._router.url.indexOf('analytics') === -1
            && this._router.url.indexOf('allocations') === -1
            && this._router.url.indexOf('users') === -1
            && this._router.url.indexOf('picture-manager') === -1) {
            return this._router.navigate(['/dashboard']);
        }

        const lastUpdate: Array<ILastUpdate> = await this._dataService.getLastUpdate().toPromise();

        this.lastUpdated = lastUpdate[0].last_update;

        this.subscriptions.add(
            this._store.select(selectUserDetails).subscribe(async (result: { email: string }) => {
                if (result) {
                    this.currentBoard.user_id = result.email;

                    setTimeout(async () => {
                        this.getTimePeriods();
                        this.getLocations();
                        await this.getProductHierarchies(
                            this.inputs.department,
                            this.inputs.sub_department,
                            this.inputs.category,
                            this.inputs.product_type,
                            this.inputs.style
                        );
                    }, 100);
                } else {
                    try {
                        const session: CognitoUserSession = await Auth.currentSession();

                        // Extracts custom roles and parses to array of roles
                        const roles: Array<IUserRole> = (
                            JSON.parse((await Auth.currentUserInfo()).attributes['custom:roles']) as IUserRoleObj
                        ).data;

                        this._store.dispatch(
                            actionStoreUserDetails({
                                userDetails: { email: session.getIdToken().payload.email, roles }
                            })
                        );
                    } catch (e) {
                        return this._router.navigate(['/auth']);
                    }
                }
            })
        );

        this.subscriptions.add(
            combineLatest([this._store.select(selectUserDetails), this._store.select(selectSavedBoards)]).subscribe(
                async ([result]: [{ email: string }, ISavedBoard]) => {
                    if (!result) {
                        return;
                    }
                    this.savedBoards$ = this._boardService.getSavedBoards(result.email);
                    this.savedBoards = await this.savedBoards$.toPromise();
                }
            )
        );

        this.subscriptions.add(
            this._store.select(selectPatternBoards).subscribe(async () => {
                // this.patternBoardsLoading = true;

                this.patternBoards$ = this._boardService
                    .getPatternBoards()
                    .pipe(map((t: Array<ICurrentBoard>) => _.sortBy(t, (board: ICurrentBoard) => board.board_name)));
                this.patternBoards = await this.patternBoards$.toPromise();

                // .subscribe((response: Array<ICurrentBoard>) => {
                // this.patternBoardsLoading = false;
                // this.patternBoards = response;
                // this.patternBoards = _.sortBy(this.patternBoards, (board: ICurrentBoard) => {
                //     return board.board_name;
                // });
                // });
            })
        );

        this.subscriptions.add(
            this._store.select(selectAnalyticsActiveView).subscribe((result: ListingDisplayMode) => {
                this.gallery = result;
            })
        );

        this.subscriptions.add(
            this._store.select(selectFiltersSaved).subscribe((result: boolean) => {
                this.filtersSaved = result;
            })
        );

        //  COUNTER DELAY FIRST LOAD
        // let delayMetaCount = 1;
        this.subscriptions.add(
            combineLatest(this.activeApplication$, this._store.select(selectFilterData)).subscribe(
                ([application, result]: [IApplicationMenuItemModel, IFilterData]) => {
                    setTimeout(() => {
                        if (application.name === 'analytics') {
                            this.filterData = JSON.parse(JSON.stringify(result));

                            if (!Object.keys(this.inputs.meta_data).length && !Object.entries(this.filterData.meta_data).length) {
                                // delayMetaCount++

                                // if(delayMetaCount > 2){
                                //   this.getProductMetas()
                                // }
                                const time: Array<string> = [];
                                if (this.filterData.time_period.length) {
                                    time.push(`recent:'${this.filterData.time_period}'`);
                                } else {
                                    if (this.filterData.time_year.length) {
                                        time.push(`year:'${this.filterData.time_year}'`);
                                    }
                                    if (this.filterData.time_quarter.length) {
                                        time.push(`quarter:'${this.filterData.time_quarter}'`);
                                    }
                                    if (this.filterData.time_month.length) {
                                        time.push(`month:'${this.filterData.time_month}'`);
                                    }
                                }

                                this.getMetricRanges(
                                    time.join(','),
                                    this.filterData.time_range,
                                    this.filterData.department,
                                    this.filterData.sub_department,
                                    this.filterData.category,
                                    this.filterData.product_type,
                                    this.filterData.meta_data,
                                    this.pageSize
                                );
                            }

                            const metaData: Record<string, Array<string>> = this.filterData.meta_data;
                            // if (!metaData.length) {
                            //     this.filters.meta_datas.forEach((value: IProductMetaItem, index: number) => {
                            //         if (index > 3 && metaData[value.name]) {
                            //             metaData[value.name] = [];
                            //         }
                            //     });
                            // }
                            this.inputs.time_period = this.filterData.time_period;
                            this.inputs.time_range = this.filterData.time_range;
                            this.inputs.time_year = this.filterData.time_year;
                            this.inputs.time_quarter = this.filterData.time_quarter;
                            this.inputs.time_month = this.filterData.time_month;
                            this.inputs.location_company = this.filterData.location_company;
                            this.inputs.location_division = this.filterData.location_division;
                            this.inputs.location_area = this.filterData.location_area;
                            this.inputs.location_region = this.filterData.location_region;
                            this.inputs.location_store = this.filterData.location_store;
                            this.inputs.department = this.filterData.department;
                            this.inputs.sub_department = this.filterData.sub_department;
                            this.inputs.category = this.filterData.category;
                            this.inputs.product_type = this.filterData.product_type;
                            this.inputs.style = this.filterData.style;
                            this.inputs.meta_data = metaData;
                            this.inputs.metric_range = this.filterData.metric_range;
                            this.itemsPerPageUpdate();
                        }
                    });
                }
            )
        );

        this.subscriptions.add(
            combineLatest(this.activeApplication$, this._store.select(selectWorkbenchFilterData)).subscribe(
                ([application, result]: [IApplicationMenuItemModel, IFilterData]) => {
                    setTimeout(() => {
                        if (application.name === 'allocations') {
                            this.filterData = JSON.parse(JSON.stringify(result));

                            if (!Object.keys(this.inputs.meta_data).length && !Object.entries(this.filterData.meta_data).length) {
                                // delayMetaCount++

                                // if(delayMetaCount > 2){
                                //   this.getProductMetas()
                                // }
                                const time: Array<string> = [];
                                if (this.filterData.time_period.length) {
                                    time.push(`recent:'${this.filterData.time_period}'`);
                                } else {
                                    if (this.filterData.time_year.length) {
                                        time.push(`year:'${this.filterData.time_year}'`);
                                    }
                                    if (this.filterData.time_quarter.length) {
                                        time.push(`quarter:'${this.filterData.time_quarter}'`);
                                    }
                                    if (this.filterData.time_month.length) {
                                        time.push(`month:'${this.filterData.time_month}'`);
                                    }
                                }

                                this.getMetricRanges(
                                    time.join(','),
                                    this.filterData.time_range,
                                    this.filterData.department,
                                    this.filterData.sub_department,
                                    this.filterData.category,
                                    this.filterData.product_type,
                                    this.filterData.meta_data,
                                    this.pageSize
                                );
                            }

                            const metaData: Record<string, Array<string>> = this.filterData.meta_data;
                            // if (!metaData.length) {
                            //     this.filters.meta_datas.forEach((value: IProductMetaItem, index: number) => {
                            //         if (index > 3 && metaData[value.name]) {
                            //             metaData[value.name] = [];
                            //         }
                            //     });
                            // }
                            this.inputs.time_period = this.filterData.time_period;
                            this.inputs.time_range = this.filterData.time_range;
                            this.inputs.time_year = this.filterData.time_year;
                            this.inputs.time_quarter = this.filterData.time_quarter;
                            this.inputs.time_month = this.filterData.time_month;
                            this.inputs.location_company = this.filterData.location_company;
                            this.inputs.location_division = this.filterData.location_division;
                            this.inputs.location_area = this.filterData.location_area;
                            this.inputs.location_region = this.filterData.location_region;
                            this.inputs.location_store = this.filterData.location_store;
                            this.inputs.department = this.filterData.department;
                            this.inputs.sub_department = this.filterData.sub_department;
                            this.inputs.category = this.filterData.category;
                            this.inputs.product_type = this.filterData.product_type;
                            this.inputs.style = this.filterData.style;
                            this.inputs.meta_data = metaData;
                            this.inputs.metric_range = this.filterData.metric_range;
                            this.itemsPerPageUpdate();
                        }
                    });
                }
            )
        );

        this.subscriptions.add(
            this._store.select(selectBoardData).subscribe((result: IBoard) => {
                this.currentBoard.board_id = result.board_id;
                this.currentBoard.board_name = result.board_name;
                this.currentBoard.type = result.user_id;
            })
        );

        // console.log('Side application-menu:', menuConfig);
    }

    public ngAfterContentChecked(): void {
        this.itemsPerPageUpdate();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public async getBoard(saveBoard: boolean, boardId: number): Promise<boolean | void> {
        if (this.filtersSaved) {
            if (saveBoard) {
                const result: Array<ICurrentBoard> = this.savedBoards
                    .concat(this.patternBoards || [])
                    .filter((board: ICurrentBoard) => board.board_id === boardId);
                if (result.length) {
                    this.inputs = {
                        time_period: '',
                        time_range: [],
                        time_year: [],
                        time_quarter: [],
                        time_month: [],
                        location_company: [],
                        location_division: [],
                        location_area: [],
                        location_region: [],
                        location_store: [],
                        department: [],
                        sub_department: [],
                        category: [],
                        product_type: [],
                        style: [],
                        meta_data: {},
                        metric_range: [],
                        exclusions: [],
                        board_type: ''
                    };

                    this.currentBoard.board_id = boardId;
                    this.currentBoard.board_name = result[0].board_name;
                    this.currentBoard.user_id = result[0].user_id;

                    let timeFilters: string | Array<string> = result[0].filter_time.substring(
                        result[0].filter_time.indexOf('=') + 1,
                        result[0].filter_time.length
                    );
                    timeFilters = timeFilters.split(',');
                    timeFilters.map((item: string) => {
                        const itemArray: Array<string> = item.split(':');
                        if (itemArray[0] === 'recent') {
                            this.inputs.time_period = itemArray[1];
                        }
                        if (itemArray[0] === 'year') {
                            this.inputs.time_year.push(itemArray[1]);
                        }
                        if (itemArray[0] === 'quarter') {
                            this.inputs.time_quarter.push(itemArray[1]);
                        }
                        if (itemArray[0] === 'month') {
                            this.inputs.time_month.push(itemArray[1]);
                        }
                    });
                    this.filterData.time_period = this.inputs.time_period;
                    this.filterData.time_year = this.inputs.time_year;
                    this.filterData.time_month = this.inputs.time_month;
                    this.filterData.time_quarter = this.inputs.time_quarter;

                    let hierarchyFilters:
                        | string
                        | Array<{ [key: string]: string }>
                        | Array<{
                        department_name: string;
                        sub_department_name: string;
                        category_name: string;
                        product_type_name: string;
                        style: string;
                    }> = result[0].filter_product.substring(
                        result[0].filter_product.indexOf('=') + 1,
                        result[0].filter_product.length
                    );
                    if (hierarchyFilters.length) {
                        hierarchyFilters = this.parseAPIData(hierarchyFilters);

                        this.inputs.department = Array.from(
                            new Set(hierarchyFilters.filter((prop: { department_name: string }) => prop.department_name))
                        );
                        this.inputs.department = Array.from(
                            new Set(this.inputs.department.map((prop: { department_name: string }) => prop.department_name))
                        );
                        this.inputs.sub_department = Array.from(
                            new Set(
                                hierarchyFilters.filter(
                                    (prop: { department_name: string; sub_department_name: string }) => prop.sub_department_name
                                )
                            )
                        );
                        this.inputs.sub_department = Array.from(
                            new Set(
                                this.inputs.sub_department.map(
                                    (prop: { sub_department_name: string }) => prop.sub_department_name
                                )
                            )
                        );
                        this.inputs.category = Array.from(
                            new Set(
                                hierarchyFilters.filter(
                                    (prop: { department_name: string; sub_department_name: string; category_name: string }) =>
                                        prop.category_name
                                )
                            )
                        );
                        this.inputs.category = Array.from(
                            new Set(this.inputs.category.map((prop: { category_name: string }) => prop.category_name))
                        );
                        this.inputs.product_type = Array.from(
                            new Set(
                                hierarchyFilters.filter(
                                    (prop: {
                                        department_name: string;
                                        sub_department_name: string;
                                        category_name: string;
                                        product_type_name: string;
                                    }) => prop.product_type_name
                                )
                            )
                        );
                        this.inputs.product_type = Array.from(
                            new Set(this.inputs.product_type.map((prop: { product_type_name: string }) => prop.product_type_name))
                        );

                        this.inputs.style = Array.from(
                            new Set(
                                hierarchyFilters.filter(
                                    (prop: {
                                        department_name: string;
                                        sub_department_name: string;
                                        category_name: string;
                                        product_type_name: string;
                                        style: string;
                                    }) => prop.style
                                )
                            )
                        );
                        this.inputs.style = Array.from(new Set(this.inputs.style.map((prop: { style: string }) => prop.style)));

                        this.filterData.department = this.inputs.department;
                        this.filterData.sub_department = this.inputs.sub_department;
                        this.filterData.category = this.inputs.category;
                        this.filterData.product_type = this.inputs.product_type;
                        this.filterData.style = this.inputs.style;
                    } else {
                        this.filterData.department = [];
                        this.filterData.sub_department = [];
                        this.filterData.category = [];
                        this.filterData.product_type = [];
                        this.filterData.style = [];
                    }

                    this.filterData.sort_order = result[0].sort_order ? result[0].sort_order : '';
                    this.filterData.display_metrics = result[0].display_metrics
                        ? (result[0].display_metrics as string).split(',').map((item: string) => ({ metric_name: item }))
                        : [];
                    this.filterData.styling_display_metrics = result[0].styling_display_metrics
                        ? (result[0].styling_display_metrics as string).split(',').map((item: string) => ({ metric_name: item }))
                        : [];
                    this.filterData.display_attributes = result[0].display_attribute
                        ? (result[0].display_attribute).split(',').map((item: string) => ({ column_name: item }))
                        : [];

                    let locationFilters: string | Array<{ [id: string]: string }> = result[0].filter_location.substring(
                        result[0].filter_location.indexOf('=') + 1,
                        result[0].filter_location.length
                    );
                    if (locationFilters.length) {
                        locationFilters = this.parseAPIData(locationFilters);

                        for (const locationFilter of locationFilters) {
                            const key: string = Object.keys(locationFilter)[0];
                            const value: string = Object.values(locationFilter)[0];

                            if (key === 'fin_co_name') {
                                this.inputs.location_company.push(value);
                            }
                            if (key === 'division_descr') {
                                this.inputs.location_division.push(value);
                            }
                            if (key === 'trademark_descr') {
                                this.inputs.location_area.push(value);
                            }
                            if (key === 'region_descr') {
                                this.inputs.location_region.push(value);
                            }
                            if (key === 'store_name') {
                                this.inputs.location_store.push(value);
                            }
                        }
                        this.filterData.location_company = this.inputs.location_company;
                        this.filterData.location_division = this.inputs.location_division;
                        this.filterData.location_area = this.inputs.location_area;
                        this.filterData.location_region = this.inputs.location_region;
                        this.filterData.location_store = this.inputs.location_store;
                    }

                    const metaData: string = result[0].filter_meta.substring(
                        result[0].filter_meta.indexOf('=') + 1,
                        result[0].filter_meta.length
                    );
                    if (metaData.length) {
                        const parsedMetaData: Array<{ [key: string]: string }> = this.parseAPIData(metaData);
                        this.filters.meta_datas = this.metaData.slice(0, 4);

                        for (const metaDatum of parsedMetaData) {
                            const key: string = Object.keys(metaDatum)[0];
                            const value: string = Object.values(metaDatum)[0];
                            const index: number = this.metaData.findIndex((i: IProductMetaItem) => i.name === key);

                            if (index > 3 && this.filters.meta_datas.findIndex((i: IProductMetaItem) => i.name === key) === -1) {
                                this.filters.meta_datas.push({
                                    name: key,
                                    input: `metaData-${key}`,
                                    data: this.metaData[index].data
                                });
                            }

                            if (!this.inputs.meta_data[key]) {
                                this.inputs.meta_data[key] = [];
                            }

                            this.inputs.meta_data[key].push(value);
                        }

                        this.filterData.meta_data = this.inputs.meta_data;
                    } else {
                        this.filterData.meta_data = {};
                        this.inputs.meta_data = {};
                    }

                    if (result[0].filter_range) {
                        const metricRange: Array<string> = result[0].filter_range.split(',');

                        this.inputs.metric_range = metricRange.map((item: string) => {
                            const data: Array<string> = item.split(':');
                            return {
                                active: true,
                                metric_name: data[0],
                                min: undefined,
                                max: undefined,
                                values: [parseFloat(data[1]), parseFloat(data[2])]
                            };
                        });
                        this.filterData.metric_range = this.inputs.metric_range;
                    }

                    if (result[0].exclusions) {
                        this.currentBoard.exclusions = result[0].exclusions;
                    }

                    this.currentBoard.board_id = boardId;
                    this.currentBoard.board_name = result[0].board_name;
                    this.currentBoard.user_id = result[0].user_id;

                    this.applyFilters('');
                    return this._router.navigate(['/analytics', result[0].board_type]);
                }
            }
        } else {
            this.saveBoard(boardId);
        }
    }

    public getTimePeriods(): void {
        this._dataService.getTimePeriods().subscribe((response: Array<ITimePeriod>) => {
            this.filters.time_periods = Array.from(new Set(response.filter((prop: ITimePeriod) => prop.period_name)));
            this.filters.time_periods = Array.from(
                new Set(this.filters.time_periods.map((prop: ITimePeriod) => prop.period_name))
            );

            this.filterData.time_period = this.filterData.time_period.length ? this.filterData.time_period : '';
            this.inputs.time_period = this.filterData.time_period;

            /* this.filterData['time_range'] = this.filterData['time_range']; */
        });

        this._dataService.getTimeYears().subscribe((response: Array<string>) => {
            this.filters.time_years = response;

            this.inputs.time_year = this.filterData.time_year;
        });

        this._dataService.getTimeQuarters().subscribe((response: Array<string>) => {
            this.filters.time_quarters = response;

            this.inputs.time_quarter = this.filterData.time_quarter;
        });

        this._dataService.getTimeMonths().subscribe((response: Array<string>) => {
            this.filters.time_months = response;

            this.inputs.time_month = this.filterData.time_month;
        });
    }

    public getLocations(): void {
        this._dataService.getLocationCompany().subscribe((response: Array<string>) => {
            this.filters.location_companies = response;

            this.inputs.location_company = this.filterData.location_company;
        });

        this._dataService.getLocationDivision().subscribe((response: Array<string>) => {
            this.filters.location_divisions = response;

            this.inputs.location_division = this.filterData.location_division;
        });

        this._dataService.getLocationArea().subscribe((response: Array<string>) => {
            this.filters.location_areas = response;

            this.inputs.location_area = this.filterData.location_area;
        });

        this._dataService.getLocationRegion().subscribe((response: Array<string>) => {
            this.filters.location_regions = response;

            this.inputs.location_region = this.filterData.location_region;
        });

        this._dataService.getLocationStore().subscribe((response: Array<string>) => {
            this.filters.location_stores = response;

            this.inputs.location_store = this.filterData.location_store;
        });
    }

    public async getProductHierarchies(
        department: Array<string> | Array<{ [p: string]: string }>,
        subDepartment: Array<string> | Array<{ [p: string]: string }>,
        category: Array<string> | Array<{ [p: string]: string }>,
        productType: Array<string> | Array<{ [p: string]: string }>,
        style: Array<string> | Array<{ [p: string]: string }>
    ): Promise<void> {
        this.filters.departments = await this._productHierarchyService.getProductHierarchyDepartments();

        this.inputs.department = department ? department : this.filterData.department;
        // SUB DEPARTMENT
        this.filters.sub_departments = await this._productHierarchyService.getProductHierarchySubDepartments();

        this.inputs.sub_department = subDepartment ? subDepartment : this.filterData.sub_department;

        this.filters.categories = await this._productHierarchyService.getProductHierarchyCategories();

        this.inputs.category = category ? category : this.filterData.category;

        this.filters.product_types = await this._productHierarchyService.getProductHierarchyProductTypes();

        this.inputs.product_type = productType ? productType : this.filterData.product_type;

        this.filters.styles = await this._productHierarchyService.getProductHierarchyStyles();

        this.inputs.style = style ? style : this.filterData.style;
    }

    public async loadMetaData(): Promise<void> {
        await this.getProductMetas();
    }

    public async getProductMetas(): Promise<void | boolean> {
        if (this.metaData?.length > 0) {
            return;
        }
        this.metaData = [];

        this.metaData$ = this._dataService.getProductMetas().pipe(
            map((response: Array<IProductMetas>) => {
                const newProductMetaItems: Array<IProductMetaItem> = response.map(
                    (metaResult: IProductMetas): IProductMetaItem => {
                        const data: Array<string> = ('\'' + metaResult.attribute_values.replace(/,/g, '\',\'') + '\'')
                            .replace('[', '')
                            .replace(']', '')
                            .split(',')
                            .map((x: string) => x.replace(/'/g, ''));
                        return { name: metaResult.attribute, data };
                    }
                );
                return newProductMetaItems;
            })
        );
        this.metaDataLoading = true;
        this.metaData = await this.metaData$.toPromise();
        let userId: string = this.currentBoard.user_id;
        if (!this.currentBoard.user_id) {
            try {
                const session: CognitoUserSession = await Auth.currentSession();
                userId = session.getIdToken().payload.email;
            } catch (e) {
                return this._router.navigate(['/auth']);
            }
        }
        const userMetaData: Array<IUserMetaData> = await this._userMetaDataService.getUserMetaData(userId);
        if (userMetaData?.length) {
            const metaDataNames: Array<string> = userMetaData.map((t: IUserMetaData) => t.meta_data_name);
            this.filters.meta_datas = this.metaData.filter((t: IProductMetaItem) => metaDataNames.includes(t.name));
        } else {
            this.filters.meta_datas = this.metaData.slice(0, 4);
        }

        this.metaDataLoading = false;
    }

    public getMetricRanges(
        timePeriod: string | Array<string>,
        timeRange: string | Array<string>,
        department: Array<string>,
        subDepartment: Array<string>,
        category: Array<string>,
        productType: Array<string>,
        meta: Record<string, Array<string>>,
        pageSize: number
    ): void {
        this.filters.metric_ranges = [];

        this._dataService
            .getMetricRanges(timePeriod, timeRange, department, subDepartment, category, productType, meta, pageSize)
            .subscribe((response: Array<IMetricRange>) => {
                this.filters.metric_ranges = response;
                this.filters.metric_ranges.forEach((item: IMetricRange) => {
                    item.active = false;
                    item.values = [item.min, item.max];
                });
            });
    }

    public calculateItemsPerPage(): void {
        const windowWidth: number = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const windowHeight: number = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        const headerHeight: number = 0;
        const toolbarHeight: number = 73 + 36 + 40 + 20;
        const paginationHeight: number = 30;
        let rows: number;

        if (this.filterData && this.filterData.display_metrics && this.filterData.display_metrics.length) {
            this.itemWidth = this.sideNavOpenState ? (windowWidth - 350 - 60) / 5 - 6 : (windowWidth - 154 - 60) / 6 - 6;
            rows = Math.ceil((windowHeight - headerHeight - toolbarHeight - paginationHeight) / this.itemWidth);
            this.itemHeight = (windowHeight - headerHeight - toolbarHeight - paginationHeight) / rows - 6;
            this.itemsPerPage = this.sideNavOpenState ? 6 * rows : 5 * rows;
        } else {
            this.itemWidth = this.sideNavOpenState ? (windowWidth - 270 - 60) / 7 - 6 : (windowWidth - 74 - 60) / 8 - 6;
            rows = Math.ceil((windowHeight - headerHeight - toolbarHeight - paginationHeight) / this.itemWidth);
            this.itemHeight = (windowHeight - headerHeight - toolbarHeight - paginationHeight) / rows - 6;
            this.itemsPerPage = this.sideNavOpenState ? 7 * rows : 8 * rows;
        }
    }

    public itemsPerPageUpdate(): void {
        this.calculateItemsPerPage();

        this._itemsPerPageService.updateItemsPerPage({
            itemsPerPage: this.itemsPerPage,
            itemWidth: this.itemWidth,
            itemHeight: this.itemHeight
        });
    }

    public async beforePanelOpened(sidenav: MatSidenav): Promise<MatDrawerToggleResult> {
        this.sideNavOpenState = true;
        this.itemsPerPageUpdate();
        return sidenav.open();
    }

    public async sideNavToggle(sidenav: MatSidenav, accordions: Array<MatAccordion>): Promise<void> {
        accordions.forEach((accordion: MatAccordion) => {
            accordion.multi = true;
            accordion.closeAll();
            accordion.multi = false;
        });

        this.sideNavOpenState = !this.sideNavOpenState;
        this.itemsPerPageUpdate();
        await sidenav.toggle();
    }

    public isLargeScreen(): boolean {
        const width: number = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        return width > 720;
    }

    public editSavedBoard(board: ICurrentBoard): void {
        const dialogRef: MatDialogRef<DialogBoardEditComponent, ISavedBoard> = this.dialog.open(DialogBoardEditComponent, {
            autoFocus: false,
            data: {
                boardData: board
            }
        });

        dialogRef.afterClosed().subscribe((result: ISavedBoard) => {
            if (result) {
                this.currentBoard.board_id = 0;
                this.currentBoard.board_name = '';

                this._store.dispatch(
                    actionStoreSetSavedBoards({
                        savedBoards: result
                    })
                );
            }
        });
    }

    public deleteSavedBoard(boardId: string): void {
        const dialogRef: MatDialogRef<DialogBoardDeleteComponent, ISavedBoard> = this.dialog.open(DialogBoardDeleteComponent, {
            autoFocus: false,
            data: {
                boardData: {
                    board_id: boardId,
                    board_name: this.currentBoard.board_name
                }
            }
        });

        dialogRef.afterClosed().subscribe((result: ISavedBoard) => {
            if (result) {
                this.currentBoard.board_id = 0;
                this.currentBoard.board_name = '';

                this._store.dispatch(
                    actionStoreSetSavedBoards({
                        savedBoards: result
                    })
                );
            }
        });
    }

    public closeFilter(result: {
        items: Array<string> | Array<ITimePeriod> | Array<IMetricRange>;
        selectedItem: string | Array<string> | Array<IMetricRange>;
        input: string;
        subInput: string;
    }): void {
        if (result) {
            const localInput: string = result.input;
            const localSubInput: string = result.subInput;
            let previousValues: string | Array<string> | Array<IMetricRange>;
            if (localSubInput) {
                previousValues = this.inputs[localInput][localSubInput];
            } else {
                previousValues = this.inputs[localInput];
            }
            if (localSubInput) {
                this.inputs[localInput][localSubInput] = result.selectedItem;
            } else {
                this.inputs[localInput] =
                    localInput === 'time_period' || localInput === 'time_range' ? result.selectedItem[0] : result.selectedItem;
            }

            if (localSubInput && this.inputs[localInput][localSubInput].length === 0) {
                delete this.inputs[localInput][localSubInput];
            }

            if (localInput === 'time_period') {
                this.inputs.time_range = [];
                this.inputs.time_year = [];
                this.inputs.time_quarter = [];
                this.inputs.time_month = [];
            } else if (localInput === 'time_range') {
                this.inputs.time_period = '';
                this.inputs.time_year = [];
                this.inputs.time_quarter = [];
                this.inputs.time_month = [];
                const timeRanges: string | Array<string> = this.inputs.time_range;
                this.filters.time_ranges = [timeRanges as string];
            } else if (localInput === 'time_year' || localInput === 'time_quarter' || localInput === 'time_month') {
                this.inputs.time_period = '';
                this.inputs.time_range = [];
            }

            if (previousValues !== result.selectedItem) {
                this.filtersSaved = this.gallery === ListingDisplayMode.Table;

                this._store.dispatch(
                    actionStoreSetFilterSaved({
                        filtersSaved: this.filtersSaved
                    })
                );

                this.applyFilters('get-board');
            }
        }

        if (result.input === 'metric_range') {
            this.viewPanels.forEach((p: MatExpansionPanel) => (p.hideToggle === true ? p.close() : ''));
        }

        const overlay: HTMLDivElement = document.querySelector('.cdk-overlay-backdrop');
        if (overlay) {
            overlay.click();
        }
    }

    /***
     * Meta-Data Filters
     * Functionality
     */

    public addMetaDataField(): void {
        const dialogRef: MatDialogRef<DialogMetaDataAddComponent, Array<string>> = this.dialog.open(DialogMetaDataAddComponent, {
            data: {
                currentMetaFilters: this.filters.meta_datas,
                items: this.metaData
            }
        });

        dialogRef.afterClosed().subscribe(async (result: Array<string>) => {
            if (result) {
                const metaDataName: string = result[0];
                let userId: string = this.currentBoard.user_id;
                if (!this.currentBoard.user_id) {
                    try {
                        const session: CognitoUserSession = await Auth.currentSession();
                        userId = session.getIdToken().payload.email;
                    } catch (e) {
                        return this._router.navigate(['/auth']);
                    }
                }
                this.metaDataLoading = true;
                let userMetaData: Array<IUserMetaData> = await this._userMetaDataService.getUserMetaData(userId);

                // No records for the users and the user hasn't cleared out all the meta in this session
                if (!userMetaData?.length && this.filters.meta_datas.length === 4) {
                    const defaultMetaData: Array<IProductMetaItem> = this.metaData.slice(0, 4);
                    await Promise.all(
                        defaultMetaData.map(async (t: IProductMetaItem) => {
                            await this._userMetaDataService.addMetaData({ meta_data_name: t.name, user_id: userId });
                        })
                    );
                }

                await this._userMetaDataService.addMetaData({ meta_data_name: metaDataName, user_id: userId });

                userMetaData = await this._userMetaDataService.getUserMetaData(userId);
                if (userMetaData?.length) {
                    const metaDataNames: Array<string> = userMetaData.map((t: IUserMetaData) => t.meta_data_name);
                    this.filters.meta_datas = this.metaData.filter((t: IProductMetaItem) => metaDataNames.includes(t.name));
                } else {
                    this.filters.meta_datas = [];
                }
                this.metaDataLoading = false;
                // this.filters.meta_datas.push({
                //     name: metaDataName,
                //     input: `metaData-${metaDataName}`,
                //     data: this.metaData[index].data
                // });
            }
        });
    }

    public async deleteMetaDataField(index: number): Promise<void | boolean> {
        const metaDataName: string = this.filters.meta_datas[index].name;
        let userId: string = this.currentBoard.user_id;
        if (!this.currentBoard.user_id) {
            try {
                const session: CognitoUserSession = await Auth.currentSession();
                userId = session.getIdToken().payload.email;
            } catch (e) {
                return this._router.navigate(['/auth']);
            }
        }
        this.metaDataLoading = true;
        let userMetaData: Array<IUserMetaData> = await this._userMetaDataService.getUserMetaData(userId);

        if (userMetaData?.length) {
            await this._userMetaDataService.deleteMetaData({ meta_data_name: metaDataName, user_id: userId });
        } else {
            const defaultMetaData: Array<IProductMetaItem> = this.metaData
                .slice(0, 4)
                .filter((t: IProductMetaItem) => t.name !== metaDataName);
            for (const t of defaultMetaData) {
                await this._userMetaDataService.addMetaData({ meta_data_name: t.name, user_id: userId });
            }
        }

        const filterDataIndex: string = Object.keys(this.filterData.meta_data).find((meta: string) => meta === metaDataName);
        delete this.filterData.meta_data[filterDataIndex];

        userMetaData = await this._userMetaDataService.getUserMetaData(userId);
        if (userMetaData?.length) {
            const metaDataNames: Array<string> = userMetaData.map((t: IUserMetaData) => t.meta_data_name);
            this.filters.meta_datas = this.metaData.filter((t: IProductMetaItem) => metaDataNames.includes(t.name));
        } else {
            this.filters.meta_datas = [];
        }
        this.metaDataLoading = false;
    }

    public applyFilters(action: string): void {
        let metaData: Record<string, Array<string>> = this.inputs.meta_data;

        if (
            action !== 'get-board' &&
            (JSON.stringify(this.inputs.time_period) !== JSON.stringify(this.filterData.time_period) ||
                JSON.stringify(this.inputs.time_range) !== JSON.stringify(this.filterData.time_range) ||
                JSON.stringify(this.inputs.time_year) !== JSON.stringify(this.filterData.time_year) ||
                JSON.stringify(this.inputs.time_quarter) !== JSON.stringify(this.filterData.time_quarter) ||
                JSON.stringify(this.inputs.time_month) !== JSON.stringify(this.filterData.time_month) ||
                JSON.stringify(this.inputs.department) !== JSON.stringify(this.filterData.department) ||
                JSON.stringify(this.inputs.sub_department) !== JSON.stringify(this.filterData.sub_department) ||
                JSON.stringify(this.inputs.category) !== JSON.stringify(this.filterData.category) ||
                JSON.stringify(this.inputs.product_type) !== JSON.stringify(this.filterData.product_type) ||
                JSON.stringify(this.inputs.style) !== JSON.stringify(this.filterData.style) ||
                JSON.stringify(this.inputs.location_company) !== JSON.stringify(this.filterData.location_company) ||
                JSON.stringify(this.inputs.location_division) !== JSON.stringify(this.filterData.location_division) ||
                JSON.stringify(this.inputs.location_area) !== JSON.stringify(this.filterData.location_area) ||
                JSON.stringify(this.inputs.location_region) !== JSON.stringify(this.filterData.location_region) ||
                JSON.stringify(this.inputs.location_store) !== JSON.stringify(this.filterData.location_store) ||
                JSON.stringify(this.inputs.metric_range) !== JSON.stringify(this.filterData.metric_range))
        ) {
            metaData = {};

            // if (!metaData.length) {
            //     this.filters.meta_datas.forEach((value: IProductMetaItem, index: number) => {
            //         if (index > 3) {
            //             metaData[value.name] = [];
            //         }
            //     });
            // }
        }
        // this.subscriptions.add(
        //     this.activeApplication$.subscribe(() => {
        //         // if (t.name === 'analytics') {
        //         this._store.dispatch(
        //             actionStoreApplyFilters({
        //                 boardData: {
        //                     user_id: this.currentBoard.user_id,
        //                     board_id: this.currentBoard.board_id,
        //                     board_name: this.currentBoard.board_name
        //                 },
        //                 filterData: {
        //                     time_period: this.inputs.time_period,
        //                     time_range: this.inputs.time_range,
        //                     time_year: this.inputs.time_year,
        //                     time_quarter: this.inputs.time_quarter,
        //                     time_month: this.inputs.time_month,
        //                     location_company: this.inputs.location_company,
        //                     location_division: this.inputs.location_division,
        //                     location_area: this.inputs.location_area,
        //                     location_region: this.inputs.location_region,
        //                     location_store: this.inputs.location_store,
        //                     sort_order: this.filterData.sort_order,
        //                     display_metrics: this.filterData.display_metrics,
        //                     department: this.inputs.department as Array<string>,
        //                     sub_department: this.inputs.sub_department as Array<string>,
        //                     category: this.inputs.category as Array<string>,
        //                     product_type: this.inputs.product_type as Array<string>,
        //                     style: this.inputs.style as Array<string>,
        //                     meta_data: metaData,
        //                     metric_range: this.inputs.metric_range,
        //                     exclusions: this.currentBoard.exclusions,
        //                     display_attributes: this.filterData.display_attributes,
        //                     styling_display_metrics:
        //                         this.filterData.styling_display_metrics ??
        //                         initialState.workbenchFilterData.styling_display_metrics
        //                 },
        //                 gallery: this.gallery
        //             })
        //         );
        //         // } else {
        //         this._store.dispatch(
        //             actionStoreWorkbenchFilterData({
        //                 filterData: {
        //                     time_period: '',
        //                     time_range: this.inputs.time_range,
        //                     time_year: this.inputs.time_year,
        //                     time_quarter: this.inputs.time_quarter,
        //                     time_month: this.inputs.time_month,
        //                     location_company: this.inputs.location_company,
        //                     location_division: this.inputs.location_division,
        //                     location_area: this.inputs.location_area,
        //                     location_region: this.inputs.location_region,
        //                     location_store: this.inputs.location_store,
        //                     sort_order: this.filterData.sort_order,
        //                     display_metrics: this.filterData.display_metrics,
        //                     department: this.inputs.department as Array<string>,
        //                     sub_department: this.inputs.sub_department as Array<string>,
        //                     category: this.inputs.category as Array<string>,
        //                     product_type: this.inputs.product_type as Array<string>,
        //                     style: this.inputs.style as Array<string>,
        //                     meta_data: metaData,
        //                     metric_range: this.inputs.metric_range,
        //                     exclusions: this.currentBoard.exclusions,
        //                     display_attributes: this.filterData.display_attributes,
        //                     styling_display_metrics:
        //                         this.filterData.styling_display_metrics ??
        //                         initialState.workbenchFilterData.styling_display_metrics
        //                 }
        //             })
        //         );
        //         // }
        //     })
        // );
        this.subscriptions.add(
            this.activeApplication$.subscribe((t: IApplicationMenuItemModel) => {
                if (t.name === 'analytics') {
                    this._store.dispatch(
                        actionStoreApplyFilters({
                            boardData: {
                                user_id: this.currentBoard.user_id,
                                board_id: this.currentBoard.board_id,
                                board_name: this.currentBoard.board_name
                            },
                            filterData: {
                                time_period: this.inputs.time_period,
                                time_range: this.inputs.time_range,
                                time_year: this.inputs.time_year,
                                time_quarter: this.inputs.time_quarter,
                                time_month: this.inputs.time_month,
                                location_company: this.inputs.location_company,
                                location_division: this.inputs.location_division,
                                location_area: this.inputs.location_area,
                                location_region: this.inputs.location_region,
                                location_store: this.inputs.location_store,
                                sort_order: this.filterData.sort_order,
                                display_metrics: this.filterData.display_metrics,
                                department: this.inputs.department as Array<string>,
                                sub_department: this.inputs.sub_department as Array<string>,
                                category: this.inputs.category as Array<string>,
                                product_type: this.inputs.product_type as Array<string>,
                                style: this.inputs.style as Array<string>,
                                meta_data: metaData,
                                metric_range: this.inputs.metric_range,
                                exclusions: this.currentBoard.exclusions,
                                display_attributes: this.filterData.display_attributes,
                                styling_display_metrics:
                                    this.filterData.styling_display_metrics ??
                                    initialState.workbenchFilterData.styling_display_metrics
                            }
                        })
                    );
                } else {
                    this._store.dispatch(
                        actionStoreWorkbenchFilterData({
                            filterData: {
                                time_period: '',
                                time_range: this.inputs.time_range,
                                time_year: this.inputs.time_year,
                                time_quarter: this.inputs.time_quarter,
                                time_month: this.inputs.time_month,
                                location_company: this.inputs.location_company,
                                location_division: this.inputs.location_division,
                                location_area: this.inputs.location_area,
                                location_region: this.inputs.location_region,
                                location_store: this.inputs.location_store,
                                sort_order: this.filterData.sort_order,
                                display_metrics: this.filterData.display_metrics,
                                department: this.inputs.department as Array<string>,
                                sub_department: this.inputs.sub_department as Array<string>,
                                category: this.inputs.category as Array<string>,
                                product_type: this.inputs.product_type as Array<string>,
                                style: this.inputs.style as Array<string>,
                                meta_data: metaData,
                                metric_range: this.inputs.metric_range,
                                exclusions: this.currentBoard.exclusions,
                                display_attributes: this.filterData.display_attributes,
                                styling_display_metrics:
                                    this.filterData.styling_display_metrics ??
                                    initialState.workbenchFilterData.styling_display_metrics
                            }
                        })
                    );
                }
            })
        );
    }

    public saveBoard(boardId: number): void {
        const timePeriod: string = this.filterData.time_period.length ? `recent:${this.filterData.time_period}` : '';
        const timeRange: string | Array<string> = this.filterData.time_range.length ? `${this.filterData.time_range}` : [];
        const timeYear: Array<string> = this.filterData.time_year.length
            ? this.filterData.time_year.length > 1
                ? this.filterData.time_year.map((value: string) => `year:${value}`)
                : [`year:${this.filterData.time_year}`]
            : [];
        const timeQuarter: Array<string> = this.filterData.time_quarter.length
            ? this.filterData.time_quarter.length > 1
                ? this.filterData.time_quarter.map((value: string) => `quarter:${value}`)
                : [`quarter:${this.filterData.time_quarter}`]
            : [];
        const timeMonth: Array<string> = this.filterData.time_month.length
            ? this.filterData.time_month.length > 1
                ? this.filterData.time_month.map((value: string) => `month:${value}`)
                : [`month:${this.filterData.time_month}`]
            : [];
        const locationCompany: Array<string> = this.filterData.location_company.length
            ? this.filterData.location_company.length > 1
                ? this.filterData.location_company.map((value: string) => `fin_co_name:'${value}'`)
                : [`fin_co_name:'${this.filterData.location_company}'`]
            : [];
        const locationDivision: Array<string> = this.filterData.location_division.length
            ? this.filterData.location_division.length > 1
                ? this.filterData.location_division.map((value: string) => `division_descr:'${value}'`)
                : [`division_descr:'${this.filterData.location_division}'`]
            : [];
        const locationArea: Array<string> = this.filterData.location_area.length
            ? this.filterData.location_area.length > 1
                ? this.filterData.location_area.map((value: string) => `trademark_descr:'${value}'`)
                : [`trademark_descr:'${this.filterData.location_area}'`]
            : [];
        const locationRegion: Array<string> = this.filterData.location_region.length
            ? this.filterData.location_region.length > 1
                ? this.filterData.location_region.map((value: string) => `region_descr:'${value}'`)
                : [`region_descr:'${this.filterData.location_region}'`]
            : [];
        const locationStore: Array<string> = this.filterData.location_store.length
            ? this.filterData.location_store.length > 1
                ? this.filterData.location_store.map((value: string) => `store_name:'${value}'`)
                : [`store_name:'${this.filterData.location_store}'`]
            : [];
        const sortOrder: string = this.filterData.sort_order.length ? `${this.filterData.sort_order}` : '';
        const displayMetrics: string | Array<string> = this.filterData.display_metrics.length
            ? this.filterData.display_metrics.map((value: IDisplayMetric) => `${value.metric_name}`).join(',')
            : [];
        const stylingDisplayMetrics: string | Array<string> = this.filterData.styling_display_metrics.length
            ? this.filterData.styling_display_metrics.map((value: IDisplayMetric) => `${value.metric_name}`).join(',')
            : [];

        const departmentName: Array<string> = this.filterData.department.length
            ? this.filterData.department.length > 1
                ? this.filterData.department.map((value: string) => `department_name:'${value}'`)
                : [`department_name:'${this.filterData.department}'`]
            : [];

        const subDepartmentName: Array<string> = this.filterData.sub_department.length
            ? this.filterData.sub_department.length > 1
                ? this.filterData.sub_department.map((value: string) => `sub_department_name:'${value}'`)
                : [`sub_department_name:'${this.filterData.sub_department}'`]
            : [];

        const categoryName: Array<string> = this.filterData.category.length
            ? this.filterData.category.length > 1
                ? this.filterData.category.map((value: string) => `category_name:'${value}'`)
                : [`category_name:'${this.filterData.category}'`]
            : [];
        const productTypeName: Array<string> = this.filterData.product_type.length
            ? this.filterData.product_type.length > 1
                ? this.filterData.product_type.map((value: string) => `product_type_name:'${value}'`)
                : [`product_type_name:'${this.filterData.product_type}'`]
            : [];
        const metricRange: string | Array<string> = this.filterData.metric_range.length
            ? this.filterData.metric_range
                .map((value: IMetricRange) => `${value.metric_name}:${value.values[0]}:${value.values[1]}`)
                .join(',')
            : [];
        const time: Array<string> = [];
        if (timeYear.length > 0 || timeQuarter.length > 0 || timeMonth.length > 0) {
            if (timeYear.length > 0) {
                time.push(...timeYear);
            }
            if (timeQuarter.length > 0) {
                time.push(...timeQuarter);
            }
            if (timeMonth.length > 0) {
                time.push(...timeMonth);
            }
        } else {
            time.push(timePeriod);
        }
        const locationFilters: Array<string> = [];
        if (locationCompany.length) {
            locationCompany.map((location: string) => {
                locationFilters.push(location);
            });
        }
        if (locationDivision.length) {
            locationDivision.map((location: string) => {
                locationFilters.push(location);
            });
        }
        if (locationArea.length) {
            locationArea.map((location: string) => {
                locationFilters.push(location);
            });
        }
        if (locationRegion.length) {
            locationRegion.map((location: string) => {
                locationFilters.push(location);
            });
        }
        if (locationStore.length) {
            locationStore.map((location: string) => {
                locationFilters.push(location);
            });
        }
        const metaData: Array<string> = [];
        if (this.filterData.meta_data) {
            for (const property in this.filterData.meta_data) {
                if (this.filterData.meta_data[property]) {
                    for (const value of this.filterData.meta_data[property]) {
                        metaData.push(`${property}:'${value}'`);
                    }
                }
            }
        }
        const metaFilters: string | Array<string> = metaData ? `meta_filters=${metaData.join(',')}` : [];

        const dialogRef: MatDialogRef<DialogBoardSaveComponent, ISavedBoard> = this.dialog.open(DialogBoardSaveComponent, {
            width: '506px',
            data: {
                boardData: {
                    user_id: this.currentBoard.user_id,
                    board_id: this.currentBoard.board_id,
                    board_name: this.currentBoard.board_name,
                    time_period: time.join(','),
                    time_range: timeRange,
                    sort_order: sortOrder,
                    display_metrics: displayMetrics,
                    department_name: departmentName,
                    sub_department_name: subDepartmentName,
                    category_name: categoryName,
                    product_type_name: productTypeName,
                    filter_time: '',
                    filter_product: '',
                    filter_location: locationFilters.join(','),
                    filter_meta: metaFilters,
                    metric_range: metricRange,
                    styling_display_metrics: stylingDisplayMetrics
                },
                saveFirst: !this.filtersSaved
            }
        });

        dialogRef.afterClosed().subscribe(async (result: ISavedBoard) => {
            if (result) {
                this.filtersSaved = true;

                this._store.dispatch(
                    actionStoreSetFilterSaved({
                        filtersSaved: this.filtersSaved
                    })
                );

                this._store.dispatch(
                    actionStoreSetSavedBoards({
                        savedBoards: result
                    })
                );

                this.currentBoard.board_id = result.board_id;
                this.currentBoard.board_name = result.board_name;
                // this.currentBoard['user_id'] = result['user_id'];
            } else {
                this.filtersSaved = true;

                this._store.dispatch(
                    actionStoreSetFilterSaved({
                        filtersSaved: this.filtersSaved
                    })
                );

                return this.getBoard(true, boardId);
            }
        });
    }

    // Used to convert meta-data string to object
    private parseAPIData(str: string): Array<{ [id: string]: string }> {
        const processedData: Array<{ [id: string]: string }> = [];
        const items: Array<string> = str.split(',');

        for (const item of items) {
            const current: Array<string> = item.split(':');
            processedData.push({ [current[0]]: current[1].replace(/'/g, '') });
        }
        return processedData;
    }
}
