import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
    // eslint-disable-next-line promise/always-return
  .bootstrapModule(AppModule).then(() => {
    /* // Ensure Angular destroys itself on hot reloads.
    if (window['ngRef']) {
      window['ngRef'].destroy();
    }
    window['ngRef'] = ref; */
  })
    // eslint-disable-next-line no-console,@typescript-eslint/no-explicit-any
  .catch((err: any) => console.error(err));
