import packageJson from '../../../../package.json';

export const environment: {
    appName: string;
    test: boolean;
    debug: boolean;
    attributes: boolean,
    apiUrl: string;
    production: boolean;
    listingPagination: boolean;
    envName: string;
    version: string;
    userManagementApiUrl: string;
    pictureManagerApiUrl: string;
    awsCognitoIdentityPoolId: string;
    awsUserPoolsId: string;
    awsUserPoolsWebClientId: string;
    pictureManagerCloudfrontDistribution: string;
} = {
    appName: "Lynkd Pattern Analytics",
    envName: 'PROD',
    debug: false,
    production: true,
    test: false,
    attributes: true,
    listingPagination: false,
    version: packageJson.version,
    apiUrl: 'https://gstx2zlzy6.execute-api.af-south-1.amazonaws.com/Prod',
    userManagementApiUrl: 'https://3u5ph4717j.execute-api.af-south-1.amazonaws.com/dev',
    pictureManagerApiUrl: '',
    awsCognitoIdentityPoolId: 'eu-west-1:8ae7fad8-ef94-4005-a3d2-bfd7854dac07',
    awsUserPoolsId: 'eu-west-1_3r2G4lEfq',
    awsUserPoolsWebClientId: 'm6i8ec5f0sqsk6c0a64306h5c',
    pictureManagerCloudfrontDistribution: ''
};
