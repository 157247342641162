<div>
    <h4 matDialogTitle>Export Gallery</h4>
</div>
<div matDialogContent class="dialog-content">
    <div class="gallery-export" #gallery>
        <div class="gallery-export__header" *ngIf="fileHeader?.length > 0">
            <p>{{ fileHeader }}</p>
        </div>

        <div class="gallery-export__sub-header" *ngIf="fileSubHeader?.length > 0">
            <p>{{ fileSubHeader }}</p>
        </div>

        <div *ngFor="let item of galleryItems" class="gallery-item" [ngClass]="getLayout()">
            <ng-container *ngIf='item'>
            <img [src]="item.image_url" alt=""/>
            <div class="metrics" *ngIf="data.filterData.length > 0">
                <span *ngFor="let filter of data.filterData">
                    <small class="font-weight-bold">{{filter.metric_name | displayName }}:</small>
                    <small>{{ item[filter.metric_name] }}</small>
                </span>
            </div>
            </ng-container>
        </div>
    </div>

    <div class="export-settings">
        <div class="export-settings__layout-selector">
            <mat-form-field>
                <mat-label class="font-weight-bold">Select Layout</mat-label>
                <mat-select [(value)]="selectedConfig" (selectionChange)="setGalleryLayout()">
                    <mat-option
                        *ngFor="let config of layoutConfigs"
                        [value]="config">{{config.layout}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="export-settings__file-header">
            <label class="font-weight-bold">Enter header</label>
            <mat-form-field class="w-100">
                <input matInput [(ngModel)]="fileHeader"/>
            </mat-form-field>
        </div>

        <div class="export-settings__file-header">
            <label class="font-weight-bold">Enter sub-header</label>
            <mat-form-field class="w-100">
                <input matInput [(ngModel)]="fileSubHeader"/>
            </mat-form-field>
        </div>

        <div class="export-settings__page-size-selector">
            <label class="font-weight-bold d-block">Choose page size</label>
            <mat-radio-group [(ngModel)]="selectedPageSize">
                <mat-radio-button class="page-size-option"
                                  [value]="pageSize"
                                  *ngFor="let pageSize of pageSizes">{{pageSize.name}}</mat-radio-button>

            </mat-radio-group>
        </div>

        <div class="export-settings__file-type-section">
            <label id="file-type-select-label">Export as</label>
            <mat-radio-group
                aria-labelledby="file-type-select-label"
                class="file-type-selections"
                [(ngModel)]="exportType">
                <mat-radio-button class="file-type-option" [value]="'PPTX'">PPTX</mat-radio-button>
                <mat-radio-button class="file-type-option" [value]="'PDF'">PDF</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="export-settings__file-name">
            <label class="font-weight-bold">Enter file name</label>
            <mat-form-field class="w-100">
                <input matInput [(ngModel)]="filename"/>
            </mat-form-field>
        </div>

        <div class="export-settings__button-layout">
            <button
                mat-button
                (click)="close()"
                class="btn btn-primary cancel-btn">Cancel</button>

            <button
                *ngIf="exportType === 'PPTX'"
                (click)="exportAsPowerpoint()"
                class="btn btn-primary export-btn">Export</button>

            <button
                *ngIf="exportType === 'PDF'"
                (click)="exportAsPdf()"
                class="btn btn-primary export-btn">Export</button>
        </div>

    </div>
</div>
<div matDialogActions class="d-flex flex-row justify-content-between align-items-center">
    <div class="button-layout">
        <span class="d-flex flex-row justify-content-start align-items-center">
            <p class="font-weight-bold mr-2">Page Count:</p>
            <p>{{ galleryItemsPages?.length }}</p>
        </span>
    </div>
</div>
