import { IProductDisplayAttribute } from './product-display-attribute.model';

export const defaultDisplayAttributes: Array<IProductDisplayAttribute> = [
    // {
    //     column_name: 'category_name'
    // },
    {
        column_name: 'product_type_name'
    },
    {
        column_name: 'attr_colour_name'
    },
    {
        column_name: 'attr_supplier_name'
    }
];
