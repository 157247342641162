import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'descriptionShorter'
})
export class DescriptionShorterPipe implements PipeTransform {
    public transform(value: string): string {
        if (!value) {
            return value;
        }
        const parts: Array<string> = value.split('-');
        return parts[parts.length - 1];
    }
}
