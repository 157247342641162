import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormatDisplayNamePipe } from '../../../../core/data/format-display-name.pipe';
import { IDisplayMetric } from '../../../../core/models/display-metric.model';
import { IProduct } from '../../../../core/models/product.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
    selector: 'lynkd-pattern-table-listing',
    templateUrl: './table-listing.component.html',
    styleUrls: ['./table-listing.component.scss']
})
export class TableListingComponent implements OnInit {
    public get products(): Array<IProduct> {
        return this._products;
    }

    @Input()
    public set products(value: Array<IProduct>) {
        this._products = value;
        this.gridSource.paginator = this.paginator;
        this.gridSource.data = this.products;
        this.gridSource.sort = this.sort;
    }

    public paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), this._ref);

    public gridLength: number = 30;

    public gridSource: MatTableDataSource<IProduct> = new MatTableDataSource();

    @Input()
    public gridColumns: Array<string>;
    @Input()
    public displayMetrics: Array<IDisplayMetric>;

    @Output()
    public readonly sortChange: EventEmitter<void> = new EventEmitter<void>();

    // Todo: Investigate not passing native elements around
    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-native
    public readonly drop: EventEmitter<CdkDragDrop<IProduct, IProduct>> = new EventEmitter<CdkDragDrop<IProduct, IProduct>>();

    @Output()
    public readonly selectProduct: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public readonly excludeProduct: EventEmitter<IProduct> = new EventEmitter<IProduct>();

    @ViewChild(MatSort)
    public sort: MatSort;
    private readonly _formatDisplayName: FormatDisplayNamePipe;
    private _products: Array<IProduct>;

    public constructor(private readonly _ref: ChangeDetectorRef) {
        this._formatDisplayName = new FormatDisplayNamePipe();
    }

    public ngOnInit(): void {
        this.paginator.pageSize = this.gridLength;

        // Todo: Fix hacky way of adding virtual scroll with pagination
        // eslint-disable-next-line @angular-eslint/no-lifecycle-call
        this.paginator.ngOnInit();

        this.gridSource.paginator = this.paginator;
        this.gridSource.data = this.products;
        this.gridSource.sort = this.sort;
    }

    public onScroll(event: HTMLElement): void {
        const elem: HTMLDivElement = event as HTMLDivElement;

        if (elem.scrollHeight - elem.scrollTop === elem.clientHeight) {
            this.gridLength += 30;
            this.paginator._changePageSize(this.gridLength);
        }
    }


    public onDrop(event: CdkDragDrop<IProduct, IProduct>): void {
        this.drop.emit(event);
    }

    public onSelectProduct(productId: string): void {
        this.selectProduct.emit(productId);
    }

    public onExcludeProduct(product: IProduct): void {
        this.excludeProduct.emit(product);
    }

    public getDisplayName(field: string): string {
        return this.displayMetrics?.find((t: IDisplayMetric): boolean => t.metric_name === field)?.metric_acronym?.trim()
            || this._formatDisplayName.transform(field);
    }
}
