import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../../environments/environment';

import { IAppState, reducers, metaReducers, selectRouterState } from './core.state';

import { AuthEffects } from './auth/auth.effects';
import { selectIsAuthenticated, selectAuth } from './auth/auth.selectors';
import { authLogin, authLogout } from './auth/auth.actions';
import { AuthGuardService } from './auth/auth-guard.service';
import { TitleService } from './title/title.service';
import { routeAnimationsElements, routeAnimations } from './animations/route.animations';

import { AnimationsService } from './animations/animations.service';
import { AppErrorHandler } from './error-handler/app-error-handler.service';
import { CustomSerializer } from './router/custom-serializer';
import { LocalStorageService } from './local-storage/local-storage.service';
import { HttpErrorInterceptor } from './http-interceptors/http-error.interceptor';
import { GoogleAnalyticsEffects } from './google-analytics/google-analytics.effects';
import { NotificationService } from './notifications/notification.service';
import { StoreEffects } from './store/store.effects';
import { BoardService } from './data/board.service';

export {
    TitleService,
    selectAuth,
    authLogin,
    authLogout,
    routeAnimations,
    IAppState,
    LocalStorageService,
    selectIsAuthenticated,
    routeAnimationsElements,
    AnimationsService,
    AuthGuardService,
    selectRouterState,
    NotificationService
};

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreRouterConnectingModule.forRoot(),
        MatProgressSpinnerModule,
        EffectsModule.forRoot([AuthEffects, StoreEffects, GoogleAnalyticsEffects]),
        environment.production
            ? []
            : StoreDevtoolsModule.instrument({
                  name: 'Lynkd Pattern Analytics'
              })
    ],
    declarations: [],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: ErrorHandler, useClass: AppErrorHandler },
        { provide: RouterStateSerializer, useClass: CustomSerializer },
        BoardService
    ]
})
export class CoreModule {
    public constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
}
