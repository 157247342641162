import { Component, Input } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { IProductDisplayAttribute } from '../../../core/models/product-display-attribute.model';
import { DataService } from '../../../core/data/data.service';
import { Observable, of } from 'rxjs';
import { IProductAttributeRow } from '../../../core/models/product-attribute-row.model';
import { IProductAttributeParameters } from '../../../core/models/product-attribute-parameters.model';
import { GraphDisplayMode } from '../../../core/models/graph-display-mode.enum';

@Component({
    selector: 'lynkd-pattern-attribute-row',
    templateUrl: './attribute-row.component.html',
    styleUrls: ['./attribute-row.component.scss']
})
export class AttributeRowComponent {
    public get attributeParameters(): IProductAttributeParameters {
        return this._attributeParameters;
    }

    @Input()
    public set attributeParameters(value: IProductAttributeParameters) {
        if (
            !this._attributeParameters ||
            !(
                this._attributeParameters.attribute_column === value.attribute_column &&
                this._attributeParameters.ids === value.ids &&
                this._attributeParameters.department === value.department &&
                this._attributeParameters.sub_department === value.sub_department &&
                this._attributeParameters.category === value.category &&
                this._attributeParameters.product_type === value.product_type &&
                this._attributeParameters.metric_range === value.metric_range &&
                this._attributeParameters.fieldsSet === value.fieldsSet &&
                this._attributeParameters.sortOrder === value.sortOrder &&
                this._attributeParameters.pageSize === value.pageSize &&
                this._attributeParameters.meta_data === value.meta_data &&
                // this._attributeParameters.location_store === value.location_store &&
                // this._attributeParameters.location_region === value.location_region &&
                // this._attributeParameters.location_area === value.location_area &&
                // this._attributeParameters.location_division === value.location_division &&
                // this._attributeParameters.location_company === value.location_company &&
                this._attributeParameters.time_month === value.time_month &&
                this._attributeParameters.time_quarter === value.time_quarter &&
                this._attributeParameters.time_year === value.time_year &&
                this._attributeParameters.time_range === value.time_range &&
                this._attributeParameters.time_period === value.time_period &&
                this._attributeParameters.display_attributes === value.display_attributes
            )
        ) {
            this._attributeParameters = JSON.parse(JSON.stringify(value));
            if (this.attribute && this.attribute.column_name) {
                this.attributeRows$ = this._dataService
                    .getProductAttributes({
                        ...this._attributeParameters,
                        attribute_column: this.attribute.column_name
                    })
                    .pipe(catchError(() => of([])));
            }
        }
    }

    public get attribute(): IProductDisplayAttribute {
        return this._attribute;
    }

    @Input()
    public set attribute(value: IProductDisplayAttribute) {
        this._attribute = value;
        this.attributeRows$ = this._dataService
            .getProductAttributes({
                ...this._attributeParameters,
                attribute_column: value.column_name
            })
            .pipe(catchError(() => of([])));
    }

    @Input()
    public graph: GraphDisplayMode;

    @Input()
    public sideNavOpenState: boolean;

    public attributeRows$: Observable<Array<IProductAttributeRow>>;
    private _attributeParameters: IProductAttributeParameters;
    private _attribute: IProductDisplayAttribute;

    public constructor(private readonly _dataService: DataService) {}
}
