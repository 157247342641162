import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { IProduct } from '../../../../core/models/product.model';
import { IFilterData } from '../../../../core/models/filter-data.model';
import { IState } from '../../../../core/store/store.model';
import { Store } from '@ngrx/store';
import { actionStoreApplyFilters } from '../../../../core/store/store.actions';
import { ICurrentBoard } from '../../../../core/models/current-board.model';
import { IInputs } from '../../../../core/models/inputs.model';
import { ListingDisplayMode } from '../../../../core/models/display-mode.enum';

@Component({
    selector: 'lynkd-pattern-gallery-listing',
    templateUrl: './gallery-listing.component.html',
    styleUrls: ['./gallery-listing.component.scss']
})
export class GalleryListingComponent {
    public enablePagination: boolean = environment.listingPagination;
    @Input()
    public products: Array<IProduct>;

    @Input()
    public filterData: IFilterData;

    @Input()
    public currentBoard: ICurrentBoard;

    @Input()
    public inputs: IInputs;

    @Input()
    public sortDirection: string;

    @Input()
    public gallery: ListingDisplayMode;

    @Input()
    public currentPage: number = 1;

    @Input()
    public itemsPerPage: number;

    @Input()
    public showDisplayMetrics: boolean;

    @Input()
    public itemHeight: number;

    @Output()
    public readonly selectProduct: EventEmitter<string> = new EventEmitter<string>();

    @Input()
    public metricAlignment: boolean = false;

    public constructor(private readonly _store: Store<IState>) {}

    public viewProduct(productId: string): void {
        this.selectProduct.emit(productId);
    }

    public metricStyle(): Record<string, string> {
        if (this.metricAlignment === false) {
            return {
                bottom: '10px'
            };
        } else {
            return {
                top: '10px',
                bottom: 'unset'
            };
        }
    }

    public exclude(product: IProduct): void {
        (this.filterData.exclusions as Array<IProduct>).push(product);

        this._store.dispatch(
            actionStoreApplyFilters({
                boardData: {
                    user_id: this.currentBoard.user_id,
                    board_id: this.currentBoard.board_id,
                    board_name: this.currentBoard.board_name
                },
                filterData: {
                    time_period: this.filterData.time_period,
                    time_range: this.filterData.time_range,
                    time_year: this.filterData.time_year,
                    time_quarter: this.filterData.time_quarter,
                    time_month: this.filterData.time_month,
                    location_company: this.filterData.location_company,
                    location_division: this.filterData.location_division,
                    location_area: this.filterData.location_area,
                    location_region: this.filterData.location_region,
                    location_store: this.filterData.location_store,
                    sort_order: this.inputs.sort_order.length ? `${this.inputs.sort_order}:${this.sortDirection}` : '',
                    display_metrics: this.inputs.display_metrics,
                    department: this.filterData.department,
                    sub_department: this.filterData.sub_department,
                    category: this.filterData.category,
                    product_type: this.filterData.product_type,
                    meta_data: this.filterData.meta_data,
                    metric_range: this.filterData.metric_range,
                    exclusions: this.filterData.exclusions,
                    display_attributes: this.inputs.display_attributes
                }
            })
        );
    }
}
