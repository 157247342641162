import { Injectable } from '@angular/core';
import {
    Router,
    NavigationEnd,
    RouterEvent,
    NavigationStart,
    NavigationCancel,
    NavigationError,
    RoutesRecognized,
    GuardsCheckStart,
    GuardsCheckEnd,
    RouteConfigLoadStart,
    RouteConfigLoadEnd,
    ChildActivationStart,
    ChildActivationEnd,
    ActivationStart,
    ActivationEnd, Scroll, ResolveStart, ResolveEnd
} from '@angular/router';
import { createEffect, CreateEffectMetadata } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { tap, filter } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class GoogleAnalyticsEffects {

  public pageView: (() => Observable<NavigationEnd>) & CreateEffectMetadata = createEffect(
    () => (): Observable<NavigationEnd> =>
      this._router.events.pipe(
        filter((event: RouterEvent | NavigationStart | NavigationEnd | NavigationCancel | NavigationError
            | RoutesRecognized | GuardsCheckStart | GuardsCheckEnd | RouteConfigLoadStart | RouteConfigLoadEnd
            | ChildActivationStart | ChildActivationEnd | ActivationStart | ActivationEnd | Scroll
            | ResolveStart | ResolveEnd) => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).ga('set', 'page', event.urlAfterRedirects);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).ga('send', 'pageview');
        })
      ),
    { dispatch: false }
  );

    public constructor(private readonly _router: Router) {}
}
