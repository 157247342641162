import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';


/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable({providedIn: 'root'})
export class AppErrorHandler extends ErrorHandler {
    public constructor() {
        super();
    }

    public handleError(error: Error | HttpErrorResponse): void {
        let displayMessage: string = `An error occurred. (${'HttpErrorResponse'})`;

        if (!environment.production) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            displayMessage += ' See console for details.';
        }

        // this.notificationService.error(displayMessage);

        // eslint-disable-next-line no-console
        console.log(error);
        super.handleError(error);
    }
}
