import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {
    public transform(input?: number, args: number = 2): string {
        if (input === undefined || input === null) {
            return '';
        }
        let exp: number;
        const suffixes: Array<string> = ['K', 'M', 'B', 'T', 'P', 'E'];
        const isNegativeValues: boolean = input < 0;

        if (Number.isNaN(input) || (input < 1000 && input >= 0) || !this.isNumeric(input) || (input < 0 && input > -1000)) {
            return input.toFixed(args);
        }

        if (!isNegativeValues) {
            exp = Math.floor(Math.log(input) / Math.log(1000));

            return (input / Math.pow(1000, exp)).toFixed(2) + suffixes[exp - 1];
        } else {
            const localInput: number = input * -1;

            exp = Math.floor(Math.log(localInput) / Math.log(1000));

            return ((localInput * -1) / Math.pow(1000, exp)).toFixed(2) + suffixes[exp - 1];
        }
    }

    public isNumeric(value: number | string): boolean {
        if (value < 0) {
            value = (value as number) * -1;
        }
        if (/^-{0,1}\d+$/.test(value as string)) {
            return true;
        } else return /^\d+\.\d+$/.test(value as string);
    }
}
