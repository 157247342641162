import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AllocationsViewModel } from '../../features/workbench/models/allocations-view-model.enum';

@Injectable({
    providedIn: 'root'
})
export class AllocationsNavigationService {
    public activeAllocationView$: Observable<AllocationsViewModel>;
    private readonly activeAllocationView: BehaviorSubject<AllocationsViewModel> = new BehaviorSubject(AllocationsViewModel.Workbench);

    public constructor() {
        this.activeAllocationView$ = this.activeAllocationView.asObservable();
    }

    public setActiveAllocationView(view: AllocationsViewModel): void {
        this.activeAllocationView.next(view);
    }
}
