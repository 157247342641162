<ng-container *ngIf="savedBoards$">
    <mat-nav-list *ngIf="savedBoards$ | async; else noItems" class="list-links">
        <mat-list-item class="search-boards">
            <input type="text" placeholder="Search your boards" [formControl]="savedBoardsSearch" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let board of filteredSavedBoards$ | async" [value]="board" class="text-capitalize">
                    {{ board.board_name }}
                </mat-option>
            </mat-autocomplete>
        </mat-list-item>
        <a
            mat-list-item
            *ngFor="let board of filteredSavedBoards$ | async"
            (click)="selectBoard($event, board.board_id)"
            class="board-name"
        >
            <div class="content-wrap">
                <div class="board-name-text" [matTooltip]="board.board_name" matTooltipPosition="right" matTooltipHideDelay="100">
                    {{ board.board_name }}
                </div>
                <div class="board-delete-btn">
                    <div>
                        <button mat-button [matMenuTriggerFor]="menu">...</button>
                    </div>
                    <div>
                        <mat-menu #menu="matMenu">
                            <a (click)="editSavedBoard($event, board)" class="board-edit">
                                <fa-icon icon="edit" class="mr-4"></fa-icon>
                                <p class="m-0 p-0">Edit</p>
                            </a>
                            <a (click)="deleteSavedBoard($event, board.board_id)" class="board-delete">
                                <fa-icon icon="trash" class="mr-4"></fa-icon>
                                <p class="m-0 p-0">Delete</p>
                            </a>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </a>
    </mat-nav-list>
</ng-container>

<ng-template #noItems>
    <mat-nav-list class="no-items">
        <a mat-list-item>
            <div class="d-flex align-items-center justify-content-between">
                <span>No My boards found</span>
            </div>
        </a>
    </mat-nav-list>
</ng-template>

<ng-template #loading>
    <mat-nav-list class="no-items">
        <a mat-list-item>
            <div class="d-flex align-items-center justify-content-between">
                <span>Updating My boards</span>
            </div>
        </a>
    </mat-nav-list>
</ng-template>
