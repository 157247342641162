import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterStateSnapshot } from "@angular/router";
import { AllocationsViewModel } from '../../../features/workbench/models/allocations-view-model.enum';
import { AllocationsNavigationService } from '../../services/allocations-navigation.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'lynkd-pattern-allocations-views',
    templateUrl: './allocations-views.component.html',
    styleUrls: ['./allocations-views.component.scss']
})
export class AllocationsViewsComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/typedef
    public AllocationsViewModel = AllocationsViewModel;
    public activeView: AllocationsViewModel;
    private readonly _unsubscribe: Subject<void> = new Subject();

    public constructor(private readonly _router: Router, private readonly _allocationsNavigationService: AllocationsNavigationService) {}

    public ngOnInit(): void {
        this.setActiveView();

        this._allocationsNavigationService.activeAllocationView$
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((view: AllocationsViewModel) => (this.activeView = view));
    }

    public ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    public async changeView(view: AllocationsViewModel): Promise<boolean> {
        this._allocationsNavigationService.setActiveAllocationView(view);
        return this._router.navigate(['/allocations', view]);
    }

    /**
     * Ensures that the displayed view matches with the selection on the allocations
     * navbar when navigated to.
     */
    public setActiveView(): void {
        const initialRouterStateSnapshot: RouterStateSnapshot = this._router.routerState.snapshot;
        const currentPath: string = initialRouterStateSnapshot.url.split('/').pop();

        if (currentPath.toLowerCase() === 'workbench') {
            this._allocationsNavigationService.setActiveAllocationView(AllocationsViewModel.Workbench);
        } else if (currentPath.toLowerCase() === 'settings') {
            this._allocationsNavigationService.setActiveAllocationView(AllocationsViewModel.Settings);
        }
    }
}
