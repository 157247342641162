import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AppConfigService } from '../../core/data/app-config.service';
import { ICreateUserMetaData } from '../models/create-user-meta-data.model';
import { IDeleteUserMetaData } from '../models/delete-user-meta-data.model';
import { IUserMetaData } from '../models/user-meta-data.model';

@Injectable({
  providedIn: 'root'
})
export class UserMetaDataService {
    public apiUrl: string = this._appConfigService.apiUrl;
    public baseUrl: string = '/api/usermetadata';

    public constructor(private readonly _http: HttpClient, private readonly _appConfigService: AppConfigService) {}

    public async getUserMetaData(userId: string): Promise<Array<IUserMetaData>>{
        return firstValueFrom(this._http.get<Array<IUserMetaData>>(`${this.apiUrl + this.baseUrl}?userId=${userId}`));
    }

    public async addMetaData(metaData: ICreateUserMetaData): Promise<void>{
        return firstValueFrom(this._http.post<void>(`${this.apiUrl + this.baseUrl}`, metaData));
    }

    public async deleteMetaData(metaData: IDeleteUserMetaData): Promise<void>{
        return firstValueFrom(this._http.delete<void>(`${this.apiUrl + this.baseUrl}`, {body: metaData}));
    }
}
