import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { Observable } from 'rxjs';
import { authLogout } from '../../../core/auth/auth.actions';
import { selectIsAuthenticated } from '../../../core/auth/auth.selectors';
import { IState } from '../../../core/store/store.model';
import { IApplicationMenuItemModel } from '../../models/application-menu-item.model';

@Component({
    selector: 'lynkd-pattern-navigation-bar',
    templateUrl: './navigation-bar.component.html',
    styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {
    @Input()
    public application: IApplicationMenuItemModel;

    public isAuthenticated$: Observable<boolean>;

    public constructor(private readonly _store: Store<IState>) {}

    public ngOnInit(): void {
        this.isAuthenticated$ = this._store.select(selectIsAuthenticated);
    }

    public async logout(): Promise<void> {
        try {
            await Auth.signOut();
            this._store.dispatch(authLogout());
        } catch (e) {
            // Todo: Error handling on signout
        }
    }
}
