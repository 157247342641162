import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DisplayModelLabel } from '../../../core/models/display-model-label.enum';

@Component({
    selector: 'lynkd-pattern-analytics-views',
    templateUrl: './analytics-views.component.html',
    styleUrls: ['./analytics-views.component.scss']
})
export class AnalyticsViewsComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/typedef
    public readonly DisplayModelLabel = DisplayModelLabel;
    public activeView: DisplayModelLabel = DisplayModelLabel.Gallery;

    public constructor(private readonly _router: Router, private readonly _route: ActivatedRoute) {}

    public async changeView(view: DisplayModelLabel): Promise<boolean> {
        this.activeView = view;
        return this._router.navigate(['/analytics', view]);
    }

    public ngOnInit(): void {
        this._route.params.subscribe((params: Params) => {
            this.activeView = params.view;
        });
    }
}
