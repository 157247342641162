import { Action, ActionReducer } from '@ngrx/store';

import { IAppState } from '../core.state';

export function debug(reducer: ActionReducer<IAppState>): ActionReducer<IAppState> {
    return (state: IAppState, action: Action) => {
        const newState: IAppState = reducer(state, action);
        // eslint-disable-next-line no-console
        console.log(`[DEBUG] action: ${action.type}`, {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            payload: (action as any),
            oldState: state,
            newState
        });
        return newState;
    };
}
