import { ComponentRef, Injectable } from '@angular/core';

// cdk
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

// rxjs
import { Subject } from 'rxjs';
import { scan, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoadingSpinnerService {
    public spin$: Subject<boolean> = new Subject();
    private readonly spinnerTopRef: OverlayRef = this.cdkSpinnerCreate();

    public constructor(private readonly _overlay: Overlay) {
        this.spin$
            .asObservable()
            .pipe(
                map((val: boolean) => (val ? 1 : -1)),
                scan((acc: number, one: number) => (acc + one >= 0 ? acc + one : 0), 0)
            )
            .subscribe((res: number) => {
                if (res === 1) {
                    this.showSpinner();
                } else if (res === 0) {
                    if (this.spinnerTopRef.hasAttached()) {
                        this.stopSpinner();
                    }
                }
            });
    }

    private cdkSpinnerCreate(): OverlayRef {
        return this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-dark-backdrop',
            positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically()
        });
    }

    private showSpinner(): void {
        if (!this.spinnerTopRef.hasAttached()) {
            const spinnerRef: ComponentRef<MatProgressSpinner> = this.spinnerTopRef.attach(new ComponentPortal(MatProgressSpinner));
            spinnerRef.instance.color = "primary";
            spinnerRef.instance.mode = "indeterminate";
            spinnerRef.instance.diameter = 100;
        }
    }

    private stopSpinner(): void {
        this.spinnerTopRef.detach();
    }
}
