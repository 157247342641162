import { Injectable } from '@angular/core';

// const currentBoardStorageKey = 'boardId';

@Injectable({
  providedIn: 'root'
})
export class BoardService {
  //
  //
  // constructor(private readonly http: HttpClient) { }
  //
  // saveCurrentBoardName(name: string) {
  //   localStorage.setItem(currentBoardStorageKey, name);
  // }
  //
  // getCurrentBoardName(): string | undefined {
  //   return localStorage.getItem(currentBoardStorageKey);
  // }

}
