import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ICurrentBoard } from '../../../core/models/current-board.model';
import { IPaginationLabels } from '../../../core/models/pagination-labels.model';
import { IProductDisplayAttribute } from '../../../core/models/product-display-attribute.model';
import { DataService } from '../../../core/data/data.service';
import { Observable } from 'rxjs';
import { IProductAttributeParameters } from '../../../core/models/product-attribute-parameters.model';
import { map, takeWhile } from 'rxjs/operators';
import { GraphDisplayMode } from '../../../core/models/graph-display-mode.enum';

@Component({
    selector: 'lynkd-pattern-attribute-listing',
    templateUrl: './attribute-listing.component.html',
    styleUrls: ['./attribute-listing.component.scss']
})
export class AttributeListingComponent implements OnInit, OnDestroy {
    public get attributeParameters(): IProductAttributeParameters {
        return this._attributeParameters;
    }
    @Input()
    public set attributeParameters(value: IProductAttributeParameters) {
        this.currentPage = 0;
        this._attributeParameters = value;
        if (this._attributeParameters.display_attributes && this._attributeParameters.display_attributes.length > 0) {
            const columnNames: Array<string> = value.display_attributes.map((t: IProductDisplayAttribute) => t.column_name);
            this.attributes$ = this._dataService
                .getProductDisplayAttributes()
                .pipe(
                    map((t: Array<IProductDisplayAttribute>) =>
                        t.filter((x: IProductDisplayAttribute) => columnNames.includes(x.column_name))
                    )
                );
            this.attributes$
                .pipe(takeWhile(() => this._isActive))
                .subscribe((t: Array<IProductDisplayAttribute>) => (this.attributes = t));
        } else {
            this.attributes$ = this._dataService.getProductDisplayAttributes();
        }
    }
    @Input()
    public currentBoard: ICurrentBoard;
    @Input()
    public sideNavOpenState: boolean;
    public attributes$: Observable<Array<IProductDisplayAttribute>>;
    public attributes: Array<IProductDisplayAttribute>;
    public loading: boolean;
    public itemsPerPage: number = 3;
    public graph: GraphDisplayMode = GraphDisplayMode.Bar;

    public currentPage: number;
    public labels: IPaginationLabels = {
        previousLabel: '',
        nextLabel: ''
    };
    private _isActive: boolean = true;

    private _attributeParameters: IProductAttributeParameters;

    public constructor(private readonly _dataService: DataService) {}

    public ngOnInit(): void {
        this.attributes$ = this._dataService.getProductDisplayAttributes();
    }

    public ngOnDestroy(): void {
        this._isActive = false;
    }
}
