<mat-button-toggle-group [value]="activeView" aria-label="View selection">
    <mat-button-toggle [value]="AllocationsViewModel.Insights" disabled (click)="changeView(AllocationsViewModel.Insights)"
        >Insights
    </mat-button-toggle>
    <mat-button-toggle [value]="AllocationsViewModel.Workbench" (click)="changeView(AllocationsViewModel.Workbench)"
        >Workbench
    </mat-button-toggle>
    <mat-button-toggle [value]="AllocationsViewModel.Settings" (click)="changeView(AllocationsViewModel.Settings)"
        >Settings
    </mat-button-toggle>
    <mat-button-toggle [value]="AllocationsViewModel.Summaries" disabled (click)="changeView(AllocationsViewModel.Summaries)"
        >Summaries
    </mat-button-toggle>
</mat-button-toggle-group>
