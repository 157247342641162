import { createSelector, MemoizedSelector } from '@ngrx/store';
import { ISize } from '../../features/workbench/models/size.model';
import { IStyleParametersModel } from '../../features/workbench/models/style-parameters.model';
import { IWorkbenchParameterModel } from '../../features/workbench/models/workbench-parameter.model';
import { IBoard } from '../models/board.model';
import { IDisplayMetric } from '../models/display-metric.model';
import { IFilterData } from '../models/filter-data.model';
import { IProductDisplayAttributeMetric } from '../models/product-display-attribute-metric.model';
import { ISavedBoard } from '../models/saved-board.model';

import { IStoreState } from './store.model';
import { IAppState, selectStoreState } from '../core.state';
import { IUserRole } from '../models/user-roles.model';

export const selectStore: MemoizedSelector<IAppState, IStoreState> = createSelector(
    selectStoreState,
    (state: IStoreState) => state
);

export const selectUserDetails: MemoizedSelector<IAppState, { email: string; roles: Array<IUserRole> }> = createSelector(
    selectStore,
    (state: IStoreState) => state.userDetails
);

export const selectFilterData: MemoizedSelector<IAppState, IFilterData> = createSelector(
    selectStore,
    (state: IStoreState) => state.filterData
);

export const selectBoardData: MemoizedSelector<IAppState, IBoard> = createSelector(
    selectStore,
    (state: IStoreState) => state.boardData
);

export const selectSavedBoards: MemoizedSelector<IAppState, ISavedBoard> = createSelector(
    selectStore,
    (state: IStoreState) => state.savedBoards
);

export const selectPatternBoards: MemoizedSelector<IAppState, Array<string>> = createSelector(
    selectStore,
    (state: IStoreState) => state.patternBoards
);

export const selectFiltersSaved: MemoizedSelector<IAppState, boolean> = createSelector(
    selectStore,
    (state: IStoreState) => state.filtersSaved
);

export const selectWorkbenchFilterData: MemoizedSelector<IAppState, IFilterData> = createSelector(
    selectStore,
    (state: IStoreState) => state.workbenchFilterData
);

export const selectWorkbenchSort: MemoizedSelector<IAppState, { sortOrder?: string; sortDirection?: 'asc' | 'desc' }> =
    createSelector(selectStore, (state: IStoreState) => state.workbenchSort);

export const selectWorkbenchSearchTerm: MemoizedSelector<IAppState, string> = createSelector(
    selectStore,
    (state: IStoreState) => state.workbenchSearchTerm
);

export const selectStylings: MemoizedSelector<IAppState, { stylings: Array<string> }> = createSelector(
    selectStore,
    (state: IStoreState) => ({ stylings: state.stylings })
);
export const selectStylingSort: MemoizedSelector<IAppState, { sortOrder?: string; sortDirection?: 'asc' | 'desc' }> =
    createSelector(selectStore, (state: IStoreState) => state.stylingSort);

export const selectAttributeDisplayMetrics: MemoizedSelector<
    IAppState,
    { display_metrics: Array<IProductDisplayAttributeMetric> }
> = createSelector(selectStore, (state: IStoreState) => ({ display_metrics: state.attributeDisplayMetrics }));

export const selectAttributeTableSort: MemoizedSelector<IAppState, { sortOrder?: string; sortDirection?: 'asc' | 'desc' }> =
    createSelector(selectStore, (state: IStoreState) => state.attributeSort);
export const selectWorkbenchDisplayMetrics: MemoizedSelector<IAppState, { display_metrics: Array<IDisplayMetric> }> =
    createSelector(selectStore, (state: IStoreState) => ({ display_metrics: state.workbenchFilterData?.display_metrics }));
export const selectStylingDisplayMetrics: MemoizedSelector<IAppState, { display_metrics: Array<IDisplayMetric> }> =
    createSelector(selectStore, (state: IStoreState) => ({
        display_metrics: state.workbenchFilterData?.styling_display_metrics
    }));
export const selectWorkbenchStyleSizes: MemoizedSelector<IAppState, { sizes: Array<ISize> }> = createSelector(
    selectStore,
    (state: IStoreState) => ({ sizes: state.workbenchSizes })
);

export const selectStyleParameters: MemoizedSelector<IAppState, { parameters: IStyleParametersModel }> = createSelector(
    selectStore,
    (state: IStoreState) => ({ parameters: state.styleParameters })
);

export const selectWorkbenchParameters: MemoizedSelector<IAppState, { parameters: IWorkbenchParameterModel }> = createSelector(
    selectStore,
    (state: IStoreState) => ({ parameters: state.workbenchParameters })
);


// export const selectExclusionsSaved = createSelector(
//   selectStore,
//   (state: StoreState) => state.exclusions
// );

// export const selectGalleryState: MemoizedSelector<IAppState, ListingDisplayMode> =
//     createSelector(selectStore, (state: IStoreState) => state.gallery);
