import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../core/data/app-config.service';
import { lastValueFrom } from 'rxjs';
import { IProductDisplayAttributeMetric } from '../../core/models/product-display-attribute-metric.model';

@Injectable({
    providedIn: 'root'
})
export class AttributeService {
    public apiUrl: string = this._appConfigService.apiUrl;
    public baseUrl: string = '/api/defaultproductattribute';
    public availableMetricsBaseUrl: string = '/api/defaultproductattribute/available';
    private _defaultProductDisplayAttributeMetrics: Array<IProductDisplayAttributeMetric>;
    private _availableProductDisplayAttributeMetrics: Array<IProductDisplayAttributeMetric>;

    public constructor(private readonly _http: HttpClient, private readonly _appConfigService: AppConfigService) {
    }

    public async getDefaultProductDisplayAttributeMetrics(): Promise<Array<IProductDisplayAttributeMetric>> {
        const query: string = encodeURI(`${this.apiUrl}${this.baseUrl}`);
        if (this._defaultProductDisplayAttributeMetrics){
            return this._defaultProductDisplayAttributeMetrics;
        }
        this._defaultProductDisplayAttributeMetrics = await lastValueFrom(this._http.get<Array<IProductDisplayAttributeMetric>>(query));
        return this._defaultProductDisplayAttributeMetrics;
    }

    public async getAvailableProductDisplayAttributeMetrics(): Promise<Array<IProductDisplayAttributeMetric>> {
        const query: string = encodeURI(`${this.apiUrl}${this.availableMetricsBaseUrl}`);
        if (this._availableProductDisplayAttributeMetrics){
            return this._availableProductDisplayAttributeMetrics;
        }
        this._availableProductDisplayAttributeMetrics = await lastValueFrom(this._http.get<Array<IProductDisplayAttributeMetric>>(query));
        return this._availableProductDisplayAttributeMetrics;
    }
}
