import { IStyleParametersModel } from '../../features/workbench/models/style-parameters.model';
import { IWorkbenchParameterModel } from '../../features/workbench/models/workbench-parameter.model';
import { IApplicationMenuItemModel } from '../../shared/models/application-menu-item.model';
import { IDisplayMetric } from '../models/display-metric.model';
import { IStoreState } from './store.model';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
    actionStoreApplyFilters,
    actionStoreClearAllAnalytics,
    actionStoreClearAllWorkbench,
    actionStoreClearWorkbench,
    actionStoreSetAnalyticsFilterData,
    actionStoreSetAttributeDisplayMetrics,
    actionStoreSetAttributeTableSort,
    actionStoreSetColumnOrder,
    actionStoreSetExclusionsSaved,
    actionStoreSetFilterSaved,
    actionStoreSetSavedBoards,
    actionStoreSetStylingDisplayMetrics,
    actionStoreSetStylings,
    actionStoreSetStylingSort,
    actionStoreSetStylingSortDirection,
    actionStoreSetStylingSortOrder,
    actionStoreSetWorkbenchDisplayMetrics,
    actionStoreSetWorkbenchSort,
    actionStoreSetWorkbenchSortDirection,
    actionStoreSetWorkbenchSortOrder,
    actionStoreUpdateStyleParameters,
    actionStoreUpdateWorkbenchParameters,
    actionStoreUserDetails,
    actionStoreWorkbenchFilterData,
    actionStoreWorkbenchSearchTerm
} from './store.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { IBoard } from '../models/board.model';
import { IFilterData } from '../models/filter-data.model';
import { ISavedBoard } from '../models/saved-board.model';
import { IProductDisplayAttributeMetric } from '../models/product-display-attribute-metric.model';
import { IUserRole } from '../models/user-roles.model';

export const initialState: IStoreState = {
    userDetails: undefined,
    boardData: {
        user_id: '',
        board_id: 0,
        board_name: '',
        board_type: ''
    },
    filtersSaved: true,
    filterData: {
        time_period: '',
        // time_period: '',
        time_range: [],
        time_year: [],
        time_quarter: [],
        time_month: [],
        sort_order: [],
        display_metrics: [],
        department: [],
        sub_department: [],
        category: [],
        product_type: [],
        meta_data: {},
        location_company: [],
        location_division: [],
        location_area: [],
        location_region: [],
        location_store: [],
        metric_range: [],
        exclusions: []
    },
    styleParameters: {
        allocation_algorithm: 'ML Alloc',
        aws_opt: '',
        like_products: ['None'],
        substitute_products: [],
        alternative_time_period_start_date: '',
        alternative_time_period_end_date: '',
        applied_flex: 0,
        apply_push_out: false,
        ideal_cover: 8
    },
    workbenchParameters: {
        flexPerc: 10,
        allocationsAlgorithm: 'test',
        woc: 12,
        timePeriodEnd: '2023-04-20',
        timePeriodStart: '2023-03-20'
    },
    workbenchFilterData: {
        time_period: '',
        time_range: [],
        time_year: [],
        time_quarter: [],
        time_month: [],
        sort_order: '',
        sort_direction: '',
        display_metrics: [],
        styling_display_metrics: [],
        department: [],
        sub_department: [],
        category: [],
        product_type: [],
        meta_data: {},
        location_company: [],
        location_division: [],
        location_area: [],
        location_region: [],
        location_store: [],
        metric_range: [],
        exclusions: [],
        display_attributes: []
    },
    patternBoards: [],
    savedBoards: null,
    exclusionsSaved: false,
    attributeSort: {
        sortOrder: 'sls_u_ty',
        sortDirection: 'desc'
    },
    workbenchSizes: [
        {
            ordered_size: 'size_one',
            display_name: 'One'
        },
        {
            ordered_size: 'size_two',
            display_name: 'Two'
        },
        {
            ordered_size: 'size_three',
            display_name: 'Three'
        },
        {
            ordered_size: 'size_four',
            display_name: 'Four'
        },
        {
            ordered_size: 'size_five',
            display_name: 'Five'
        },
        {
            ordered_size: 'size_six',
            display_name: 'Six'
        },
        {
            ordered_size: 'size_seven',
            display_name: 'Seven'
        },
        {
            ordered_size: 'size_eight',
            display_name: 'One'
        },
        {
            ordered_size: 'size_nine',
            display_name: 'Nine'
        },
        {
            ordered_size: 'size_ten',
            display_name: 'Ten'
        },
        {
            ordered_size: 'size_eleven',
            display_name: 'Eleven'
        },
        {
            ordered_size: 'size_twelve',
            display_name: 'Twelve'
        },
        {
            ordered_size: 'size_thirteen',
            display_name: 'Thirteen'
        }
    ],
    // activeApplication: {
    //     name: 'analytics',
    //     label: 'Analytics',
    //     icon: 'icon-pie-chart',
    //     order: 1,
    //     id: 1
    // },
    workbenchSearchTerm: '',
    stylings: [],
    workbenchSort: { sortOrder: 'style_pnn', sortDirection: 'desc' },
    stylingSort: { sortOrder: 'store_name', sortDirection: 'desc' },
    attributeDisplayMetrics: []
};

export type StoreUserDetailsAction = {
    userDetails: { email: string; roles: Array<IUserRole> } | undefined;
} & TypedAction<'[Store] Set User Details'> & {
        type: '[Store] Set User Details';
    };
export type StoreApplyFiltersAction = {
    boardData: IBoard;
    filterData: IFilterData;
} & TypedAction<'[Store] Apply Filters'> & {
        type: '[Store] Apply Filters';
    };
export type StoreSetSavedBoardsAction = { savedBoards: ISavedBoard } & TypedAction<'[Store] Set Saved Boards'> & {
        type: '[Store] Set Saved Boards';
    };
export type StoreSetFilterSavedAction = { filtersSaved: boolean } & TypedAction<'[Store] Set Filters Saved'> & {
        type: '[Store] Set Filters Saved';
    };

export type StoreSetWorkbenchSortOrder = { sortOrder: string } & TypedAction<'[Store] Set Workbench Sort Order'> & {
        type: '[Store] Set Workbench Sort Order';
    };
export type StoreSetStylingSortOrder = { sortOrder: string } & TypedAction<'[Store] Set Styling Sort Order'> & {
        type: '[Store] Set Styling Sort Order';
    };

export type StoreSetWorkbenchFilterData = { filterData: IFilterData } & TypedAction<'[Store] Set Workbench Filter Data'> & {
        type: '[Store] Set Workbench Filter Data';
    };
export type StoreSetAnalyticsFilterData = { filterData: IFilterData } & TypedAction<'[Store] Set Analytics Filter Data'> & {
        type: '[Store] Set Analytics Filter Data';
    };
export type StoreWorkbenchSearchTerm = { searchTerm: string } & TypedAction<'[Store] Set Workbench Search Term'> & {
        type: '[Store] Set Workbench Search Term';
    };
export type StoreSetStylings = { stylings: Array<string> } & TypedAction<'[Store] Set Stylings'> & {
        type: '[Store] Set Stylings';
    };
export type StoreSetWorkbenchSort = {
    sortOrder: string;
    sortDirection: 'asc' | 'desc';
} & TypedAction<'[Store] Set Workbench Sort'> & {
        type: '[Store] Set Workbench Sort';
    };

export type StoreSetAttributeTableSort = {
    sortOrder: string;
    sortDirection: 'asc' | 'desc';
} & TypedAction<'[Store] Set Attribute Table Sort'> & {
        type: '[Store] Set Attribute Table Sort';
    };

export type StoreSetStylingSort = {
    sortOrder: string;
    sortDirection: 'asc' | 'desc';
} & TypedAction<'[Store] Set Styling Sort'> & {
        type: '[Store] Set Styling Sort';
    };
export type StoreSetWorkbenchSortDirection = {
    sortDirection: 'asc' | 'desc';
} & TypedAction<'[Store] Set Workbench Sort Direction'> & {
        type: '[Store] Set Workbench Sort Direction';
    };

export type StoreSetStylingSortDirection = {
    sortDirection: 'asc' | 'desc';
} & TypedAction<'[Store] Set Styling Sort Direction'> & {
        type: '[Store] Set Styling Sort Direction';
    };
export type StoreSetWorkbenchDisplayMetrics = {
    displayMetrics: Array<IDisplayMetric>;
} & TypedAction<'[Store] Set Workbench Display Metrics'> & {
        type: '[Store] Set Workbench Display Metrics';
    };

export type StoreSetStylingDisplayMetrics = {
    displayMetrics: Array<IDisplayMetric>;
} & TypedAction<'[Store] Set Styling Display Metrics'> & {
        type: '[Store] Set Styling Display Metrics';
    };

export type StoreSetAttributeDisplayMetrics = {
    displayMetrics: Array<IProductDisplayAttributeMetric>;
} & TypedAction<'[Store] Set Attribute Display Metrics'> & {
        type: '[Store] Set Attribute Display Metrics';
    };

export type StoreSetExclusionsSavedAction = { exclusionsSaved: boolean } & TypedAction<'[Store] Set Exclusions Saved'> & {
        type: '[Store] Set Exclusions Saved';
    };
export type StoreSetColumnOrder = {
    orderedColumns: Array<IProductDisplayAttributeMetric>;
} & TypedAction<'[Store] Set Column Order'> & {
        type: '[Store] Set Column Order';
    };

export type StoreUpdateStyleParameters = {
    parameters: IStyleParametersModel;
} & TypedAction<'[Store] Update Style Parameters'> & {
        type: '[Store] Update Style Parameters';
    };

export type StoreUpdateWorkbenchParameters = {
    parameters: IWorkbenchParameterModel;
} & TypedAction<'[Store] Update Workbench Parameters'> & {
        type: '[Store] Update Workbench Parameters';
    };

// export type StoreSetWorkbenchMetaDataFilter = {
//     name: string;
//     value: string;
// } & TypedAction<'[Store] Set Workbench Meta Data Filter'> & {
//         type: '[Store] Set Workbench Meta Data Filter';
//     };

export type StoreSetActiveApplication = {
    activeApplication: IApplicationMenuItemModel;
} & TypedAction<'[Store] Set Active Application'> & {
        type: '[Store] Set Active Application';
    };
export type StoreActions =
    | StoreUserDetailsAction
    | StoreApplyFiltersAction
    | StoreSetSavedBoardsAction
    | StoreSetFilterSavedAction
    | StoreSetExclusionsSavedAction
    | StoreSetColumnOrder;
const reducer: ActionReducer<IStoreState, Action> = createReducer(
    initialState,
    on(actionStoreSetColumnOrder, (state: IStoreState, action: StoreSetColumnOrder) => ({ ...state, ...action })),
    on(actionStoreUserDetails, (state: IStoreState, action: StoreUserDetailsAction) => ({ ...state, ...action })),
    on(actionStoreApplyFilters, (state: IStoreState, action: StoreApplyFiltersAction) => ({ ...state, ...action })),
    on(actionStoreSetSavedBoards, (state: IStoreState, action: StoreSetSavedBoardsAction) => ({ ...state, ...action })),
    on(actionStoreSetFilterSaved, (state: IStoreState, action: StoreSetFilterSavedAction) => ({ ...state, ...action })),
    on(actionStoreSetAttributeTableSort, (state: IStoreState, action: StoreSetAttributeTableSort) => ({
        ...state,
        attributeSort: {
            sortDirection: action.sortDirection,
            sortOrder: action.sortOrder
        }
    })),
    on(actionStoreSetExclusionsSaved, (state: IStoreState, action: StoreSetExclusionsSavedAction) => ({
        ...state,
        ...action
    })),
    on(actionStoreSetWorkbenchSortOrder, (state: IStoreState, action: StoreSetWorkbenchSortOrder) => ({
        ...state,
        workbenchSort: {
            ...state.workbenchSort,
            sortOrder: action.sortOrder
        }
    })),
    on(actionStoreSetStylingSortOrder, (state: IStoreState, action: StoreSetStylingSortOrder) => ({
        ...state,
        stylingSort: {
            ...state.workbenchSort,
            sortOrder: action.sortOrder
        }
    })),
    on(actionStoreSetWorkbenchSort, (state: IStoreState, action: StoreSetWorkbenchSort) => ({
        ...state,
        workbenchSort: {
            sortDirection: action.sortDirection,
            sortOrder: action.sortOrder
        }
    })),
    on(actionStoreSetStylingSort, (state: IStoreState, action: StoreSetStylingSort) => ({
        ...state,
        stylingSort: {
            sortDirection: action.sortDirection,
            sortOrder: action.sortOrder
        }
    })),
    on(actionStoreSetWorkbenchSortDirection, (state: IStoreState, action: StoreSetWorkbenchSortDirection) => ({
        ...state,
        workbenchSort: {
            ...state.workbenchSort,
            sortDirection: action.sortDirection
        }
    })),
    on(actionStoreSetStylingSortDirection, (state: IStoreState, action: StoreSetStylingSortDirection) => ({
        ...state,
        stylingSort: {
            ...state.workbenchSort,
            sortDirection: action.sortDirection
        }
    })),
    on(actionStoreSetWorkbenchDisplayMetrics, (state: IStoreState, action: StoreSetWorkbenchDisplayMetrics) => ({
        ...state,
        workbenchFilterData: {
            ...state.workbenchFilterData,
            display_metrics: action.displayMetrics
        }
    })),

    on(actionStoreSetAttributeDisplayMetrics, (state: IStoreState, action: StoreSetAttributeDisplayMetrics) => ({
        ...state,
        attributeDisplayMetrics: action.displayMetrics
    })),
    on(actionStoreSetStylingDisplayMetrics, (state: IStoreState, action: StoreSetStylingDisplayMetrics) => ({
        ...state,
        workbenchFilterData: {
            ...state.workbenchFilterData,
            styling_display_metrics: action.displayMetrics
        }
    })),

    on(actionStoreUpdateStyleParameters, (state: IStoreState, action: StoreUpdateStyleParameters) => ({
        ...state,
        styleParameters: action.parameters
    })),

    on(actionStoreUpdateWorkbenchParameters, (state: IStoreState, action: StoreUpdateWorkbenchParameters) => ({
        ...state,
        workbenchParameters: action.parameters
    })),
    on(actionStoreClearWorkbench, (state: IStoreState) => ({
        ...state,
        workbenchFilterData: {
            ...state.workbenchFilterData,
            display_metrics: initialState.workbenchFilterData.display_metrics,
            sort_order: initialState.workbenchFilterData.sort_order,
            sort_direction: initialState.workbenchFilterData.sort_direction
        }
    })),
    on(actionStoreClearAllWorkbench, (state: IStoreState) => ({
        ...state,
        workbenchFilterData: {
            time_period: '',
            time_range: [],
            time_year: [],
            time_quarter: [],
            time_month: [],
            sort_order: initialState.workbenchFilterData.sort_order,
            sort_direction: initialState.workbenchFilterData.sort_direction,
            display_metrics: initialState.workbenchFilterData.display_metrics,
            styling_display_metrics: initialState.workbenchFilterData.styling_display_metrics,
            department: [],
            sub_department: [],
            category: [],
            product_type: [],
            meta_data: {},
            location_company: [],
            location_division: [],
            location_area: [],
            location_region: [],
            location_store: [],
            metric_range: [],
            exclusions: [],
            display_attributes: initialState.workbenchFilterData.display_attributes
        }
    })),
    on(actionStoreClearAllAnalytics, (state: IStoreState) => ({
        ...state,
        filterData: {
            time_period: 'Last 5 weeks',
            time_range: [],
            time_year: [],
            time_quarter: [],
            time_month: [],
            sort_order: initialState.filterData.sort_order,
            sort_direction: initialState.filterData.sort_direction,
            display_metrics: initialState.filterData.display_metrics,
            styling_display_metrics: initialState.filterData.styling_display_metrics,
            department: [],
            sub_department: [],
            category: [],
            product_type: [],
            meta_data: {},
            location_company: [],
            location_division: [],
            location_area: [],
            location_region: [],
            location_store: [],
            metric_range: [],
            exclusions: [],
            display_attributes: initialState.filterData.display_attributes
        }
    })),
    on(actionStoreWorkbenchFilterData, (state: IStoreState, action: StoreSetWorkbenchFilterData) => ({
        ...state,
        workbenchFilterData: {
            ...action.filterData,
            display_metrics: state?.workbenchFilterData?.display_metrics,
            sort_order: state?.workbenchFilterData?.sort_order,
            sort_direction: state?.workbenchFilterData?.sort_direction
        }
    })),
    on(actionStoreSetAnalyticsFilterData, (state: IStoreState, action: StoreSetAnalyticsFilterData) => ({
        ...state,
        filterData: {
            ...action.filterData,
            display_metrics: state?.workbenchFilterData?.display_metrics,
            sort_order: state?.workbenchFilterData?.sort_order,
            sort_direction: state?.workbenchFilterData?.sort_direction
        }
    })),
    on(actionStoreWorkbenchSearchTerm, (state: IStoreState, action: StoreWorkbenchSearchTerm) => ({
        ...state,
        workbenchSearchTerm: action.searchTerm
    })),
    on(actionStoreSetStylings, (state: IStoreState, action: StoreSetStylings) => ({
        ...state,
        stylings: [...action.stylings]
    }))

    // on(actionStoreSetWorkbenchMetaDataFilter, (state: IStoreState, action: StoreSetWorkbenchMetaDataFilter) => ({
    //     ...state,
    //     workbenchFilterData: {
    //         ...state?.workbenchFilterData,
    //         meta_data: [...state?.workbenchFilterData.meta_data, ]
    //     }
    // }))
);

export function storeReducer(state: IStoreState | undefined, action: Action): IStoreState {
    return reducer(state, action);
}
