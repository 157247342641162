import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import { authLogout, selectIsAuthenticated } from '../../../core/core.module';
import { selectAnalyticsActiveView } from '../../../core/core.state';

import { DataService } from '../../../core/data/data.service';
import { ExcelExportService } from '../../../core/data/excel-export.service';
import { FilterStorageService } from '../../../core/data/filters.service';
import { LoadingSpinnerService } from '../../../core/data/loading-spinner.service';
import { IBoard } from '../../../core/models/board.model';
import { ICurrentBoard } from '../../../core/models/current-board.model';
import { defaultDisplayAttributes } from '../../../core/models/default-display-attributes.const';
import { DialogDisplayMode } from '../../../core/models/dialog-display-mode.enum';
import { DialogFilterTypes } from '../../../core/models/dialog-filter-type.enum';
import { IDisplayMetric } from '../../../core/models/display-metric.model';
import { ListingDisplayMode } from '../../../core/models/display-mode.enum';
import { DisplayModelLabel } from '../../../core/models/display-model-label.enum';
import { IFilterData } from '../../../core/models/filter-data.model';
import { IFilters } from '../../../core/models/filters.model';
import { IInputs } from '../../../core/models/inputs.model';
import { IItemsPerPageOptions } from '../../../core/models/items-per-page-options.model';
import { ILastUpdate } from '../../../core/models/last-update.model';
import { IMetricRange } from '../../../core/models/metric-range.model';
import { IPaginationLabels } from '../../../core/models/pagination-labels.model';
import { IProductAttributeParameters } from '../../../core/models/product-attribute-parameters.model';
import { IProductAttributeRow } from '../../../core/models/product-attribute-row.model';
import { IProductDisplayAttributeMetric } from '../../../core/models/product-display-attribute-metric.model';
import { IProductDisplayAttribute } from '../../../core/models/product-display-attribute.model';
import { IProduct } from '../../../core/models/product.model';
import { ActivatedRoute, Router } from '@angular/router';

import {
    actionStoreApplyFilters, actionStoreSetAnalyticsFilterData,
    actionStoreSetAttributeDisplayMetrics,
    actionStoreSetFilterSaved,
    actionStoreSetSavedBoards
} from '../../../core/store/store.actions';

import { IState } from '../../../core/store/store.model';
import {
    selectAttributeDisplayMetrics,
    selectBoardData,
    selectFilterData,
    selectFiltersSaved,
    selectSavedBoards,
    selectUserDetails
} from '../../../core/store/store.selectors';
import { DefaultFiltersService } from '../../../shared/services/default-filters.service';
import { StyleCommentService } from '../../../shared/services/style-comment.service';
import { ItemsPerPageService } from '../../dashboard/dashboard/dashboard.itemsperpage.service';
import { DialogBoardSaveComponent } from '../../dialogs/dialog-board-save.component';

import { DialogFilterSelectComponent } from '../../dialogs/dialog-filter-select.component';
import { DialogProductViewComponent } from '../../dialogs/dialog-product-view.component';
import { AttributeService } from '../../../shared/services/attribute.service';
import { GalleryExportComponent } from '../components/gallery-export/gallery-export.component';
import { DialogBoardEditComponent } from '../../dialogs/dialog-board-edit.component';

// const defaultTimePeriod: string = '';
type FilterTypes = string | IMetricRange;

@Component({
    selector: 'lynkd-pattern-listing',
    templateUrl: './listing.component.html',
    styleUrls: ['./listing.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('0.25s ease-out', style({ height: 300, opacity: 1 }))
            ]),
            transition(':leave', [style({ height: 300, opacity: 1 }), animate('0.05s ease-in', style({ height: 0, opacity: 0 }))])
        ])
    ]
})
export class ListingComponent implements OnInit, OnDestroy {
    @Input()
    public sideNavOpenState: boolean;
    public isAuthenticated$: Observable<boolean>;
    public filtersApplied: boolean;
    public filtersSaved: boolean;
    public filterData: IFilterData = {
        display_attributes: defaultDisplayAttributes
    };

    public toolbarOpenState: boolean = true;
    public showDisplayMetrics: boolean = false;
    public sortToggleAscending: boolean = false;
    public sort_direction: string = '';
    public gallery: ListingDisplayMode = ListingDisplayMode.Gallery;
    // eslint-disable-next-line @typescript-eslint/typedef
    public ListingDisplayMode = ListingDisplayMode;
    // eslint-disable-next-line @typescript-eslint/typedef
    public DialogDisplayMode = DialogDisplayMode;
    // eslint-disable-next-line @typescript-eslint/typedef
    public DialogFilterTypes = DialogFilterTypes;

    public products: Array<IProduct>;
    public productsCache: Array<IProduct> = [];
    public productsFiltered: Array<IProduct>;
    public productsLoading: boolean = false;
    public productsSearched: boolean = false;
    public environment: {
        test: boolean;
        production: boolean;
        envName: string;
        version: string;
        listingPagination: boolean;
    } = environment;
    public tableCache: Array<IProduct> = [];
    public loadCache: boolean = false;
    public lastUpdated: string = '';
    public lastUpdatedCache: string = '';
    public hovered: string = '';
    public exclusions: Array<string> = [];

    public galleryFilterData: Array<IDisplayMetric>;
    public gridColumns: Array<string>;
    public filterDefaults: Array<string>;
    public displayDefaults: Array<string>;
    public firstLoad: boolean = true;
    public sort_counter: number = 1;
    public default_metrics: Array<IDisplayMetric>;
    public metricAlignment: boolean = false;

    public pageSize: number = 8000;
    public currentPage: number = 1;
    public itemsPerPage: number = 24;
    public itemWidth: number;
    public itemHeight: number;

    public isDownloadingExportData: boolean;
    public labels: IPaginationLabels = {
        previousLabel: '',
        nextLabel: ''
    };
    public inputs: IInputs = {
        sort_order: undefined,
        sort_order_name: undefined as string | Array<IDisplayMetric> | IDisplayMetric | Array<string>,
        display_metrics: [],
        search: '',
        display_attributes: defaultDisplayAttributes
    };
    public filters: IFilters = {
        sort_order: [],
        display_metrics: [],
        display_attributes: defaultDisplayAttributes,
        display_attribute_metrics: []
    };
    public currentBoard: ICurrentBoard = {
        user_id: '',
        board_id: 0,
        board_name: '',
        time_ranges: undefined as string,
        time_periods: [],
        time_years: [],
        time_quarters: [],
        time_months: [],
        location_companies: [],
        location_divisions: [],
        location_areas: [],
        location_regions: [],
        location_stores: [],
        fields: '',
        departments: [],
        sub_departments: [],
        categories: [],
        product_types: [],
        meta_datas: {},
        metric_ranges: [],
        display_metrics: [],
        type: '',
        exclusions: [],
        display_attributes: defaultDisplayAttributes,
        display_attribute_metrics: []
    };
    public attributeParameters: IProductAttributeParameters = {
        attribute_column: '',
        display_attributes: defaultDisplayAttributes
        // time_period: ''
    };

    public attributeDisplayMetrics: Array<IProductDisplayAttributeMetric>;

    private readonly _subscriptions: Subscription = new Subscription();

    public get listingFilterData(): IFilterData {
        return {
            sort_order: this.currentBoard.sort_order,
            metric_range: this.currentBoard.metric_ranges,
            meta_data: this.currentBoard.meta_datas,
            category: this.currentBoard.categories,
            sub_department: this.currentBoard.sub_departments,
            department: this.currentBoard.departments,
            location_store: this.currentBoard.location_stores,
            location_region: this.currentBoard.location_regions,
            location_area: this.currentBoard.location_areas,
            location_division: this.currentBoard.location_divisions,
            location_company: this.currentBoard.location_companies,
            time_month: this.currentBoard.time_months,
            product_type: this.currentBoard.product_types,
            time_quarter: this.currentBoard.time_quarters,
            time_year: this.currentBoard.time_years,
            time_range: this.currentBoard.time_ranges,
            time_period: this.currentBoard.time_periods,
            exclusions: this.currentBoard.exclusions,
            display_metrics: this.currentBoard.display_metrics,
            styling_display_metrics: this.currentBoard.styling_display_metrics,
            display_attributes: this.currentBoard.display_attributes,
            display_attribute_metrics: this.currentBoard.display_attribute_metrics
        } as IFilterData;
    }

    private defaultDisplayMetrics: Array<IProductDisplayAttributeMetric>;

    public constructor(

        private readonly _store: Store<IState>,
        public dialog: MatDialog,
        public readonly dataService: DataService,
        private readonly _excelExportService: ExcelExportService,
        private readonly _loadingSpinner: LoadingSpinnerService,
        private readonly _itemsPerPageService: ItemsPerPageService,
        private readonly _filterStorageService: FilterStorageService,
        private readonly _attributeService: AttributeService,
        private readonly _dom: DomSanitizer,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _defaultFilters: DefaultFiltersService,
        private readonly _commentService: StyleCommentService
    ) {}


    public deepEqual(
        objectOne: Record<string, Array<string>> | Array<string>,
        objectTwo: Record<string, Array<string>> | Array<string>
    ): boolean {
        const keysOne: Array<string> = Object.keys(objectOne);
        const keysTwo: Array<string> = Object.keys(objectTwo);

        if (keysOne.length !== keysTwo.length) {
            return false;
        }

        for (const key of keysOne) {
            const valuesOne: Array<string> = objectOne[key];
            const valuesTwo: Array<string> = objectTwo[key];
            const areObjects: boolean = this.isObject(valuesOne) && this.isObject(valuesTwo);
            if ((areObjects && !this.deepEqual(valuesOne, valuesTwo)) || (!areObjects && valuesOne !== valuesTwo)) {
                return false;
            }
        }

        return true;
    }

    public isObject(object: Array<string>): boolean {
        return object !== null && typeof object === 'object';
    }

    public async ngOnInit(): Promise<void> {
        this.isAuthenticated$ = this._store.select(selectIsAuthenticated);

        await this.init();

        this._subscriptions.add(
            this._store.select(selectAnalyticsActiveView).subscribe(async (result: ListingDisplayMode) => {
                const oldView: ListingDisplayMode = this.gallery;
                this.gallery = result;
                if (oldView === result) {
                    return;
                }
                await this.galleryChange(true);
            })
        );
        // this._route.params.subscribe(async (params: Params) => {
        //     const view: DisplayModelLabel = params.view;
        //     const oldView: ListingDisplayMode = this.gallery;
        //     switch (view) {
        //         case DisplayModelLabel.Gallery:
        //             this.gallery = ListingDisplayMode.Gallery;
        //             break;
        //         case DisplayModelLabel.Table:
        //             this.gallery = ListingDisplayMode.Table;
        //             break;
        //         case DisplayModelLabel.Attributes:
        //             this.gallery = ListingDisplayMode.Attributes;
        //             break;
        //     }
        //     if (this.gallery === oldView) {
        //         return;
        //     }
        //     this._store.dispatch(actionStoreSetGallery({ gallery: this.gallery }));
        // });
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public drop(event: CdkDragDrop<IProduct, IProduct>): void {
        moveItemInArray(this.gridColumns, event.previousIndex, event.currentIndex);
    }

    // removeExclusions(arrayOne, arrayTwo) {
    //   const result = arrayOne.filter(function(objOne) {
    //     return !arrayTwo.some(function(objTwo) {
    //         return objOne.style_id === objTwo.style_id;
    //     });
    // });
    //   return result;
    // }
    public setDisplayMetricsData(): void {
        if (this.gallery === ListingDisplayMode.Table) {
            const displayMetrics: Array<IDisplayMetric> = [];

            this.galleryFilterData = this.inputs && this.inputs.display_metrics ? this.inputs.display_metrics.slice() : [];
            // If table mode, get saved display filters or save default filters
            const savedFilters: Array<string> = this._filterStorageService.getSortFilters();

            if (savedFilters && savedFilters.length) {
                savedFilters.map((item: string) => {
                    this.filters.display_metrics.forEach((option: IDisplayMetric) => {
                        if (
                            option.metric_name.replace(/_/g, ' ').toLowerCase().trim() ===
                            item.replace(/_/g, ' ').toLowerCase().trim()
                        ) {
                            displayMetrics.push(option);
                        }
                    });
                });
            } else {
                this.displayDefaults.forEach((item: string) => {
                    this.filters.display_metrics.map((option: IDisplayMetric) => {
                        if (
                            option.metric_name.replace(/_/g, ' ').toLowerCase().trim() ===
                            item.replace(/_/g, ' ').toLowerCase().trim()
                        ) {
                            displayMetrics.push(option);
                        }
                    });
                });
            }

            this.inputs.display_metrics = displayMetrics;
        } else if (this.gallery === ListingDisplayMode.Gallery) {
            if (this.galleryFilterData) {
                this.inputs.display_metrics = this.galleryFilterData.slice();
            } else {
                this.galleryFilterData = this.inputs.display_metrics.slice();
            }
        }
    }

    public async galleryChange(clearFilters: boolean = false): Promise<void> {
        if (clearFilters && this.currentBoard.board_name.length !== 0) {
            await this.resetFilters();
        } else {
            if (this.gallery === ListingDisplayMode.Attributes) {
                this.filterData.time_period = await this.calculateTimePeriodFilter();
            }
            this.setDisplayMetricsData();

            await this.applyProductsFilters();
        }
    }

    public async logout(): Promise<void> {
        try {
            await Auth.signOut();
            this._store.dispatch(authLogout());
        } catch (e) {
            // Todo: Error handling on signout
        }
    }

    public checkActiveMetricRanges(): boolean {
        return this.currentBoard.metric_ranges.some((item: IMetricRange) => item.active === true);
    }

    public sortToggle(): boolean {
        this.sortToggleAscending = !this.sortToggleAscending;
        this.sort_direction = this.sortToggleAscending ? 'asc' : 'desc';

        this._store.dispatch(
            actionStoreApplyFilters({
                boardData: {
                    user_id: this.currentBoard.user_id,
                    board_id: this.currentBoard.board_id,
                    board_name: this.currentBoard.board_name
                },
                filterData: {
                    time_period: this.filterData.time_period,
                    time_range: this.filterData.time_range,
                    time_year: this.filterData.time_year,
                    time_quarter: this.filterData.time_quarter,
                    time_month: this.filterData.time_month,
                    location_company: this.filterData.location_company,
                    location_division: this.filterData.location_division,
                    location_area: this.filterData.location_area,
                    location_region: this.filterData.location_region,
                    location_store: this.filterData.location_store,
                    sort_order: this.inputs.sort_order.length ? `${this.inputs.sort_order}:${this.sort_direction}` : '',
                    display_metrics: this.inputs.display_metrics,
                    department: this.filterData.department,
                    sub_department: this.filterData.sub_department,
                    category: this.filterData.category,
                    product_type: this.filterData.product_type,
                    meta_data: this.filterData.meta_data,
                    metric_range: this.filterData.metric_range,
                    exclusions: this.currentBoard.exclusions,
                    display_attributes: this.inputs.display_attributes
                }
            })
        );

        return false;
    }

    public searchFilter(): void {
        this.searchProducts();
        // this.searchAttributes();
    }

    public async getProducts(
        ids: string,
        time_period: string | Array<string>,
        time_range: string | Array<string>,
        time_year: Array<string>,
        time_quarter: Array<string>,
        time_month: Array<string>,
        department: Array<string>,
        sub_department: Array<string>,
        category: Array<string>,
        product_type: Array<string>,
        metric_range: Array<IMetricRange>,
        fieldsSet: string,
        sortOrder: string | Array<string>,
        pageSize: number,
        meta: Record<string, Array<string>>,
        locationCompany: Array<string>,
        locationDivision: Array<string>,
        locationArea: Array<string>,
        locationRegion: Array<string>,
        locationStore: Array<string>
    ): Promise<void> {
        if (this.gallery === ListingDisplayMode.Attributes) {
            return;
        }
        this.productsLoading = true;
        this.lastUpdated = '';
        this.products = [];

        if (!this.loadCache) {
            await this.loadProducts(
                time_period,
                time_year,
                time_quarter,
                time_month,
                ids,
                time_range,
                department,
                sub_department,
                category,
                product_type,
                metric_range,
                fieldsSet,
                sortOrder,
                pageSize,
                meta,
                locationCompany,
                locationDivision,
                locationArea,
                locationRegion,
                locationStore
            );
            this.searchFilter();
        } else {
            this._loadingSpinner.spin$.next(true);

            if (this.gallery === ListingDisplayMode.Gallery) {
                this.products = this.productsCache;
            } else {
                this.products = this.tableCache;
            }
            this.productsFiltered = this.products.slice();

            this.lastUpdated = this.lastUpdatedCache;
            this.loadCache = false;

            this.searchFilter();

            this._loadingSpinner.spin$.next(false);
        }
    }

    public async getProductSortOrders(): Promise<void> {
        this.filters.sort_order = await firstValueFrom(this.dataService.getProductSortOrders());
    }

    public async getProductMetrics(): Promise<void> {
        this.filters.display_metrics = await firstValueFrom(this.dataService.getProductMetrics());
    }

    public selectFilter(
        items: Array<IProductDisplayAttribute> | Array<IDisplayMetric>,
        selectedItem: Array<IProductDisplayAttribute> | Array<IDisplayMetric>,
        input: DialogFilterTypes,
        subInput: string,
        gallery: ListingDisplayMode,
        display_mode: DialogDisplayMode,
        listing_display_mode: string
    ): boolean {
        this.sort_counter++;
        const alignment: boolean = this.metricAlignment;

        const dialogRef: MatDialogRef<
            DialogFilterSelectComponent,
            {
                selectedItem: Array<IDisplayMetric> | Array<IProductDisplayAttribute>;
                input: DialogFilterTypes;
                subInput: string;
            }
        > = this.dialog.open(DialogFilterSelectComponent, {
            data: {
                filter: input,
                subFilter: subInput ? subInput : null,
                items,
                selectedItem,
                gallery,
                display_mode,
                listing_display_mode,
                allignment: alignment,
                selectedDisplayAttributes: this.inputs.display_attributes,
                selectedDisplayAttributeMetrics: this.attributeDisplayMetrics
            }
        });

        dialogRef
            .afterClosed()
            .subscribe(
                async (result: {
                    selectedItem: Array<IDisplayMetric> | Array<IProductDisplayAttribute>;
                    input: DialogFilterTypes;
                    subInput: string;
                }) => {
                    if (result) {
                        const localInput: DialogFilterTypes = result.input;
                        const localSubInput: string = result.subInput;
                        if (
                            localInput !== DialogFilterTypes.DisplayAttributes &&
                            localInput !== DialogFilterTypes.DisplayAttributeMetrics
                        ) {
                            const localSelectedItems: Array<IDisplayMetric> = result.selectedItem as Array<IDisplayMetric>;
                            if (localSubInput) {
                                this.inputs[localInput][localSubInput] = localSelectedItems;
                            } else {
                                this.inputs[localInput] =
                                    localInput === DialogFilterTypes.SortOrder
                                        ? localSelectedItems[0].metric_name
                                        : localSelectedItems;
                            }

                            if (localSubInput && this.inputs[localInput][localSubInput].length === 0) {
                                delete this.inputs[localInput][localSubInput];
                            }

                            if (
                                (!localSubInput && this.inputs[localInput] !== selectedItem) ||
                                (localSubInput && this.inputs[localInput][localSubInput] !== selectedItem)
                            ) {
                                if (this.gallery === ListingDisplayMode.Gallery) {
                                    this.filtersSaved = false;
                                } else if (this.gallery === ListingDisplayMode.Table) {
                                    this.filtersSaved = true;
                                    this._filterStorageService.saveSortFilters(
                                        localSelectedItems.map((filter: IDisplayMetric) => filter.metric_name)
                                    );
                                }

                                this._store.dispatch(
                                    actionStoreSetFilterSaved({
                                        filtersSaved: this.filtersSaved
                                    })
                                );

                                await this.applyProductsFilters();
                            }
                        } else if (localInput === DialogFilterTypes.DisplayAttributes) {
                            this.inputs.display_attributes = result.selectedItem as Array<IProductDisplayAttribute>;
                            this.filterData.display_attributes = result.selectedItem as Array<IProductDisplayAttribute>;
                            await this.applyProductsFilters();
                        } else if (localInput === DialogFilterTypes.DisplayAttributeMetrics) {
                            this._store.dispatch(
                                actionStoreSetAttributeDisplayMetrics({
                                    displayMetrics: result.selectedItem as Array<IProductDisplayAttributeMetric>
                                })
                            );
                        }
                    }

                    const overlay: HTMLDivElement = document.querySelector('.cdk-overlay-backdrop');
                    if (overlay) {
                        overlay.click();
                    }
                }
            );

        return false;
    }

    public async applyProductsFilters(): Promise<boolean> {
        this.filterData.display_attributes =
            !this.filterData.display_attributes || this.filterData.display_attributes.length === 0
                ? defaultDisplayAttributes
                : this.filterData.display_attributes;

        if (this.gallery === ListingDisplayMode.Attributes) {
            this.filterData.time_period = await this.calculateTimePeriodFilter();
        }
        const attributeParameters: IProductAttributeParameters = {
            attribute_column: '',
            pageSize: this.pageSize,
            ...this.filterData
        } as IProductAttributeParameters;
        this.inputs.display_attributes = this.filterData.display_attributes;
        this.attributeParameters = attributeParameters;
        this.showDisplayMetrics = !!this.inputs.display_metrics;

        this.sort_direction = this.sortToggleAscending ? 'asc' : 'desc';
        this._store.dispatch(
            actionStoreApplyFilters({
                boardData: {
                    user_id: this.currentBoard.user_id,
                    board_id: this.currentBoard.board_id,
                    board_name: this.currentBoard.board_name
                },
                filterData: {
                    time_period: this.filterData.time_period,
                    time_range: this.filterData.time_range,
                    time_year: this.filterData.time_year,
                    time_quarter: this.filterData.time_quarter,
                    time_month: this.filterData.time_month,
                    location_company: this.filterData.location_company,
                    location_division: this.filterData.location_division,
                    location_area: this.filterData.location_area,
                    location_region: this.filterData.location_region,
                    location_store: this.filterData.location_store,
                    sort_order: this.inputs.sort_order.length ? `${this.inputs.sort_order}:${this.sort_direction}` : '',
                    display_metrics: this.inputs.display_metrics,
                    department: this.filterData.department,
                    sub_department: this.filterData.sub_department,
                    category: this.filterData.category,
                    product_type: this.filterData.product_type,
                    meta_data: this.filterData.meta_data,
                    metric_range: this.filterData.metric_range,
                    exclusions: this.currentBoard.exclusions,
                    display_attributes: this.filterData.display_attributes
                }
            })
        );

        return false;
    }

    public async clearSortAndDisplay(): Promise<boolean> {
        this.inputs.search = '';
        this.productsSearched = false;
        this.sortToggleAscending = false;
        this.inputs.sort_order = [];
        this.showDisplayMetrics = false;
        if (this.gallery === ListingDisplayMode.Table) {
            this.inputs.display_metrics = this.default_metrics;
            // clear session storage filters
            this._filterStorageService.saveSortFilters([]);
        } else if (this.gallery === ListingDisplayMode.Gallery) {
            this.inputs.display_metrics = [];
        } else if (this.gallery === ListingDisplayMode.Attributes) {
            this.inputs.display_attributes = defaultDisplayAttributes;
            this.filterData.time_period = await this.calculateTimePeriodFilter();
        }
        // this.inputs.display_metrics = [];
        this._store.dispatch(
            actionStoreApplyFilters({
                boardData: {
                    user_id: this.currentBoard.user_id,
                    board_id: this.currentBoard.board_id,
                    board_name: this.currentBoard.board_name
                },
                filterData: {
                    time_period: this.filterData.time_period,
                    time_range: this.filterData.time_range,
                    time_year: this.filterData.time_year,
                    time_quarter: this.filterData.time_quarter,
                    time_month: this.filterData.time_month,
                    location_company: this.filterData.location_company,
                    location_division: this.filterData.location_division,
                    location_area: this.filterData.location_area,
                    location_region: this.filterData.location_region,
                    location_store: this.filterData.location_store,
                    sort_order: this.inputs.sort_order,
                    display_metrics: this.inputs.display_metrics,
                    department: this.filterData.department,
                    sub_department: this.filterData.sub_department,
                    category: this.filterData.category,
                    product_type: this.filterData.product_type,
                    meta_data: this.filterData.meta_data,
                    metric_range: this.filterData.metric_range,
                    exclusions: this.currentBoard.exclusions,
                    display_attributes: this.inputs.display_attributes
                }
            })
        );

        return false;
    }

    public clearFilters(): void {
        // Clear Sort + Display filter from Navbar
        this.inputs.search = '';
        this.exclusions = [];
        this.productsSearched = false;
        this.sortToggleAscending = false;
        this.inputs.sort_order = [];
        this.showDisplayMetrics = false;
        if (this.gallery === ListingDisplayMode.Table) {
            this.inputs.display_metrics = [];

            // TODO remove commented lines
            // this.inputs.display_metrics = this.default_metrics;
            // clear session storage filters
            // this._filterStorageService.saveSortFilters([]);
        } else if (this.gallery === ListingDisplayMode.Gallery) {
            this.inputs.display_metrics = [];
        } else if (this.gallery === ListingDisplayMode.Attributes) {
            this.inputs.display_attributes = [];
            // TODO remove commented lines
            // this.inputs.display_attributes = defaultDisplayAttributes;
            // this.filterData.time_period = await this.calculateTimePeriodFilter();
        }

        this.showDisplayMetrics = false;
        this.currentPage = 1;
        // if (this.gallery) {
        //   this.loadCache = true;
        // }
        this.loadCache = true;
        this.sortToggleAscending = false;
        this.inputs.search = '';
        this.exclusions = [];
        this._store.dispatch(
            actionStoreApplyFilters({
                boardData: {
                    user_id: '',
                    board_id: 0,
                    board_name: ''
                },
                filterData: {
                    time_period: '',
                    time_range: [],
                    time_year: [],
                    time_quarter: [],
                    time_month: [],
                    location_company: [],
                    location_division: [],
                    location_area: [],
                    location_region: [],
                    location_store: [],
                    sort_order: '',
                    display_metrics: this.inputs.display_metrics,
                    department: [],
                    sub_department: [],
                    category: [],
                    product_type: [],
                    meta_data: {},
                    metric_range: [],
                    exclusions: [],
                    display_attributes: this.inputs.display_attributes
                }
            })
        );
    }

    public async resetFilters(): Promise<void> {
        this.clearFilters();
        await this._defaultFilters.hydrateAnalyticsDefaults();
    }

    public arrayRemove(arr: Array<FilterTypes>, value: FilterTypes): Array<FilterTypes> {
        return arr.filter((ele: FilterTypes) => ele !== value);
    }

    public excludeMouseover(product_id: string): void {
        this.hovered = product_id;
    }

    public exclude(product: IProduct): void {
        (this.filterData.exclusions as Array<IProduct>).push(product);

        this._store.dispatch(
            actionStoreApplyFilters({
                boardData: {
                    user_id: this.currentBoard.user_id,
                    board_id: this.currentBoard.board_id,
                    board_name: this.currentBoard.board_name
                },
                filterData: {
                    time_period: this.filterData.time_period,
                    time_range: this.filterData.time_range,
                    time_year: this.filterData.time_year,
                    time_quarter: this.filterData.time_quarter,
                    time_month: this.filterData.time_month,
                    location_company: this.filterData.location_company,
                    location_division: this.filterData.location_division,
                    location_area: this.filterData.location_area,
                    location_region: this.filterData.location_region,
                    location_store: this.filterData.location_store,
                    sort_order: this.inputs.sort_order.length ? `${this.inputs.sort_order}:${this.sort_direction}` : '',
                    display_metrics: this.inputs.display_metrics,
                    department: this.filterData.department,
                    sub_department: this.filterData.sub_department,
                    category: this.filterData.category,
                    product_type: this.filterData.product_type,
                    meta_data: this.filterData.meta_data,
                    metric_range: this.filterData.metric_range,
                    exclusions: this.filterData.exclusions,
                    display_attributes: this.inputs.display_attributes
                }
            })
        );
    }

    public onExclusionRemove(product: IProduct): void {
        if (this.currentBoard.exclusions.some((prod: IProduct) => (prod.style_id = product.style_id))) {
            const index: number = this.currentBoard.exclusions.indexOf(product);
            if (index > -1) {
                this.currentBoard.exclusions.splice(index, 1);
            }
            this._store.dispatch(
                actionStoreApplyFilters({
                    boardData: {
                        user_id: this.currentBoard.user_id,
                        board_id: this.currentBoard.board_id,
                        board_name: this.currentBoard.board_name
                    },
                    filterData: {
                        time_period: this.filterData.time_period,
                        time_range: this.filterData.time_range,
                        time_year: this.filterData.time_year,
                        time_quarter: this.filterData.time_quarter,
                        time_month: this.filterData.time_month,
                        location_company: this.filterData.location_company,
                        location_division: this.filterData.location_division,
                        location_area: this.filterData.location_area,
                        location_region: this.filterData.location_region,
                        location_store: this.filterData.location_store,
                        sort_order: this.inputs.sort_order.length ? `${this.inputs.sort_order}:${this.sort_direction}` : '',
                        display_metrics: this.inputs.display_metrics,
                        department: this.filterData.department,
                        sub_department: this.filterData.sub_department,
                        category: this.filterData.category,
                        product_type: this.filterData.product_type,
                        meta_data: this.filterData.meta_data,
                        metric_range: this.filterData.metric_range,
                        exclusions: this.currentBoard.exclusions,
                        display_attributes: this.inputs.display_attributes
                    }
                })
            );
        }
        this.products.unshift(product);
    }

    public onMainExclusionRemove(): void {
        this.currentBoard.exclusions.reverse().forEach((ex: IProduct) => {
            this.products.unshift(ex);
        });
        this.currentBoard.exclusions = [];
        this._store.dispatch(
            actionStoreApplyFilters({
                boardData: {
                    user_id: this.currentBoard.user_id,
                    board_id: this.currentBoard.board_id,
                    board_name: this.currentBoard.board_name
                },
                filterData: {
                    time_period: this.filterData.time_period,
                    time_range: this.filterData.time_range,
                    time_year: this.filterData.time_year,
                    time_quarter: this.filterData.time_quarter,
                    time_month: this.filterData.time_month,
                    location_company: this.filterData.location_company,
                    location_division: this.filterData.location_division,
                    location_area: this.filterData.location_area,
                    location_region: this.filterData.location_region,
                    location_store: this.filterData.location_store,
                    sort_order: this.inputs.sort_order.length ? `${this.inputs.sort_order}:${this.sort_direction}` : '',
                    display_metrics: this.inputs.display_metrics,
                    department: this.filterData.department,
                    sub_department: this.filterData.sub_department,
                    category: this.filterData.category,
                    product_type: this.filterData.product_type,
                    meta_data: this.filterData.meta_data,
                    metric_range: this.filterData.metric_range,
                    exclusions: [],
                    display_attributes: this.inputs.display_attributes
                }
            })
        );
    }

    public async onMainFilterRemoved(filter: 'location' | 'time' | keyof IFilterData, filterItem?: string): Promise<void> {
        if (filter === 'location') {
            this.filterData.location_company = [];
            this.filterData.location_division = [];
            this.filterData.location_area = [];
            this.filterData.location_region = [];
            this.filterData.location_store = [];
        } else if (filter === 'time') {
            this.filterData.time_period = '';
            this.filterData.time_year = [];
            this.filterData.time_quarter = [];
            this.filterData.time_month = [];
        } else if (filter === 'meta_data') {
            this.filterData.meta_data[filterItem] = [];
        } else if (filter !== 'sort_direction' && filter !== 'time_period') {
            this.filterData[filter] = [];
        }

        this.filtersSaved = false;

        this._store.dispatch(
            actionStoreSetFilterSaved({
                filtersSaved: this.filtersSaved
            })
        );

        await this.applyProductsFilters();
    }

    public async onFilterRemoved(
        filter: keyof IFilterData,
        filterItem: string | IMetricRange,
        subFilter?: string
    ): Promise<void> {
        let index: number;
        if (filter === 'meta_data') {
            index = this.filterData.meta_data[subFilter].indexOf(filterItem as string);
            if (this.filterData.meta_data[subFilter]) {
                this.filterData.meta_data[subFilter] = this.arrayRemove(
                    this.filterData.meta_data[subFilter],
                    this.filterData.meta_data[subFilter][index]
                ) as Array<string>;
            }

            if (this.filterData.meta_data[subFilter].length === 0) {
                delete this.filterData.meta_data[subFilter];
            }
        } else {
            if (filter === 'time_period') {
                this.filterData[filter] = '';
            } else if (filter === 'time_range') {
                this.filterData[filter] = [];
            } else if (
                filter !== 'exclusions' &&
                filter !== 'display_metrics' &&
                filter !== 'styling_display_metrics' &&
                filter !== 'display_attributes' &&
                filter !== 'sort_direction' &&
                filter !== 'sort_order'
            ) {
                if (filter === 'metric_range') {
                    index = this.filterData.metric_range.indexOf(filterItem as IMetricRange);
                    if (index >= 0) {
                        this.filterData[filter] = this.arrayRemove(
                            this.filterData[filter],
                            this.filterData[filter][index]
                        ) as Array<IMetricRange>;
                    }
                } else {
                    index = this.filterData[filter].indexOf(filterItem as string);
                    if (index >= 0) {
                        this.filterData[filter] = this.arrayRemove(
                            this.filterData[filter],
                            this.filterData[filter][index]
                        ) as Array<string>;
                    }
                }
            } else if (filter !== 'sort_direction' && this.filterData[filter].length === 0) {
                this.filterData[filter] = [];
            }
        }

        this.filtersSaved = false;

        this._store.dispatch(
            actionStoreSetFilterSaved({
                filtersSaved: this.filtersSaved
            })
        );

        await this.applyProductsFilters();
    }

    public clearAllFilters(): void {
        this.clearFilters();
        const filterData: IFilterData = {
            time_period: '',
            time_range: [],
            time_year: [],
            time_quarter: [],
            time_month: [],
            location_company: [],
            location_division: [],
            location_area: [],
            location_region: [],
            location_store: [],
            sort_order: '',
            display_metrics: this.inputs.display_metrics,
            department: [],
            sub_department: [],
            category: [],
            product_type: [],
            meta_data: {},
            metric_range: [],
            exclusions: [],
            display_attributes: this.inputs.display_attributes
        };
        this._store.dispatch(actionStoreSetAnalyticsFilterData({ filterData }));
    }

    public saveBoard(): void {
        const timePeriod: string = this.filterData.time_period.length ? `recent:${this.filterData.time_period}` : '';
        const timeRange: string | Array<string> = this.filterData.time_range.length ? `${this.filterData.time_range}` : [];
        const timeYear: Array<string> = this.filterData.time_year.length
            ? this.filterData.time_year.length > 1
                ? this.filterData.time_year.map((value: string) => `year:${value}`)
                : [`year:${this.filterData.time_year}`]
            : [];
        const timeQuarter: Array<string> = this.filterData.time_quarter.length
            ? this.filterData.time_quarter.length > 1
                ? this.filterData.time_quarter.map((value: string) => `quarter:${value}`)
                : [`quarter:${this.filterData.time_quarter}`]
            : [];
        const timeMonth: Array<string> = this.filterData.time_month.length
            ? this.filterData.time_month.length > 1
                ? this.filterData.time_month.map((value: string) => `month:${value}`)
                : [`month:${this.filterData.time_month}`]
            : [];
        const locationCompany: Array<string> = this.filterData.location_company.length
            ? this.filterData.location_company.length > 1
                ? this.filterData.location_company.map((value: string) => `fin_co_name:'${value}'`)
                : [`fin_co_name:'${this.filterData.location_company}'`]
            : [];
        const locationDivision: Array<string> = this.filterData.location_division.length
            ? this.filterData.location_division.length > 1
                ? this.filterData.location_division.map((value: string) => `division_descr:'${value}'`)
                : [`division_descr:'${this.filterData.location_division}'`]
            : [];
        const locationArea: Array<string> = this.filterData.location_area.length
            ? this.filterData.location_area.length > 1
                ? this.filterData.location_area.map((value: string) => `trademark_descr:'${value}'`)
                : [`trademark_descr:'${this.filterData.location_area}'`]
            : [];
        const locationRegion: Array<string> = this.filterData.location_region.length
            ? this.filterData.location_region.length > 1
                ? this.filterData.location_region.map((value: string) => `region_descr:'${value}'`)
                : [`region_descr:'${this.filterData.location_region}'`]
            : [];
        const locationStore: Array<string> = this.filterData.location_store.length
            ? this.filterData.location_store.length > 1
                ? this.filterData.location_store.map((value: string) => `store_name:'${value}'`)
                : [`store_name:'${this.filterData.location_store}'`]
            : [];
        const sortOrder: string = this.filterData.sort_order.length ? `${this.filterData.sort_order}` : '';
        if (this.galleryFilterData === undefined) {
            this.galleryFilterData = [];
        }

        let displayMetrics: string | Array<string>;

        if (this.gallery === ListingDisplayMode.Table) {
            displayMetrics =
                (this.galleryFilterData &&
                    this.galleryFilterData.map((value: { metric_name: string }) => `${value.metric_name}`).join(',')) ||
                [];
        } else {
            displayMetrics = this.inputs.display_metrics
                ? this.inputs.display_metrics.map((value: IDisplayMetric) => `${value.metric_name}`).join(',')
                : [];
        }
        const exclusions: string | Array<IProduct> = this.currentBoard.exclusions
            ? this.currentBoard.exclusions.map((value: IProduct) => `${value.style_id}`).join(',')
            : [];

        const departmentName: Array<string> = this.filterData.department.length
            ? this.filterData.department.length > 1
                ? this.filterData.department.map((value: string) => `department_name:'${value}'`)
                : [`department_name:'${this.filterData.department}'`]
            : [];

        const subDepartmentName: Array<string> = this.filterData.sub_department.length
            ? this.filterData.sub_department.length > 1
                ? this.filterData.sub_department.map((value: string) => `sub_department_name:'${value}'`)
                : [`sub_department_name:'${this.filterData.sub_department}'`]
            : [];

        const categoryName: Array<string> = this.filterData.category.length
            ? this.filterData.category.length > 1
                ? this.filterData.category.map((value: string) => `category_name:'${value}'`)
                : [`category_name:'${this.filterData.category}'`]
            : [];
        const productTypeName: Array<string> = this.filterData.product_type.length
            ? this.filterData.product_type.length > 1
                ? this.filterData.product_type.map((value: string) => `product_type_name:'${value}'`)
                : [`product_type_name:'${this.filterData.product_type}'`]
            : [];
        const metricRange: string | Array<string> = this.filterData.metric_range.length
            ? this.filterData.metric_range
                  .map((value: IMetricRange) => `${value.metric_name}:${value.values[0]}:${value.values[1]}`)
                  .join(',')
            : [];

        const time: Array<string> = [];
        if (timeYear.length > 0 || timeQuarter.length > 0 || timeMonth.length > 0) {
            if (timeYear.length > 0) {
                time.push(...timeYear);
            }
            if (timeQuarter.length > 0) {
                time.push(...timeQuarter);
            }
            if (timeMonth.length > 0) {
                time.push(...timeMonth);
            }
        } else {
            time.push(timePeriod);
        }

        const locationFilters: Array<string> = [];
        if (locationCompany.length) {
            locationCompany.map((location: string) => {
                locationFilters.push(location);
            });
        }
        if (locationDivision.length) {
            locationDivision.map((location: string) => {
                locationFilters.push(location);
            });
        }
        if (locationArea.length) {
            locationArea.map((location: string) => {
                locationFilters.push(location);
            });
        }
        if (locationRegion.length) {
            locationRegion.map((location: string) => {
                locationFilters.push(location);
            });
        }
        if (locationStore.length) {
            locationStore.map((location: string) => {
                locationFilters.push(location);
            });
        }

        const metaData: Array<string> = [];
        if (this.filterData.meta_data) {
            for (const property in this.filterData.meta_data) {
                if (this.filterData.meta_data[property]) {
                    for (const value of this.filterData.meta_data[property]) {
                        metaData.push(`${property}:'${value}'`);
                    }
                }
            }
        }
        const metaFilters: string | Array<string> = metaData ? `meta_filters=${metaData.join(',')}` : [];

        let boardType: string;

        switch (this.gallery) {
            case ListingDisplayMode.Gallery:
                boardType = 'gallery';
                break;
            case ListingDisplayMode.Table:
                boardType = 'table';
                break;
            case ListingDisplayMode.Attributes:
                boardType = 'attributes';
                break;
        }

        const displayAttributes: string | Array<string> = this.inputs.display_attributes
            ? this.inputs.display_attributes.map((value: IProductDisplayAttribute) => `${value.column_name}`).join(',')
            : [];

        const dialogConfig: MatDialogConfig = {
            width: '506px',
            data: {
                boardData: {
                    user_id: this.currentBoard.user_id,
                    board_id: this.currentBoard.board_id ? this.currentBoard.board_id : 0,
                    board_name: this.currentBoard.board_name,
                    type: this.currentBoard.type,
                    time_period: time.join(','),
                    time_range: timeRange,
                    sort_order: sortOrder,
                    display_metrics: displayMetrics,
                    display_attributes: displayAttributes,
                    department_name: departmentName,
                    sub_department_name: subDepartmentName,
                    category_name: categoryName,
                    product_type_name: productTypeName,
                    filter_time: '',
                    filter_product: '',
                    filter_location: locationFilters.join(','),
                    filter_meta: metaFilters,
                    filter_range: metricRange,
                    exclusions,
                    board_type: boardType
                },
                saveFirst: !this.filtersSaved
            }
        };

        let dialogRef: MatDialogRef<
            DialogBoardSaveComponent | DialogBoardEditComponent,
            {
                board_id: number;
                board_name: string;
            }
        >;

        if (this.currentBoard.board_id === 0) {
            dialogRef = this.dialog.open(DialogBoardSaveComponent, dialogConfig);
        } else {
            dialogRef = this.dialog.open(DialogBoardEditComponent, dialogConfig);
        }

        dialogRef.afterClosed().subscribe((result: { board_id: number; board_name: string }) => {
            if (result) {
                this.filtersSaved = true;

                this._store.dispatch(
                    actionStoreSetFilterSaved({
                        filtersSaved: this.filtersSaved
                    })
                );

                this._store.dispatch(
                    actionStoreSetSavedBoards({
                        savedBoards: result
                    })
                );

                this.currentBoard.board_id = result.board_id;
                this.currentBoard.board_name = result.board_name;
                // this.currentBoard['user_id'] = result['user_id'];
            }
        });
    }

    public async exportExcel(): Promise<void> {
        this.isDownloadingExportData = true;

        switch (this.gallery) {
            case ListingDisplayMode.Gallery:
                this.isDownloadingExportData = false;
                break;
            case ListingDisplayMode.Table:
                await this.exportTableToExcel();
                break;
            case ListingDisplayMode.Attributes:
                await this.exportAttributesToExcel();
                break;
        }
    }

    public showExportDialog(): void {
        this.dialog.open(GalleryExportComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            panelClass: 'full-screen-modal',
            data: {
                products: this.products
                    .slice(0)
                    .map((product: IProduct) => ({ ...product, imageId: product.image_id.replace('web', 'mini') })),
                filterData: this.filterData.display_metrics
            }
        });
    }

    // showMoreLink(data: any) {
    //   data = Array.isArray(data) ? data.join() : data;
    //
    //   if (data.length > 25) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
    public async clickEvent(gallery: number): Promise<boolean> {
        this.gallery = gallery;
        let view: DisplayModelLabel;
        switch (this.gallery) {
            case ListingDisplayMode.Gallery:
                view = DisplayModelLabel.Gallery;
                break;
            case ListingDisplayMode.Table:
                view = DisplayModelLabel.Table;
                break;
            case ListingDisplayMode.Attributes:
                view = DisplayModelLabel.Attributes;
                break;
        }
        return this._router.navigate(['/analytics', view]);
    }


    public viewProduct(product_id: string): void {
        this._loadingSpinner.spin$.next(true);
        const data: Array<IProduct> = this.products;

        const time: Array<string> = [];
        if (this.filterData.time_period.length) {
            time.push(`recent:'${this.filterData.time_period}'`);
        } else {
            if (this.filterData.time_year.length) {
                time.push(`year:'${this.filterData.time_year}'`);
            }
            if (this.filterData.time_quarter.length) {
                time.push(`quarter:'${this.filterData.time_quarter}'`);
            }
            if (this.filterData.time_month.length) {
                time.push(`month:'${this.filterData.time_month}'`);
            }
        }

        this._loadingSpinner.spin$.next(false);

        const dialogRef: MatDialogRef<DialogProductViewComponent, void> = this.dialog.open(DialogProductViewComponent, {
            autoFocus: false,
            width: '100%',
            panelClass: 'product-view-dialog',
            data: {
                products: data,
                product_id,
                user_id: this.currentBoard.user_id,
                time_period: time.join(','),
                time_range: this.filterData.time_range,
                location_company: this.filterData.location_company,
                location_division: this.filterData.location_division,
                location_area: this.filterData.location_area,
                location_region: this.filterData.location_region,
                location_store: this.filterData.location_store
            }
        });

        dialogRef.afterClosed().subscribe(() => {
            // if (response.direction == 'prev') {
            //     this.products.map((product, index) => {
            //         if (product.style_id === response.style_id) {
            //             this.viewProduct(
            //                 null,
            //                 index - 1 == -1 ? this.products[this.products.length - 1].style_id : this.products[index - 1].style_id
            //             );
            //         }
            //     });
            // } else if (response.direction == 'next') {
            //     this.products.map((product, index) => {
            //         if (product.style_id === response.style_id) {
            //             this.viewProduct(
            //                 null,
            //                 index + 1 >= this.products.length ? this.products[0].style_id : this.products[index + 1].style_id
            //             );
            //         }
            //     });
            // }
        });
        // }
    }

    public getSafeUrl(url: string): SafeUrl {
        return this._dom.sanitize(SecurityContext.STYLE, `url("${url}")`);
        // return  this.dom.bypassSecurityTrustUrl('url(' +url+ ')') ;
    }

    public async changePage($event: number): Promise<void> {
        this.currentPage = $event;
        const productsOnScreen: Array<IProduct> = this.products.slice((this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage);
        await this._commentService.hasComments(productsOnScreen.map((t:IProduct)=> t.style_id));
        if (!environment.listingPagination) {
            return;
        }
        await this.loadProducts(
            this.filterData.time_period,
            this.filterData.time_year,
            this.filterData.time_quarter,
            this.filterData.time_month,
            '',
            this.filterData.time_range,
            this.filterData.department,
            this.filterData.sub_department,
            this.filterData.category,
            this.filterData.product_type,
            this.filterData.metric_range,
            this.currentBoard.fields,
            this.filterData.sort_order,
            this.itemsPerPage,
            this.filterData.meta_data,
            this.filterData.location_company,
            this.filterData.location_division,
            this.filterData.location_area,
            this.filterData.location_region,
            this.filterData.location_store
        );
    }

    // onlyInLeft(left, right, compareFunction){
    // left.filter(leftValue =>
    //   !right.some(rightValue =>
    //     compareFunction(leftValue, rightValue)));
    // }

    private async loadProducts(
        time_period: string | Array<string>,
        time_year: Array<string>,
        time_quarter: Array<string>,
        time_month: Array<string>,
        ids: string,
        time_range: string | Array<string>,
        department: Array<string>,
        sub_department: Array<string>,
        category: Array<string>,
        product_type: Array<string>,
        metric_range: Array<IMetricRange>,
        fieldsSet: string,
        sortOrder: string | Array<string>,
        pageSize: number,
        meta: Record<string, Array<string>>,
        locationCompany: Array<string>,
        locationDivision: Array<string>,
        locationArea: Array<string>,
        locationRegion: Array<string>,
        locationStore: Array<string>
    ): Promise<void> {
        const time: Array<string> = [];
        if (time_period.length) {
            time.push(`recent:'${time_period}'`);
        } else {
            if (time_year.length) {
                time.push(`year:'${time_year}'`);
            }
            if (time_quarter.length) {
                time.push(`quarter:'${time_quarter}'`);
            }
            if (time_month.length) {
                time.push(`month:'${time_month}'`);
            }
        }

        // this._store.select(selectGalleryState).subscribe({
        //     next: async (state: ListingDisplayMode) => {
        if (this.gallery === ListingDisplayMode.Attributes) {
            // TODO Investigate and fix ListingDisplayMode to be set before products are fetched
            this._loadingSpinner.spin$.next(false);
            return;
        } else {
            try {
                this._loadingSpinner.spin$.next(true);

                this.products = await firstValueFrom(
                    this.dataService.getProducts(
                        ids,
                        time.join(','),
                        time_range,
                        department,
                        sub_department,
                        category,
                        product_type,
                        metric_range,
                        fieldsSet,
                        sortOrder,
                        environment.listingPagination ? pageSize : this.pageSize,
                        this.currentPage,
                        meta,
                        locationCompany,
                        locationDivision,
                        locationArea,
                        locationRegion,
                        locationStore
                    )
                );

                this._loadingSpinner.spin$.next(false);
                this.productsFiltered = this.products.slice();

                const lastUpdate: Array<ILastUpdate> = await firstValueFrom(this.dataService.getLastUpdate());

                this.lastUpdated = lastUpdate[0].last_update;

                if (!this.productsCache.length) {
                    this.productsCache = JSON.parse(JSON.stringify(this.products));
                    this.lastUpdatedCache = this.lastUpdated;
                }

                if (!this.tableCache.length && this.gallery === ListingDisplayMode.Table) {
                    this.tableCache = JSON.parse(JSON.stringify(this.products));
                    this.lastUpdatedCache = this.lastUpdated;
                }

                this.productsLoading = false;
            } catch (e) {
                this._loadingSpinner.spin$.next(false);
            }
        }
        // }
        // });
    }

    private async init(): Promise<void> {
        this.defaultDisplayMetrics = await this._attributeService.getDefaultProductDisplayAttributeMetrics();
        this._store.dispatch(actionStoreSetAttributeDisplayMetrics({ displayMetrics: this.defaultDisplayMetrics }));
        this.filterDefaults = [
            'product_type_id',
            'attr_supplier_name',
            'attr_plan_season',
            'store_weeks',
            'tot_sls_u'
            // 'tot_clr'
        ];

        // Todo: backend only works if 5 default filters are selected
        this.displayDefaults = [
            'sls_u',
            'sls_v',
            'awc',
            // 'aws',
            // 'soh_store_u',
            // 'soh_wh_u',
            'original_price',
            'current_price'
        ];

        this._subscriptions.add(
            this._store.select(selectUserDetails).subscribe((result: { email: string }) => {
                if (result) {
                    this.currentBoard.user_id = result.email;
                }
            })
        );

        this._subscriptions.add(
            this._store.select(selectSavedBoards).subscribe(() => {
                this.loadCache = false;
            })
        );

        this._subscriptions.add(
            this._itemsPerPageService.getItemsPerPage().subscribe((items: IItemsPerPageOptions) => {
                this.itemsPerPage = items.itemsPerPage;
                this.itemWidth = items.itemWidth;
                this.itemHeight = items.itemHeight;
            })
        );

        this._subscriptions.add(
            this._store.select(selectFiltersSaved).subscribe((result: boolean) => {
                this.filtersSaved = result;
            })
        );

        this._subscriptions.add(
            this._store
                .select(selectAttributeDisplayMetrics)
                .subscribe(({ display_metrics }: { display_metrics: Array<IProductDisplayAttributeMetric> }) => {
                    this.attributeDisplayMetrics = display_metrics;
                })
        );

        // this._dataService.boardType.subscribe((data: DisplayModelLabel) => {
        //     switch (data) {
        //         case DisplayModelLabel.Gallery:
        //             this.gallery = ListingDisplayMode.Gallery;
        //             break;
        //         case DisplayModelLabel.Table:
        //             this.gallery = ListingDisplayMode.Table;
        //             break;
        //         case DisplayModelLabel.Attributes:
        //             this.gallery = ListingDisplayMode.Attributes;
        //             break;
        //     }
        // });
        this.dataService.metricAlignment.subscribe((data: boolean) => {
            this.metricAlignment = data;
        });
        await this.getProductSortOrders();
        await this.getProductMetrics();

        this._subscriptions.add(
            this._store.select(selectFilterData).subscribe(async (result: IFilterData) => {
                const fields: Array<string> = ['image_id', 'style_id', 'description'];
                if (environment.listingPagination) {
                    fields.push('results_count');
                }
                this.filterData = JSON.parse(JSON.stringify(result));
                if (this.firstLoad === true && this.gallery === ListingDisplayMode.Table) {
                    this.default_metrics = this.inputs.display_metrics;
                    this.firstLoad = false;
                }

                if (this.gallery === ListingDisplayMode.Attributes) {
                    this.filterData.time_period = await this.calculateTimePeriodFilter();
                }
                this.filtersApplied =
                    JSON.stringify(this.currentBoard.time_periods) !== JSON.stringify(this.filterData.time_period) ||
                    JSON.stringify(this.currentBoard.time_ranges) !== JSON.stringify(this.filterData.time_range) ||
                    JSON.stringify(this.currentBoard.time_years) !== JSON.stringify(this.filterData.time_year) ||
                    JSON.stringify(this.currentBoard.time_quarters) !== JSON.stringify(this.filterData.time_quarter) ||
                    JSON.stringify(this.currentBoard.time_months) !== JSON.stringify(this.filterData.time_month) ||
                    JSON.stringify(this.currentBoard.departments) !== JSON.stringify(this.filterData.department) ||
                    JSON.stringify(this.currentBoard.sub_departments) !== JSON.stringify(this.filterData.sub_department) ||
                    JSON.stringify(this.currentBoard.categories) !== JSON.stringify(this.filterData.category) ||
                    JSON.stringify(this.currentBoard.product_types) !== JSON.stringify(this.filterData.product_type) ||
                    !this.deepEqual(this.currentBoard.meta_datas, this.filterData.meta_data) ||
                    JSON.stringify(this.currentBoard.location_companies) !== JSON.stringify(this.filterData.location_company) ||
                    JSON.stringify(this.currentBoard.location_divisions) !== JSON.stringify(this.filterData.location_division) ||
                    JSON.stringify(this.currentBoard.location_areas) !== JSON.stringify(this.filterData.location_area) ||
                    JSON.stringify(this.currentBoard.location_regions) !== JSON.stringify(this.filterData.location_region) ||
                    JSON.stringify(this.currentBoard.location_stores) !== JSON.stringify(this.filterData.location_store) ||
                    JSON.stringify(this.currentBoard.metric_ranges) !== JSON.stringify(this.filterData.metric_range) ||
                    JSON.stringify(this.currentBoard.display_attributes) !== JSON.stringify(this.filterData.display_attributes);
                this.filterData.sort_order = this.filterData.sort_order.length ? this.filterData.sort_order : 'sls_u:desc';

                this.filterData.time_period =
                    this.filterData.time_period.length ||
                    this.filterData.time_year.length ||
                    this.filterData.time_quarter.length ||
                    this.filterData.time_month.length
                        ? this.filterData.time_period
                        : '';
                this.currentBoard.time_periods = this.filterData.time_period;
                this.currentBoard.time_ranges = this.filterData.time_range;
                this.currentBoard.time_years = this.filterData.time_year;
                this.currentBoard.time_quarters = this.filterData.time_quarter;
                this.currentBoard.time_months = this.filterData.time_month;
                this.currentBoard.location_companies = this.filterData.location_company;
                this.currentBoard.location_divisions = this.filterData.location_division;
                this.currentBoard.location_areas = this.filterData.location_area;
                this.currentBoard.location_regions = this.filterData.location_region;
                this.currentBoard.location_stores = this.filterData.location_store;
                this.currentBoard.departments = this.filterData.department;
                this.currentBoard.sub_departments = this.filterData.sub_department;
                this.currentBoard.categories = this.filterData.category;
                this.currentBoard.product_types = this.filterData.product_type;
                this.currentBoard.meta_datas = this.filterData.meta_data;
                this.currentBoard.metric_ranges = this.filterData.metric_range;
                this.currentBoard.display_attributes = this.filterData.display_attributes;
                this.currentBoard.exclusions = this.filterData.exclusions as Array<IProduct>;

                if (this.filterData.sort_order.length && this.sort_counter === 1) {
                    this.sort_counter++;
                    const sortOrder: string = this.filterData.sort_order as string;
                    this.inputs.sort_order = sortOrder.split(':')[0];
                    this.sort_direction = sortOrder.split(':')[1];
                    this.sortToggleAscending = this.sort_direction === 'asc';
                    this.inputs.sort_order_name = ['Sales U'];
                } else {
                    const sortOrder: string = this.filterData.sort_order as string;
                    this.inputs.sort_order = sortOrder.split(':')[0];
                    this.sort_direction = sortOrder.split(':')[1];
                    this.sortToggleAscending = this.sort_direction === 'asc';
                    this.inputs.sort_order_name = this.filters.sort_order.filter(
                        (obj: IDisplayMetric) => obj.metric_name === this.inputs.sort_order
                    );
                    if (this.inputs.sort_order_name?.length) {
                        this.inputs.sort_order_name = this.inputs.sort_order_name[0].metric_acronym;
                    }
                }

                if (this.filterData && this.filterData.display_metrics && this.filterData.display_metrics.length) {
                    const displayMetrics: Array<IDisplayMetric> = [];
                    this.filterData.display_metrics.map((item: IDisplayMetric) => {
                        this.filters.display_metrics.map((option: IDisplayMetric) => {
                            if (
                                option.metric_name.replace(/_/g, ' ').toLowerCase().trim() ===
                                item.metric_name.replace(/_/g, ' ').toLowerCase().trim()
                            ) {
                                displayMetrics.push(option);
                            }
                        });
                    });

                    this.filterData.display_metrics = displayMetrics;
                }

                this.inputs.display_metrics = this.filterData.display_metrics;

                this.showDisplayMetrics =
                    this.inputs && this.inputs.display_metrics ? !!this.inputs.display_metrics.length : false;
                if (typeof this.filterData.exclusions === 'string') {
                    const arr: Array<string> = this.filterData.exclusions.toString().split(',');
                    this.currentBoard.exclusions = this.products.filter((product: IProduct) =>
                        arr.some((ex: string) => product.style_id === ex)
                    );
                }

                this.currentBoard.fields =
                    this.filterData && this.filterData.display_metrics
                        ? fields
                              .concat(this.filterData.display_metrics.map((prop: IDisplayMetric) => prop.metric_name))
                              .filter((value: string, index: number, self: Array<string>) => self.indexOf(value) === index)
                              .join(',')
                        : '';
                const displayFields: Array<string> =
                    this.filterData && this.filterData.display_metrics
                        ? this.filterData.display_metrics.map((f: IDisplayMetric) => f.metric_name)
                        : [];

                this.gridColumns = [
                    ...new Set<string>(
                        this.currentBoard.fields.split(',').concat(this.filterDefaults).concat(this.displayDefaults)
                    )
                ];
                if (this.gallery === ListingDisplayMode.Table) {
                    this.currentBoard.fields = this.inputs.display_metrics
                        ? [...new Set(this.currentBoard.fields.split(',').concat(this.filterDefaults))].join(',')
                        : '';
                    // const tableFields: string = this.inputs.display_metrics
                    //     ? [...new Set(this.currentBoard.fields.split(',').concat(this.filterDefaults))].join(',')
                    //     : '';
                    this.gridColumns = Array.from(
                        new Set(
                            [
                                'action',
                                'image_id',
                                'style_id',
                                'description',
                                'product_type_id',
                                'attr_supplier_name',
                                'attr_plan_season',
                                // 'original_price',
                                // 'current_price',
                                'store_weeks',
                                // 'awc',
                                // 'aws',
                                // 'sls_u',
                                // 'sls_v',
                                'tot_sls_u',
                                'tot_clr'
                                // 'soh_store_u',
                                // 'soh_wh_u'
                            ].concat(displayFields)
                        )
                    );
                    const newColumns: Array<string> = this.currentBoard.fields.split(',');
                    const newColumnsArray: Array<string> = newColumns.filter((x: string) => !this.gridColumns.includes(x));
                    this.gridColumns = this.gridColumns.concat(newColumnsArray);
                    if (this.filterData.display_metrics?.length < 1) {
                        const displayMetrics: Array<IDisplayMetric> = [];
                        this.gridColumns.map((item: string) => {
                            this.filters.display_metrics.map((option: IDisplayMetric) => {
                                if (
                                    option.metric_name.replace(/_/g, ' ').toLowerCase().trim() ===
                                    item.replace(/_/g, ' ').toLowerCase().trim()
                                ) {
                                    displayMetrics.push(option);
                                }
                            });
                        });

                        this.filterData.display_metrics = displayMetrics;
                        this.inputs.display_metrics = displayMetrics;
                    }
                }
                this.currentPage = 1;
                await this.getProducts(
                    '',
                    this.filterData.time_period,
                    this.filterData.time_range,
                    this.filterData.time_year,
                    this.filterData.time_quarter,
                    this.filterData.time_month,
                    this.filterData.department,
                    this.filterData.sub_department,
                    this.filterData.category,
                    this.filterData.product_type,
                    this.filterData.metric_range,
                    this.currentBoard.fields,
                    this.filterData.sort_order,
                    this.itemsPerPage,
                    this.filterData.meta_data,
                    this.filterData.location_company,
                    this.filterData.location_division,
                    this.filterData.location_area,
                    this.filterData.location_region,
                    this.filterData.location_store
                );
                const productsOnScreen: Array<IProduct> = this.products.slice(0, this.itemsPerPage);
                await this._commentService.hasComments(productsOnScreen.map((t:IProduct)=> t.style_id));
                const attributeParameters: IProductAttributeParameters = {
                    attribute_column: '',
                    pageSize: this.pageSize,
                    ...this.filterData
                } as IProductAttributeParameters;
                attributeParameters.display_attributes =
                    !attributeParameters.display_attributes || attributeParameters.display_attributes.length === 0
                        ? defaultDisplayAttributes
                        : attributeParameters.display_attributes;
                this.attributeParameters = attributeParameters;
                // await this.getAttributes(
                //     '',
                //     this.filterData.time_period,
                //     this.filterData.time_range,
                //     this.filterData.time_year,
                //     this.filterData.time_quarter,
                //     this.filterData.time_month,
                //     this.filterData.department,
                //     this.filterData.sub_department,
                //     this.filterData.category,
                //     this.filterData.product_type,
                //     this.filterData.metric_range,
                //     this.currentBoard.fields,
                //     this.filterData.sort_order,
                //     this.pageSize,
                //     this.filterData.meta_data,
                //     this.filterData.location_company,
                //     this.filterData.location_division,
                //     this.filterData.location_area,
                //     this.filterData.location_region,
                //     this.filterData.location_store
                // );
            })
        );

        this._subscriptions.add(
            this._store.select(selectBoardData).subscribe((result: IBoard) => {
                this.currentBoard.board_id = result.board_id;
                this.currentBoard.board_name = result.board_name;
                this.currentBoard.type = result.user_id;
            })
        );

        // this.subscriptions.add(
        //   this.store.select(selectExclusionsSaved).subscribe((result: object) => {
        //     this.exclusions = result;
        //     console.log(this.exclusions);
        //   })
        // );

        // this._route.params.subscribe(async (params: Params) => {
        //     console.log('route param triggered');
        //     const view: DisplayModelLabel = params.view;
        //     switch (view) {
        //         case DisplayModelLabel.Gallery:
        //             this.gallery = ListingDisplayMode.Gallery;
        //             break;
        //         case DisplayModelLabel.Table:
        //             this.gallery = ListingDisplayMode.Table;
        //             break;
        //         case DisplayModelLabel.Attributes:
        //             this.gallery = ListingDisplayMode.Attributes;
        //             break;
        //     }
        //     await this.galleryChange();
        // });
    }

    private hasTimeFilter(): boolean {
        return (
            (this.filterData.time_month && this.filterData.time_month.length !== 0) ||
            (this.filterData.time_quarter && this.filterData.time_quarter.length !== 0) ||
            (this.filterData.time_year && this.filterData.time_year.length !== 0) ||
            (this.filterData.time_range && this.filterData.time_range.length !== 0) ||
            (this.filterData.time_period && this.filterData.time_period.length !== 0)
        );
    }

    private async calculateTimePeriodFilter(): Promise<string> {
        if (this.hasTimeFilter()) {
            return this.filterData.time_period;
        } else {
            const defaults: IFilterData = await this._defaultFilters.getAnalyticsDefaults();
            return defaults?.time_period;
        }
    }

    private async exportAttributesToExcel(): Promise<void> {
        try {
            let rows: Array<
                IProductAttributeRow & {
                    attribute_name: string;
                } & {
                    image_id?: string;
                    image?: string | ArrayBuffer | null;
                }
            > = [];
            const attributeNames: Array<IProductDisplayAttribute> = this.currentBoard
                .display_attributes as Array<IProductDisplayAttribute>;
            for (const attributeName of attributeNames) {
                const attributeRows: Array<IProductAttributeRow> = await firstValueFrom(
                    this.dataService
                        .getProductAttributes({
                            pageSize: this.pageSize,
                            ...this.filterData,
                            attribute_column: attributeName.column_name
                        })
                        .pipe(catchError(() => of([])))
                );
                rows = rows.concat(
                    attributeRows.map((t: IProductAttributeRow) => ({
                        attribute_name: attributeName.column_name,
                        ...t
                    }))
                );
            }
            await this._excelExportService.exportToExcel(
                {
                    data: rows,
                    fileName: `attributes`,
                    header: [
                        {
                            header: 'Attribute',
                            key: 'attribute_value'
                        },
                        ...this.attributeDisplayMetrics.map((t: IProductDisplayAttributeMetric) => ({
                            header: t.display_name,
                            key: t.column_name
                        }))
                    ]
                },
                false
            );
            this.isDownloadingExportData = false;
        } catch (e) {
            this.isDownloadingExportData = false;
        }
    }

    private async exportTableToExcel(): Promise<void> {
        try {
            await this._excelExportService.exportToExcel(
                {
                    data: this.products.slice().map((item: IProduct) => {
                        /* eslint-disable @typescript-eslint/naming-convention */
                        const {
                            image_id,
                            ...newItem
                        }: IProduct & {
                            image_id?: string;
                            image?: string | ArrayBuffer | null;
                        } = { ...item };
                        (newItem as IProduct).image_id = image_id;
                        return newItem;
                    }),
                    fileName: `products`,
                    header: [
                        ...this.gridColumns
                            .filter((t: string) => t !== 'action')
                            .map((t: string) => {
                                const words: Array<string> = t.replace('attr', '').replace(/_/g, ' ').trim().split(' ');
                                for (let i: number = 0; i < words.length; i++) {
                                    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
                                }
                                const convertedWords: string = words.join(' ');
                                return { header: convertedWords, key: t };
                            })
                    ]
                },
                true
            );

            this.isDownloadingExportData = false;
        } catch (e) {
            this.isDownloadingExportData = false;
        }
    }

    private searchProducts(): void {
        this.productsSearched = true;
        this.currentPage = 1;
        this.products = this.productsFiltered?.filter(
            (item: { style_id: string; image_id: string; description: string }) =>
                item.style_id.toLowerCase().includes(this.inputs.search.toLowerCase()) ||
                item.description.toLowerCase().includes(this.inputs.search.toLowerCase())
        );
        let newArray: Array<IProduct>;
        if (this.currentBoard.exclusions.length > 0) {
            newArray = this.products.filter(
                (product: { style_id: string; image_id: string; description: string }) =>
                    !this.currentBoard.exclusions
                        .map((s: { style_id: string; image_id: string; description: string }) => s.style_id)
                        .includes(product.style_id)
            );
            this.products = newArray;
        }
    }

    // private searchAttributes(): void {
    //     this.attributesSearched = true;
    //     this.currentPage = 1;
    //     this.attributes = this.attributesFiltered.filter((item: IProductAttribute) => {
    //         return (
    //             item.style_id.toLowerCase().includes(this.inputs.search.toLowerCase()) ||
    //             item.description.toLowerCase().includes(this.inputs.search.toLowerCase())
    //         );
    //     });
    //     let newArray;
    //     if (this.currentBoard.exclusions.length > 0) {
    //         newArray = this.attributes.filter(
    //             (attribute: IProductAttribute) =>
    //                 !this.currentBoard.exclusions.map((s: IProduct) => s.style_id).includes(attribute.style_id)
    //         );
    //         this.attributes = newArray;
    //     }
    // }
}
