<div class="row no-gutters attribute-row" *ngIf="attributeRows$ | async as attributeRows; else loading">
    <div class="col-12 attribute-table-column" [class.col-12]="sideNavOpenState">
        <lynkd-pattern-attribute-table
            [attribute]="attribute.display_name"
            [attributeColumn]="attribute.column_name"
            [attributeRows]="attributeRows"
        ></lynkd-pattern-attribute-table>
    </div>
    <!--    <div class="col-3" *ngIf="!sideNavOpenState">-->
    <!--        <lynkd-pattern-attribute-graph-list [attribute]="attribute.display_name"-->
    <!--                                            [attributeRows]="attributeRows"-->
    <!--                                            [graph]="graph"></lynkd-pattern-attribute-graph-list>-->
    <!--    </div>-->
</div>
<ng-template #loading>
    <div class="row attribute-row">
        <div class="col-sm-1 mx-auto">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</ng-template>
