import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, CreateEffectMetadata, ofType } from '@ngrx/effects';
import { tap, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { selectAnalyticsActiveView, selectStoreState } from '../core.state';
import {
    actionStoreUserDetails,
    actionStoreApplyFilters,
    actionStoreSetSavedBoards,
    actionStoreSetFilterSaved
} from './store.actions';
import {
    selectUserDetails,
    selectFilterData,
    selectBoardData,
    selectSavedBoards,
    selectFiltersSaved
} from './store.selectors';
import { IState, IStoreState } from './store.model';
import { Observable } from 'rxjs';
import { StoreActions } from './store.reducer';
import { IFilterData } from '../models/filter-data.model';
import { IBoard } from '../models/board.model';
import { ListingDisplayMode } from '../models/display-mode.enum';
import { ISavedBoard } from '../models/saved-board.model';

@Injectable({ providedIn: 'root' })
export class StoreEffects {
    public persistStore: Observable<[StoreActions, IStoreState]> & CreateEffectMetadata = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actionStoreUserDetails, actionStoreApplyFilters, actionStoreSetSavedBoards, actionStoreSetFilterSaved),
                withLatestFrom(this._store.select(selectStoreState))
                /* tap(([action, _store]) =>
          this.localStorageService.setItem(STORE_KEY, _store)
        ) */
            ),
        { dispatch: false }
    );

    public setUserDetails: Observable<{ email: string }> & CreateEffectMetadata = createEffect(
        () =>
            this._store.pipe(
                select(selectUserDetails),
                distinctUntilChanged(),
                tap(() => '')
            ),
        { dispatch: false }
    );

    public setFilterData: Observable<IFilterData> & CreateEffectMetadata = createEffect(
        () =>
            this._store.pipe(
                select(selectFilterData),
                distinctUntilChanged(),
                tap(() => '')
            ),
        { dispatch: false }
    );

    public setBoardData: Observable<IBoard> & CreateEffectMetadata = createEffect(
        () =>
            this._store.pipe(
                select(selectBoardData),
                distinctUntilChanged(),
                tap(() => '')
            ),
        { dispatch: false }
    );

    public setSavedBoards: Observable<ISavedBoard> & CreateEffectMetadata = createEffect(
        () => this._store.pipe(select(selectSavedBoards), distinctUntilChanged(), tap()),
        {
            dispatch: false
        }
    );

    public setFiltersSaved: Observable<boolean> & CreateEffectMetadata = createEffect(
        () => this._store.pipe(select(selectFiltersSaved), distinctUntilChanged(), tap()),
        {
            dispatch: false
        }
    );

    // setExclusionsSaved = createEffect(
    //   () =>
    //     this._store.pipe(
    //       select(selectExclusionsSaved),
    //       distinctUntilChanged(),
    //       tap()
    //     ),
    //   { dispatch: false }
    // );

    public setGalleryState: Observable<ListingDisplayMode> & CreateEffectMetadata = createEffect(
        () => this._store.pipe(select(selectAnalyticsActiveView), distinctUntilChanged(), tap()),
        {
            dispatch: false
        }
    );

    public constructor(private readonly _actions$: Actions,
                       private readonly _store: Store<IState>) {
    }
}
