<mat-form-field class="mb-2 w-100" [floatLabel]="'always'">
    <mat-label>Start typing here</mat-label>
    <input type="text" placeholder="Example filter" matInput [formControl]="searchControl" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of $filteredAttributes | async" [value]="option.value.column_name"
                    class="text-capitalize">
            {{ option.value.display_name }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
<mat-selection-list #attributes (selectionChange)="selectionChange($event.options)"
                    class="mb-4 selection-list">
    <div *ngIf="$filteredAttributes | async as filteredOptions; else loading">
        <mat-list-option #selectionItem *ngFor="let item of filteredOptions" ngDefaultControl
                         [value]="item.value.column_name" class="text-capitalize"
                         [selected]="attributesControl.value &&attributesControl.value?.indexOf(item.value.column_name)>=0">
            <div>
                <p matLine class="mt-3">{{ item.value.display_name }}</p>
            </div>
        </mat-list-option>
    </div>

    <ng-template #loading>
        <div class="row no-gutters m-auto loader-wrapper">
            <mat-spinner class="m-auto"></mat-spinner>
        </div>
    </ng-template>

</mat-selection-list>
<mat-checkbox
    [checked]="allSelected" (change)="selectAll($event)">Select all
</mat-checkbox>
