import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {

    private readonly appConfig: {
        appName: string;
        apiUrl: string;
        userManagementApiUrl: string;
        pictureManagerApiUrl: string;
        debug: boolean;
    } = {
        appName: environment.appName,
        apiUrl: environment.apiUrl,
        userManagementApiUrl: environment.userManagementApiUrl,
        pictureManagerApiUrl: environment.pictureManagerApiUrl,
        debug: environment.debug
    };


    public get appName(): string {
        if (!this.appConfig) {
            throw Error('Config file not loaded!');
        }
        return this.appConfig.appName;
    }

    public get apiUrl(): string {
        if (!this.appConfig) {
            throw Error('Config file not loaded!');
        }
        return this.appConfig.apiUrl;
    }

    public get pictureManagerApiUrl(): string {
        if (!this.appConfig) {
            throw Error('Config file not loaded!');
        }
        return this.appConfig.pictureManagerApiUrl;
    }

    public get userManagementApiUrl(): string {
        if (!this.appConfig) {
            throw Error('Config file not loaded!');
        }

        return this.appConfig.userManagementApiUrl;
    }

    public get debug(): boolean {
        if (!this.appConfig) {
            throw Error('Config file not loaded!');
        }

        return this.appConfig.debug;
    }
}
