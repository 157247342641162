<mat-form-field class="mb-2 w-100" [floatLabel]="'always'">
    <mat-label>Start typing here</mat-label>
    <input type="text" placeholder="Example filter" matInput [formControl]="searchControl" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of $filteredMetrics | async" [value]="option.metric_acronym"
                    class="text-capitalize">
            {{ option.metric_description }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
<mat-selection-list #metrics (selectionChange)="selectionChange($event.options)"
                    class="mb-4 min-width-30">
    <div *ngIf="$filteredMetrics | async as filteredOptions; else loading">
        <mat-list-option #selectionItem *ngFor="let item of filteredOptions" ngDefaultControl
                         [value]="item.metric_acronym.trim()" class="text-capitalize"
                         [selected]="metricsControl.value && metricsControl.value?.indexOf(item.metric_acronym.trim())>=0"
                         [disabled]="(gallery === ListingDisplayMode.Gallery || gallery === ListingDisplayMode.Table) && metricsControl.value && metricsControl.value.length > 9 && !selectionItem.selected">
            <div class="description-wrapper">
                <p matLine class="margin-top-15 mb-0">{{ item.metric_acronym | displayName }}</p>
                <p matLine
                   class="description">{{ item.metric_description | displayName }}</p>
            </div>

        </mat-list-option>
    </div>

    <ng-template #loading>
        <div class="row no-gutters m-auto loader-wrapper">
            <mat-spinner class="m-auto"></mat-spinner>
        </div>
    </ng-template>

</mat-selection-list>

<div class="alignment">
    <h5 class="margin-top-20">Alignment: </h5>
    <mat-button-toggle-group
        [(ngModel)]="metricAlignment"
        (ngModelChange)="metricAlignmentChange($event)"
        name="metricAlignment"
        aria-label="View selection"
        value="metricAlignment"
    >
        <mat-button-toggle [value]="true">Top</mat-button-toggle>
        <mat-button-toggle [value]="false">Bottom</mat-button-toggle>
    </mat-button-toggle-group>
</div>
