<mat-expansion-panel   (opened)="openPanel()" (afterExpand)="afterOpenPanel()" (click)="click()"
                     hideToggle="{{hideToggle}}" [ngClass]="{'':collapsedHeight === '48px'}"
                     [class.opened]="sideMenuOpen" [class.accordion]="collapsedHeight === '64px'"
                     >
    <mat-expansion-panel-header [collapsedHeight]="collapsedHeight" [expandedHeight]="expandedHeight">
        <mat-panel-title>
            <img src="assets/icon-{{icon}}.svg" alt="Filters" class="icon pr-3" [class.opened]="sideMenuOpen">
            <span>{{displayName}}</span>
            <button mat-icon-button
                    *ngIf="displayName.toLowerCase() === 'my boards'"
                    (click)="showBoardSelectModal($event)"
            >
                <mat-icon>open_in_new</mat-icon>
            </button>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-content></ng-content>
</mat-expansion-panel>
