<mat-button-toggle-group
    aria-label="View selection"
    id="view-selection-btn-group"
    name="users"
    [value]="activeView"
    (change)="changeView($event)"
>
    <mat-button-toggle [value]="UserManagerView.Users">Users </mat-button-toggle>
    <mat-button-toggle [value]="UserManagerView.Info">Info </mat-button-toggle>
</mat-button-toggle-group>
