import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IProductPerformance } from '../models/product-performance.model';
import { AppConfigService } from './app-config.service';
import * as moment from 'moment';
import { BehaviorSubject, defer, isObservable, Observable, of } from 'rxjs';
import { IProduct } from '../models/product.model';
import { ILastUpdate } from '../models/last-update.model';
import { IMetricRange } from '../models/metric-range.model';
import { IDisplayMetric } from '../models/display-metric.model';
import { ITimePeriod } from '../models/time-period.model';
import { IProductMetas } from '../models/product-metas.model';
import { IProductDisplayAttribute } from '../models/product-display-attribute.model';
import { first, map, mergeMap, shareReplay } from 'rxjs/operators';
import { IProductAttributeRow } from '../models/product-attribute-row.model';
import { IProductAttributeParameters } from '../models/product-attribute-parameters.model';
import { IImage } from '../models/image.model';
import { IProductKeyMetricModel } from '../models/product-key-metric.model';
import { IProductOrderModel } from '../models/product-order.model';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    public apiUrl: string = this._appConfigService.apiUrl;
    public apiLastUpdate: string = '/api/timemeta/lastUpdate';
    public apiTime: string = '/api/timemeta';
    public apiLocation: string = '/api/LocationHierarchy';
    public apiProducts: string = '/api/products';
    public apiProductAttributes: string = '/api/productattribute';
    public apiProductDisplayAttributes: string = '/api/productattributes/displayattributes';
    public apiProductImages: string = '/api/products/images';
    public apiProductMeta: string = '/api/productmeta/all';
    // apiSub = '/api/producthierarchy/subdepartment_name';
    // apiProductSortOrder = '/api/products/sortcolumns';
    public apiProductMetrics: string = '/api/products/displaymetrics';
    public apiProductOrders: string = '/api/orders';
    public apiProductPerformanceItems: string = '/api/productperformance/charts';
    // apiProductPerformance = '/api/productperformance';
    public apiProductKeyMetrics: string = '/api/productperformance/cardmetrics';
    public apiProductMetricRanges: string = '/api/productmeta/rangeminmax';
    // public boardType: Subject<DisplayModelLabel> = new Subject<DisplayModelLabel>();
    public metricAlignment: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public headers: { headers: HttpHeaders } = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json'
        })
    };

    private returnObs$: Observable<Array<IProductDisplayAttribute>>;
    private displayAttributes$: Observable<Array<IProductDisplayAttribute>>;

    public constructor(private readonly _http: HttpClient, private readonly _appConfigService: AppConfigService) {}

    // public getBoardType(data: DisplayModelLabel): void {
    //     this.boardType.next(data);
    // }

    public metricAlignmentChange(data: boolean): void {
        this.metricAlignment.next(data);
    }

    public getProductAttributes(input: IProductAttributeParameters): Observable<Array<IProductAttributeRow>> {
        let time: string;
        if (input.time_range?.length) {
            const rangeValues: Array<string> = (input.time_range as string).split(' - ');
            const startDate: string = rangeValues[0];
            const endDate: string = rangeValues[1];
            time = `start:${moment(startDate).format('DDMMYYYY')},end:${moment(endDate).format('DDMMYYYY')}`;
        } else {
            const timeFilters: Array<string> = [];
            if (input.time_period?.length) {
                timeFilters.push(`recent:'${input.time_period}'`);
            } else {
                if (input.time_year?.length) {
                    timeFilters.push(`year:'${input.time_year}'`);
                }
                if (input.time_quarter?.length) {
                    timeFilters.push(`quarter:'${input.time_quarter}'`);
                }
                if (input.time_month?.length) {
                    timeFilters.push(`month:'${input.time_month}'`);
                }
            }
            time = timeFilters.join(',');
        }

        const departmentName: Array<string> = input.department
            ? input.department.map((value: string) => `department_name:'${value}'`)
            : [];
        const subDepartmentName: Array<string> = input.sub_department
            ? input.sub_department.map((value: string) => `sub_department_name:'${value}'`)
            : [];
        const categoryName: Array<string> = input.category
            ? input.category.map((value: string) => `category_name:'${value}'`)
            : [];
        const productTypeName: Array<string> = input.product_type
            ? input.product_type.map((value: string) => `product_type_name:'${value}'`)
            : [];

        const hierarchyFilters: string = `${departmentName
            .concat(subDepartmentName)
            .concat(categoryName)
            .concat(productTypeName)
            .join(',')}`;

        const activeMetricRanges: Array<string> = input.metric_range
            ? input.metric_range.map((value: IMetricRange) => `${value.metric_name}:${value.values[0]}:${value.values[1]}`)
            : [];

        const metricRangeFilters: string = `${activeMetricRanges.join(',')}`;

        // const locationFilters: Array<string> = [];
        //
        // if (input.location_company && input.location_company.length) {
        //     input.location_company.map((location: string) => {
        //         locationFilters.push(`fin_co_name:'${location}'`);
        //     });
        // }
        // if (input.location_division && input.location_division.length) {
        //     input.location_division.map((location: string) => {
        //         locationFilters.push(`division_descr:'${location}'`);
        //     });
        // }
        // if (input.location_area && input.location_area.length) {
        //     input.location_area.map((location: string) => {
        //         locationFilters.push(`trademark_descr:'${location}'`);
        //     });
        // }
        // if (input.location_region && input.location_region.length) {
        //     input.location_region.map((location: string) => {
        //         locationFilters.push(`region_descr:'${location}'`);
        //     });
        // }
        // if (input.location_store && input.location_store.length) {
        //     input.location_store.map((location: string) => {
        //         locationFilters.push(`store_name:'${location}'`);
        //     });
        // }
        const metaData: Array<string> = [];
        if (input.meta_data) {
            for (const property in input.meta_data) {
                if (input.meta_data[property]) {
                    for (const value of input.meta_data[property]) {
                        metaData.push(`${property}:'${value}'`);
                    }
                }
            }
        }

        let params: HttpParams = new HttpParams();
        if (input.ids) {
            params = params.set('ids', input.ids);
        }

        if (hierarchyFilters) {
            params = params.set('hierarchy_filters', hierarchyFilters);
        }
        if (metricRangeFilters) {
            params = params.set('metric_range_filters', metricRangeFilters);
        }
        // if (locationFilters) {
        //     params = params.set('location_filters', locationFilters.join(','));
        // }
        if (metaData) {
            params = params.set('meta_filters', metaData.join(','));
        }
        if (time) {
            params = params.set('time_period', time);
        }
        if (input.fieldsSet) {
            params = params.set('fields', input.fieldsSet);
        }
        if (input.sortOrder) {
            params = params.set('sort_order', input.sortOrder as string);
        }
        if (input.pageSize) {
            params = params.set('page_size', input.pageSize?.toString());
        }

        return this._http.get<Array<IProductAttributeRow>>(
            `${this.apiUrl + this.apiProductAttributes}/${input.attribute_column}`,
            {
                headers: this.headers.headers,
                params
            }
        );
    }

    public getProductDisplayAttributes(): Observable<Array<IProductDisplayAttribute>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductDisplayAttributes}`);

        if (!this.displayAttributes$) {
            const displayAttributes: Observable<Array<IProductDisplayAttribute>> = this.renewAfterTimer(
                this._http.get<Array<IProductDisplayAttribute>>(query, this.headers),
                1000 * 60 * 60 * 24 * 7
            );
            this.displayAttributes$ = displayAttributes.pipe(
                map((t: Array<IProductDisplayAttribute>) =>
                    t.map((x: IProductDisplayAttribute) => {
                        const words: Array<string> = x.display_name.toLowerCase().split(' ');
                        for (let i: number = 0; i < words.length; i++) {
                            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
                        }
                        x.display_name = words.join(' ');
                        return x;
                    })
                )
            );
        }
        return this.displayAttributes$;
    }

    public getProducts(
        ids: string,
        time_period: string,
        time_range: string | Array<string>,
        department: Array<string>,
        sub_department: Array<string>,
        category: Array<string>,
        product_type: Array<string>,
        metric_range: Array<IMetricRange>,
        fieldsSet: string,
        sortOrder: string | Array<string>,
        pageSize: number,
        currentPage: number,
        meta: Record<string, Array<string>>,
        locationCompanies: Array<string>,
        locationDivisions: Array<string>,
        locationAreas: Array<string>,
        locationRegions: Array<string>,
        locationStores: Array<string>
    ): Observable<Array<IProduct>> {
        let time: string | Array<string>;
        if (time_range.length) {
            time = (time_range as string).split(' - ');
            const startDate: string = time[0];
            const endDate: string = time[1];
            time = `start:${moment(startDate).format('DDMMYYYY')},end:${moment(endDate).format('DDMMYYYY')}`;
        } else {
            time = `${time_period}`;
        }

        const departmentName: Array<string> = department ? department.map((value: string) => `department_name:'${value}'`) : [];
        const subDepartmentName: Array<string> = sub_department
            ? sub_department.map((value: string) => `sub_department_name:'${value}'`)
            : [];
        const categoryName: Array<string> = category ? category.map((value: string) => `category_name:'${value}'`) : [];
        const productTypeName: Array<string> = product_type
            ? product_type.map((value: string) => `product_type_name:'${value}'`)
            : [];

        const hierarchyFilters: string = `${departmentName
            .concat(subDepartmentName)
            .concat(categoryName)
            .concat(productTypeName)
            .join(',')}`;

        const activeMetricRanges: Array<string> = metric_range.map(
            (value: IMetricRange) => `${value.metric_name}:${value.values[0]}:${value.values[1]}`
        );

        const metricRangeFilters: string = `${activeMetricRanges.join(',')}`;

        const locationFilters: Array<string> = [];

        if (locationCompanies.length) {
            locationCompanies.map((location: string) => {
                locationFilters.push(`fin_co_name:'${location}'`);
            });
        }
        if (locationDivisions.length) {
            locationDivisions.map((location: string) => {
                locationFilters.push(`division_descr:'${location}'`);
            });
        }
        if (locationAreas.length) {
            locationAreas.map((location: string) => {
                locationFilters.push(`trademark_descr:'${location}'`);
            });
        }
        if (locationRegions.length) {
            locationRegions.map((location: string) => {
                locationFilters.push(`region_descr:'${location}'`);
            });
        }
        if (locationStores.length) {
            locationStores.map((location: string) => {
                locationFilters.push(`store_name:'${location}'`);
            });
        }

        const metaData: Array<string> = [];
        if (meta) {
            for (const property in meta) {
                if (meta[property]) {
                    for (const value of meta[property]) {
                        metaData.push(`${property}:'${value}'`);
                    }
                }
            }
        }
        // const keys = Object.keys(meta);
        // for (let i = 0; i < keys.length; ++i) {
        //     const key = keys[i];
        //     meta_data.push(meta[key].map((value: string) => `${key}:'${value}'`));
        // }
        // for (const attr in meta) {
        //     if (meta.hasOwnProperty(attr)) {
        //         meta_data.push(meta[attr].map((value: string) => `${attr}:'${value}'`));
        //     }
        // }
        const params: HttpParams = new HttpParams()
            .set('ids', ids)
            .set('hierarchy_filters', hierarchyFilters)
            .set('metric_range_filters', metricRangeFilters)
            .set('location_filters', locationFilters.join(','))
            .set('meta_filters', metaData.join(','))
            .set('time_period', time)
            .set('fields', fieldsSet)
            .set('sort_order', sortOrder as string)
            .set('page_size', pageSize)
            .set('page_number', currentPage.toString());
        // .set('page_size', pageSize.toString());

        return this._http.get<Array<IProduct>>(this.apiUrl + this.apiProducts, {
            // headers: this.headers.headers,
            params
        });
    }

    public getProductImages(product_id: string): Observable<Array<IImage>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductImages}/${product_id}`);

        return this._http.get<Array<IImage>>(query, this.headers);
    }

    public getTimePeriods(): Observable<Array<ITimePeriod>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiTime}/periods`);

        return this._http.get<Array<ITimePeriod>>(query, this.headers);
    }

    public getTimeYears(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiTime}/hierarchy/yearname`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getTimeQuarters(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiTime}/hierarchy/quartername`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getTimeMonths(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiTime}/hierarchy/monthname`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getLocationCompany(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiLocation}/fin_co_name`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getLocationDivision(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiLocation}/division_descr`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getLocationArea(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiLocation}/trademark_descr`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getLocationRegion(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiLocation}/region_descr`);
        return this._http.get<Array<string>>(query, this.headers);
    }

    public getLocationStore(): Observable<Array<string>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiLocation}/store_name`);

        return this._http.get<Array<string>>(query, this.headers);
    }

    public getProductMetas(): Observable<Array<IProductMetas>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductMeta}`);
        return (this._http.get<Array<IProductMetas>>(query, this.headers))
            .pipe(map((t: Array<IProductMetas>)=> t.filter((x: IProductMetas)=> x.attribute !== 'active_job')));
    }

    // getSub() {
    //   let query = encodeURI(`${this.apiUrl}${this.apiSub}`);
    //   console.log(query);

    //   return this._http.get(query, this.headers);
    // }

    // getProductMetaAttributes(
    //   attr: string,
    //   time_period: [],
    //   time_range: any,
    //   department: [],
    //   sub_department: [],
    //   category: [],
    //   product_type: [],
    //   meta: any,
    //   pageSize: number
    // ) {
    //   let sort_order = `&sort_order=`;
    //   let time = '';
    //   let page_size = `&page_size=${pageSize}`;
    //
    //   if (time_range.length) {
    //     time = time_range.split(' - ');
    //     let startDate = time[0];
    //     let endDate = time[1];
    //     time = `&time_range=start:${moment(startDate).format(
    //       'DDMMYYYY'
    //     )},end:${moment(endDate).format('DDMMYYYY')}`;
    //   } else {
    //     time = `&time_period=${time_period}`;
    //   }
    //
    //   let department_name = department
    //     ? department.map((value) => {
    //         return `department_name:'${value}'`;
    //       })
    //     : [];
    //   let sub_department_name = sub_department
    //     ? sub_department.map((value) => {
    //         return `sub_department_name:'${value}'`;
    //       })
    //     : [];
    //   let category_name = category
    //     ? category.map((value) => {
    //         return `category_name:'${value}'`;
    //       })
    //     : [];
    //   let product_type_name = product_type
    //     ? product_type.map((value) => {
    //         return `product_type_name:'${value}'`;
    //       })
    //     : [];
    //   let hierachy_filters = `?hierarchy_filters=${department_name
    //     .concat(sub_department_name)
    //     .concat(category_name)
    //     .concat(product_type_name)
    //     .join(',')}`;
    //
    //   let meta_data = [];
    //   for (let attr in meta) {
    //     meta[attr].map((value) => {
    //       if (value !== '') {
    //         meta_data.push(`${attr}:'${value}'`);
    //       }
    //     });
    //   }
    //   let meta_filters = meta_data ? `&meta_filters=${meta_data.join(',')}` : [];
    //
    //   let query = encodeURI(
    //     `${this.apiUrl}${this.apiProductMeta}/${attr}${hierachy_filters}${meta_filters}${time}${sort_order}${page_size}`
    //   );
    //   console.log(query);
    //
    //   return this._http.get(query, this.headers);
    // }

    public getMetricRanges(
        time_period: string | Array<string>,
        time_range: string | Array<string>,
        department: Array<string>,
        sub_department: Array<string>,
        category: Array<string>,
        product_type: Array<string>,
        meta: Record<string, Array<string>>,
        pageSize: number
    ): Observable<Array<IMetricRange>> {
        const sortOrder: string = `&sort_order=`;
        let time: string;
        const pageSizeQuery: string = `&page_size=${pageSize}`;

        if (time_range.length) {
            const timeRanges: Array<string> = (time_range as string).split(' - ');
            const startDate: string = timeRanges[0];
            const endDate: string = timeRanges[1];
            time = `&time_range=start:${moment(startDate).format('DDMMYYYY')},end:${moment(endDate).format('DDMMYYYY')}`;
        } else {
            time = `&time_period=${time_period}`;
        }

        const departmentName: Array<string> = department ? department.map((value: string) => `department_name:'${value}'`) : [];

        const subDepartmentName: Array<string> = sub_department
            ? sub_department.map((value: string) => `sub_department_name:'${value}'`)
            : [];
        const categoryName: Array<string> = category ? category.map((value: string) => `category_name:'${value}'`) : [];
        const productTypeName: Array<string> = product_type
            ? product_type.map((value: string) => `product_type_name:'${value}'`)
            : [];
        const hierachyFilters: string = `?hierarchy_filters=${departmentName
            .concat(subDepartmentName)
            .concat(categoryName)
            .concat(productTypeName)
            .join(',')}`;

        const metaData: Array<string> = [];
        if (meta) {
            for (const property in meta) {
                if (meta[property]) {
                    for (const value of meta[property]) {
                        metaData.push(`${property}:'${value}'`);
                    }
                }
            }
        }
        const metaFilters: string = metaData ? `&meta_filters=${metaData.join(',')}` : '';

        const query: string = encodeURI(
            `${this.apiUrl}${this.apiProductMetricRanges}${hierachyFilters}${metaFilters}${time}${sortOrder}${pageSizeQuery}`
        );

        return this._http.get<Array<IMetricRange>>(query, this.headers);
    }

    /* getMetricRanges() {
    let query = encodeURI(`${this.apiUrl}${this.apiProductMetricRanges}`);
    console.log(query);

    return this._http.get(query, this.headers);
  } */

    public getProductSortOrders(): Observable<Array<IDisplayMetric>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductMetrics}`);

        return this._http.get<Array<IDisplayMetric>>(query, this.headers);
    }

    // getProductSortOrders() {
    //   let query = encodeURI(`${this.apiUrl}${this.apiProductSortOrder}`);
    //   console.log(query);

    //   return this._http.get(query, this.headers);
    // }

    public getProductMetrics(): Observable<Array<IDisplayMetric>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductMetrics}`);

        return this._http.get<Array<IDisplayMetric>>(query, this.headers);
    }

    public getProductKeyMetrics(
        product_id: string,
        time_period: string | Array<string>,
        time_range: string | Array<string>,
        locationCompanies: string | Array<string>,
        locationDivisions: string | Array<string>,
        locationAreas: string | Array<string>,
        locationRegions: string | Array<string>,
        locationStores: string | Array<string>
    ): Observable<Array<IProductKeyMetricModel>> {
        let time: string | Array<string>;

        if (time_range.length) {
            time = (time_range as string).split(' - ');
            const startDate: string = time[0];
            const endDate: string = time[1];
            time = `?time_range=start:${moment(startDate).format('DDMMYYYY')},end:${moment(endDate).format('DDMMYYYY')}`;
        } else {
            time = `?time_period=${time_period}`;
        }

        const locationFilters: Array<string> = [];
        if (locationCompanies.length) {
            (locationCompanies as Array<string>).map((location: string) => {
                locationFilters.push(`fin_co_name:'${location}'`);
            });
        }
        if (locationDivisions.length) {
            (locationDivisions as Array<string>).map((location: string) => {
                locationFilters.push(`division_descr:'${location}'`);
            });
        }
        if (locationAreas.length) {
            (locationAreas as Array<string>).map((location: string) => {
                locationFilters.push(`trademark_descr:'${location}'`);
            });
        }
        if (locationRegions.length) {
            (locationRegions as Array<string>).map((location: string) => {
                locationFilters.push(`region_descr:'${location}'`);
            });
        }
        if (locationStores.length) {
            (locationStores as Array<string>).map((location: string) => {
                locationFilters.push(`store_name:'${location}'`);
            });
        }

        const query: string = encodeURI(
            `${this.apiUrl}${this.apiProductKeyMetrics}/${product_id}${time}${
                locationFilters ? `&location_filters=${locationFilters.join(',')}` : ''
            }`
        );

        return this._http.get<Array<IProductKeyMetricModel>>(query, this.headers);
    }

    // deleteComment(comment_id: string) {
    //   const query = encodeURI(`${this.apiUrl}${this.apiComments}/${comment_id}`);
    //   console.log(query);
    //
    //   return this._http.delete(query, this.headers);
    // }

    public getProductOrders(product_id: string): Observable<Array<IProductOrderModel>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductOrders}/${product_id}`);

        return this._http.get<Array<IProductOrderModel>>(query, this.headers);
    }

    public getProductPerformanceItems(): Observable<string> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiProductPerformanceItems}`);

        return this._http.get<string>(query, this.headers);
    }

    public getProductPerformance(api: string, product_id: string): Observable<Array<IProductPerformance>> {
        const query: string = encodeURI(`${this.apiUrl}${api}${product_id}`);

        return this._http.get<Array<IProductPerformance>>(query, this.headers);
    }

    public getLastUpdate(): Observable<Array<ILastUpdate>> {
        const query: string = encodeURI(`${this.apiUrl}${this.apiLastUpdate}`);

        return this._http.get<Array<{ last_update: string }>>(query, this.headers);
    }

    private createReturnObs(
        obs: Observable<Array<IProductDisplayAttribute>>,
        time: number,
        bufferReplays: number
    ): Observable<Array<IProductDisplayAttribute>> {
        return (this.returnObs$ = obs.pipe(shareReplay(bufferReplays, time)));
    }

    private renewAfterTimer(
        obs: Observable<Array<IProductDisplayAttribute>>,
        time: number,
        bufferReplays: number = 1
    ): Observable<Array<IProductDisplayAttribute>> {
        return this.createReturnObs(obs, time, bufferReplays).pipe(
            first(
                null,
                defer(() => this.createReturnObs(obs, time, bufferReplays))
            ),
            mergeMap((d: Observable<Array<IProductDisplayAttribute>> | Array<IProductDisplayAttribute>) =>
                isObservable(d) ? d : of(d)
            )
        );
    }
}
