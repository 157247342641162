<mat-button-toggle-group
    [value]="activeView"
    aria-label="View selection"
    id="view-selection-btn-group"
    name="gallery"
>
    <mat-button-toggle [value]="DisplayModelLabel.Gallery" (click)="changeView(DisplayModelLabel.Gallery)">Gallery
    </mat-button-toggle>
    <mat-button-toggle [value]="DisplayModelLabel.Table" (click)="changeView(DisplayModelLabel.Table)">Table
    </mat-button-toggle>
    <mat-button-toggle [value]="DisplayModelLabel.Attributes" (click)="changeView(DisplayModelLabel.Attributes)">
        Attributes
    </mat-button-toggle>
</mat-button-toggle-group>
