import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class AnimationsService {
    private static routeAnimationType: RouteAnimationType = "NONE";

    public constructor() {
        AnimationsService.routeAnimationType = "NONE";
    }

    public updateRouteAnimationType(
        pageAnimations: boolean,
        elementsAnimations: boolean
    ): void {
        if (pageAnimations && elementsAnimations) {
            AnimationsService.routeAnimationType =
                'ALL';
        } else {
            if (pageAnimations) {
                AnimationsService.routeAnimationType =
                    'PAGE';
            } else {
                if (elementsAnimations) {
                    AnimationsService.routeAnimationType =
                        'ELEMENTS';
                } else {
                    AnimationsService.routeAnimationType =
                        'NONE';
                }
            }
        }
    }

    public static isRouteAnimationsType(type: RouteAnimationType): boolean {
        return AnimationsService.routeAnimationType === type;
    }

}

export type RouteAnimationType = "ALL" | "PAGE" | "ELEMENTS" | "NONE";
