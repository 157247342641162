<ng-container *ngIf="activeApplication$ | async as activeApplication">
    <lynkd-pattern-navigation-bar [application]="activeApplication"></lynkd-pattern-navigation-bar>
</ng-container>

<mat-sidenav-container [class.dashboard]="isAuthenticated$ | async">
    <mat-sidenav [mode]="isLargeScreen() ? 'side' : 'over'"
                 *ngIf="isAuthenticated$ | async" class="application-menu">
        <lynkd-pattern-application-menu></lynkd-pattern-application-menu>
    </mat-sidenav>
    <mat-sidenav-content class="application-menu">
        <mat-sidenav-container [class.dashboard]="isAuthenticated$ | async">
            <mat-sidenav #sidenav [mode]="isLargeScreen() ? 'side' : 'over'" [class.opened]="sideNavOpenState"
                         disableClose
                         *ngIf="isAuthenticated$ | async">
                <lynkd-pattern-side-menu [visible]="!!filterData"
                                         [metaDataLoading]='metaDataLoading'
                                         [boards$]="patternBoards$"
                                         [filters]="filters"
                                         [filterData]="filterData"
                                         [inputs]="inputs"
                                         [metaData$]="metaData$"
                                         [savedBoards$]="savedBoards$"
                                         [sideMenuConfig$]="sideMenuConfig$"
                                         (sideMenuToggled)="sideNavToggle(sidenav, $event)"
                                         (deleteSavedBoardClicked)="deleteSavedBoard($event)"
                                         (editSavedBoardClicked)="editSavedBoard($event)"
                                         (addMetaFieldClicked)="addMetaDataField()"
                                         (deleteMetaFieldClicked)="deleteMetaDataField($event)"
                                         (metaPanelOpened)="loadMetaData()"
                                         (filterClosed)="closeFilter($event)"
                                         (panelOpened)="beforePanelOpened(sidenav)"
                                         (boardSelected)="getBoard($event.saveBoard, $event.boardId)"></lynkd-pattern-side-menu>
            </mat-sidenav>
            <mat-sidenav-content [class.opened]="sideNavOpenState === true"
                                 class="inner-content"
                                 [class.display-metrics]="filterData['display_metrics'] ? filterData['display_metrics'].length : 0">
<!--                <div class="wrapper">-->
                <div class="row body">
                    <div class="col-12">
                        <router-outlet></router-outlet>
                    </div>
                </div>
                <div class="row d-flex footer-row" *ngIf="lastUpdated">
                    <div>
                        <p class="my-auto build-version float-end">Build Version: {{version}}</p>
                    </div>
                    <div class="ml-auto">
                        <p class="my-auto last-updated">Last updated: {{ lastUpdated }}</p>
                    </div>
                </div>
            </mat-sidenav-content>

        </mat-sidenav-container>
    </mat-sidenav-content>

</mat-sidenav-container>
