import { IAuthState } from './auth.models';
import { authLogin, authLogout } from './auth.actions';
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';

export const initialState: IAuthState = {
    isAuthenticated: true
};

const reducer: ActionReducer<IAuthState, Action> = createReducer(
    initialState,
    on(authLogin, (state: IAuthState) => ({ ...state, isAuthenticated: true })),
    on(authLogout, (state: IAuthState) => ({ ...state, isAuthenticated: false }))
);

export function authReducer(state: IAuthState | undefined, action: Action): IAuthState {
    return reducer(state, action);
}
