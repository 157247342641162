<div elementScroll (scroll)="onScroll($event)" class="row product-listing grid product-table">
    <mat-table
        [dataSource]="gridSource"
        matSort
        matSortDirection="asc"
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="onDrop($event)"
    >
        <!-- <<ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <button mat-button>Edit</button>
              </mat-cell>
            </ng-container> -->

        <ng-container *ngFor="let field of gridColumns; let i = index" [matColumnDef]="field">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="text-capitalize"
                cdkDrag
                cdkDragLockAxis="x"
            >
                {{ getDisplayName(field) }}
            </th>
            <td mat-cell *matCellDef="let element">
                <img *ngIf="field === 'image_id'" [src]="element[field]" alt="" />

                <ng-container *ngIf="field.endsWith('action')">
                    <button class="exclude-btn" (click)="onExcludeProduct(element)">
                        <img src="assets/icon-circle-clear-alt.svg" alt="" width="18px" height="auto" class="exclude" />
                    </button>
                </ng-container>

                <ng-container *ngIf="field.endsWith('tot_clr')">
                    {{ element[field] | decimal }}
                </ng-container>

                <ng-container *ngIf="field.endsWith('_v')">
                    {{ element[field] | shortNumber }}
                </ng-container>

                <ng-container *ngIf="field !== 'image_id' && !field.endsWith('_v')">
                    {{ element[field] }}
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="gridColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: gridColumns" (click)="onSelectProduct(row.style_id)"></tr>
    </mat-table>
</div>
