import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../core/core.state';
import { selectUserDetails } from '../../../core/store/store.selectors';
import { Observable, Subscription, switchMap } from 'rxjs';
import { ICurrentBoard } from '../../../core/models/current-board.model';
import { map, startWith } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { IUserRole } from '../../../core/models/user-roles.model';
import { BoardService } from '../../services/board.service';

@Component({
    selector: 'lynkd-pattern-board-search-modal',
    templateUrl: './board-search-modal.component.html',
    styleUrls: ['./board-search-modal.component.scss']
})
export class BoardSearchModalComponent implements OnInit, OnDestroy {
    public searchControl: FormControl = new FormControl();
    public $filteredBoards: Observable<Array<ICurrentBoard>>;
    public savedBoards: Array<ICurrentBoard>;
    public selectedBoard: ICurrentBoard;

    @ViewChild('boardSelectionList')
    private readonly boardSelectionList: MatSelectionList;

    private boardsSubscription: Subscription;

    public constructor(
        private readonly _boardService: BoardService,
        private readonly _store: Store<IAppState>,
        private readonly _dialogRef: MatDialogRef<BoardSearchModalComponent>
    ) {}

    public ngOnInit(): void {
        this.boardsSubscription = this._store
            .select(selectUserDetails)
            .pipe(
                switchMap((result: { email: string; roles: Array<IUserRole> }) => this._boardService.getSavedBoards(result.email))
            )
            .subscribe({
                next: (boards: Array<ICurrentBoard>) => {
                    this.savedBoards = [
                        ...boards.sort((a: ICurrentBoard, b: ICurrentBoard) => a.board_name.localeCompare(b.board_name))
                    ];

                    this.$filteredBoards = this.searchControl.valueChanges.pipe(
                        startWith(''),
                        map((value: string) => this.filter(value))
                    );
                }
            });
    }

    public ngOnDestroy(): void {
        this.boardsSubscription.unsubscribe();
    }

    public getSelectedBoard(): void {
        this.selectedBoard = this.boardSelectionList.selectedOptions.selected[0].value;
    }

    public applySelectedBoard(): void {
        this._dialogRef.close(this.selectedBoard);
    }

    private filter(value: string): Array<ICurrentBoard> {
        const filterValue: string = value.toLowerCase();
        return this.savedBoards.filter((board: ICurrentBoard) => board.board_name.toLowerCase().includes(filterValue));
    }
}
