<!--<ng-container *ngIf='(attributes$ | async) as attributes'>-->
<div *ngIf="attributes" class="row no-gutters board-details">
    <div class="col-6">
        <h4 *ngIf="currentBoard.board_name.length">
            {{ currentBoard.board_name }}
        </h4>
        <p *ngIf="attributes.length" class="m-0">{{ attributes.length }} Results found</p>
    </div>
    <!--        <div class='offset-8 col-2' *ngIf="!sideNavOpenState">-->
    <!--            <mat-button-toggle-group-->
    <!--                [(ngModel)]='graph'-->
    <!--                name='graph'-->
    <!--                aria-label='Graphs'-->
    <!--                id='graph-selection-btn-group'>-->
    <!--                <mat-button-toggle [value]='GraphDisplayMode.Bar'>Bar</mat-button-toggle>-->
    <!--                <mat-button-toggle [value]='GraphDisplayMode.Line'>Line</mat-button-toggle>-->
    <!--            </mat-button-toggle-group>-->
    <!--        </div>-->
    <div class="col align-right">
        <pagination-controls
            id="attribute-listing"
            *ngIf="attributes.length"
            (pageChange)="currentPage = $event"
            [previousLabel]="labels.previousLabel"
            [nextLabel]="labels.nextLabel"
        ></pagination-controls>
    </div>
</div>
<ng-container *ngIf="(attributes && attributes.length) || loading">
    <div class="row no-gutters attribute-rows">
        <div
            class="col-12 col-md-12 col-lg-12"
            *ngFor="
                let attribute of attributes
                    | paginate: { id: 'attribute-listing', itemsPerPage: itemsPerPage, currentPage: currentPage }
            "
        >
            <lynkd-pattern-attribute-row
                [attribute]="attribute"
                [attributeParameters]="attributeParameters"
                [graph]="graph"
                [sideNavOpenState]="sideNavOpenState"
            ></lynkd-pattern-attribute-row>
        </div>
    </div>
</ng-container>
<!--    <ng-template #noItems>-->
<!--        <div class='row'>-->
<!--            <p>No product attributes found</p>-->
<!--        </div>-->
<!--    </ng-template>-->
<!--</ng-container>-->
