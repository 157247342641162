<figcaption [ngStyle]="metricStyle" class="row">
    <div class="col" *ngFor="let display_metric of filterData.display_metrics">
        <p class="mb-0">
            {{ display_metric.metric_acronym.trim() }}:
            <span>
                <ng-container *ngIf="display_metric.metric_name.endsWith('_v')">
                    {{ product[display_metric.metric_name] | shortNumber }}
                </ng-container>

                <ng-container *ngIf="!display_metric.metric_name.endsWith('_v')">
                    {{ product[display_metric.metric_name] }}
                </ng-container>
            </span>
        </p>
    </div>
</figcaption>
