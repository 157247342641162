<ng-container *ngIf="enablePagination && products?.length > 0">
<div class="row product-listing gallery">
    <div
        class="col-12 col-md-4 col-lg-2"
        *ngFor="let product of products | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: products[0].results_count }"
        [id]="product.style_id"
        (click)="viewProduct(product.style_id)"
        transition-group-item
    >
        <lynkd-pattern-gallery-listing-item
            [filterData]="filterData"
            [product]="product"
            [showDisplayMetrics]="showDisplayMetrics"
            [itemHeight]="itemHeight"
            (cleared)="exclude(product)"
        >
            <lynkd-pattern-gallery-listing-caption
                *ngIf="showDisplayMetrics === true"
                [filterData]="filterData"
                [metricStyle]="metricStyle()"
                [product]="product"
            ></lynkd-pattern-gallery-listing-caption>
        </lynkd-pattern-gallery-listing-item>
    </div>
</div>
</ng-container>
<ng-container *ngIf="!enablePagination">
    <div class="row product-listing gallery">
        <div
            class="col-12 col-md-4 col-lg-2"
            *ngFor="let product of products | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }"
            [id]="product.style_id"
            (click)="viewProduct(product.style_id)"
            transition-group-item
        >
            <lynkd-pattern-gallery-listing-item
                [filterData]="filterData"
                [product]="product"
                [showDisplayMetrics]="showDisplayMetrics"
                [itemHeight]="itemHeight"
                (cleared)="exclude(product)"
            >
                <lynkd-pattern-gallery-listing-caption
                    *ngIf="showDisplayMetrics === true"
                    [filterData]="filterData"
                    [metricStyle]="metricStyle()"
                    [product]="product"
                ></lynkd-pattern-gallery-listing-caption>
            </lynkd-pattern-gallery-listing-item>
        </div>
    </div>
</ng-container>
